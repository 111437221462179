.c-office-locator-page {

    &__wrapper {
        display: flex;
        flex-direction: column-reverse;
        @include mq($from: wide) {
            flex-direction: row;
        }
    }

    &__map {
        width: calc(100% + 40px);
        margin-left: -20px;
        @include mq($from: wide) {
            width: calc(100% + 20px);
            margin-left: 0;
            margin-right: -20px;
            margin-top: 75px;
        }

        &-wrapper {
            width: 100%;
            height: 100%;
            @include mq($from: wide) {
                height: calc(100vh - 200px);
                
                @-moz-document url-prefix() { 
                    left: initial !important;
                }
            }
        }
    }

    &__content {
        padding: 20px 0;
        @include mq($from: wide) {
            width: 70%;
            padding-right: 40px;
        }
        @include mq($from: 1600px) {
            min-width: 700px;
        }
    }

    &__title {
        font-family: $font-oswald-medium;
        font-weight: normal;
        font-size: 28px;
        color: $color-blue;
        line-height: 1.2;
        margin: 0;
        @include mq($from: desktop) {
            font-size: 32px;
        }
    }
}

#js-office-locator-map {
    height: 150px;
    width: 100%;
    
    @include mq($from: tablet) {
        height: 300px;
    }

    
    @include mq($from: wide) {
        height: calc(100vh - 200px);
    }
}

/*/ ========================================================================
//   #OFFICE LOCATOR CARD MAP
//   ======================================================================== */
.c-info-map{
    width: 210px;
    padding: 5px 10px;

    p {
        color: $color-blue-green;
        font-size: 14px;
    }
    &-controls {
        display: flex;
        &__control {
            color: $color-black;
            border: 1px solid transparent;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            .o-icon {
                font-size: 17px;
            }
            &:hover, &:first-child {
                color: $color-blue-green;
                border: 1px solid $color-blue-green;
            }
        }
    }
}
    
.gm-style .gm-style-iw-c{
    overflow: initial;
}
button.gm-ui-hover-effect {
    width: 24px !important;
    height: 24px !important;
    border: 2px solid $color-orange;
    border-radius: 50%;
    &:before {
        content:"x";
        position: absolute;
        top: 0;
        right: 0;
        font-size: 17px;
        margin: auto;
        color: $color-orange;
        font-weight: 600;
    }
    img {
        opacity: 0;
    }
}
.gm-style .gm-style-iw-c {
    max-width: 215px !important;
}

.c-office-locator-legend {
    padding: 10px 0;
    display: flex;
    align-items: center;
    margin: 0 20px -15px;
    @include mq($from: wide) {
        margin: 0;
    }

    &__icon {
        width: 12px;
        height: 12px;
        display: block;
        border-radius: 50%;

        @include mq($from: wide) {
            width: 20px;
            height: 20px;
        }

        &--blue {
            background-color: $color-blue;
        }

        &--blue-green {
            background-color: #157EAD;
        }
    }

    &__text {
        margin-right: 15px;
        margin-left: 4px;
        font-weight: 600;
        font-size: 12px;
        @include mq($from: wide) {
            font-size: 16px;
            margin-right: 20px;
            margin-left: 8px;
        }
    }
}