.kc-teaser-home {

    @include mq($from: desktop) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__item {
        margin-bottom: 30px;
        width: 100%;
        min-height: 100%;
        @include mq($from: desktop) {
            margin-bottom: 20px;

            &:nth-child(2), &:nth-child(3) {
                margin-bottom: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--50 {
            @include mq($from: desktop) {
                width: calc(50% - 10px);
            }
        }
    }

    &__controls {
        margin-top: 20px;

        .c-button, .link-button {
            height: 48px !important;
            font-weight: 700;
        }
    }

    &-preview {
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(65, 70, 72, 0.15);
        display: flex;
        min-height: 100%;

        &--big {
            display: block;
            @include mq($from: desktop) {
                display: flex;
            }
        }

        &__img {
            display: block;
            width: 50%;
            cursor: pointer;

            .kc-teaser-home-preview--big & {
                width: 100%;

                @include mq($from: desktop) {
                    width: 50%;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }
        }

        &__content {
            width: 50%;
            padding: 15px 20px;

            .kc-teaser-home-preview--big & {
                width: 100%;
                @include mq($from: desktop) {
                    width: 50%;
                    padding: 25px 45px;
                }
            }
        }

        &__date {
            font-size: 14px;
        }
        &__category {
            color: $color-black;
            text-decoration: none;
            font-weight: 600;
            &:hover {
                color: $color-blue;
                text-decoration: underline;
            }
        }

        &__title {
            margin: 10px 0 0 0;
            font-weight: 600;
            font-size: 20px;
            line-height: 1.2;
            display: block;
            color: $color-black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: $color-blue;
            }

            @include mq($from: desktop) {
                font-size: 22px;
            }

            .kc-teaser-home-preview--big & {
                font-size: 24px;
                @include mq($from: desktop) {
                    font-size: 28px;
                }
            }
        }

        &__text {
            margin: 6px 0 0 0;
            font-size: 16px;
        }

        &__link {
            font-weight: 700;
            font-size: 16px;
            text-decoration: none;
            color: $color-black;
            display: inline-block;
            margin-top: 12px;
            @include mq($from: desktop) {
                font-size: 18px;
            }

            &:hover {
                text-decoration: underline;
                color: $color-blue;
            }
        }
    }
}