///* ========================================================================
//   #MENU MOBILE NAV
//   ======================================================================== */

.c-menu-mobile-nav {

    &__item {
        border-bottom: 1px solid rgba(#2F80ED, 0.2);
    }

    &__link {
        padding: 20px 4px;
        display: flex;
        width: 100%;
        text-decoration: none;
        color: inherit;
        font-weight: 600;
        font-size: 18px;

        &.has-icon {
            padding-left: 36px;
            position: relative;
        }

        &.has-dropdown {
            padding-right: 36px;
            position: relative;
        }

        &--payment {
            color: $color-blue-green;
        }
    }

    &__item-icon {
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        color: $color-orange;
        font-size: 16px;

        .c-menu-mobile-nav__link--payment & {
            color: $color-blue-green;
        }
    }

    &__item-icon-dropdown {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        color: $color-black;
        font-size: 18px;
        transition: $global-transition;

        .c-menu-mobile-nav__link.is-active & {
            transform: translateY(-50%) rotate(180deg);
            transition: $global-transition;
        }
    }

    &__sublist-wrapper {
        padding: 0 26px 26px;
        display: none;
        position: relative;
    }

    &__category-title {
        color: $color-blue;
        font-size: 15px;
        font-weight: 700;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 18px;
        padding: 4px 0 4px 0;

        .o-icon {
            font-size: 36px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }

    &__sublist {
        padding-bottom: 28px;
    }

    &__subitem {
        margin-top: 10px;
        display: flex;
        align-items: center;

        &:first-child {
            margin-top: 0;
        }

        &--new {
            display: flex;
            align-items: flex-start;
            font-weight: 600;
        }
    }

    &__all-products {
        padding-bottom: 24px;
        margin-top: -6px;
        padding-bottom: 0;
        // position: absolute;
        color: $color-blue;
        // bottom: 0;
        // left: 0;
        // right: 0;
        //padding: 13px;
        // height: 52px;
        // display: flex;
        // align-items: center;
        
    }

    &__sublink {
        display: block;
        text-decoration: none;
        color: inherit;
        font-size: 15px;
        max-width: 280px;
        

        .c-menu-mobile-nav__all-products & {
            text-decoration: underline;
            font-weight: 700;
           
        }
    }
}