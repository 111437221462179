.c-hero-home {
    background-color: $color-light-blue;
    position: relative;
    &__content-image {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 150px;
        display: block;
        @include mq($from: 475px) {
            height: 195px;
        }
        @include mq($from: tablet) {
            height: 250px;
        }
        @include mq($from: desktop) {
            height: 600px;
        }
    }
    &__image {
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right bottom;
            @include mq($from: desktop) {
                object-position: right bottom;
            }
        }
    }
    &__container {
        //position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        @include mq($from: desktop) {
            position: absolute;
            padding-top: 57px;

            html[lang=es-US] & {
                padding-left: 0 !important;
            }
        }
    }
    &__content {
        padding-top: 24px;
        margin: auto;

        @include mq($from: desktop) {
            border: 2px solid $color-silver;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
            background-color: $color-white;
            border-radius: 8px;
            padding-top: 30px;
            max-width: 576px;
            margin: 0;

            html[lang=es-US] & {
                max-width: 590px;
            }
        }
    }
    &__title {
        font-family: $font-oswald-medium;
        text-align: center;
        margin: 0 0 0 10px;
        line-height: 1.06;
        font-size: 28px;
        color: $color-blue;
        position: absolute;
        top: 44px;
        width: 161px;
        text-align: left;
        html[lang=es-US] & {
            font-size: 26px;
        }
        @include mq($from: tablet) {
            font-size: 38px;
            width: 215px;
            top: 50px;
        }
        @include mq($from: desktop) {
            position: relative;
            top: 0;
            width: auto;
            text-align: center;
            margin: 0;
            html[lang=es-US] & {
                font-size: 38px;
            }
        }
        span {
            color: $color-orange;
        }
        strong {
            font-weight: normal;
        }
    }
    &__subtitle {
        font-family: $font-oswald;
        font-weight: 400;
        font-size: 28px;
        margin-top: 0;
        margin-bottom: 25px;
        text-align: center;
        line-height: 1;
        margin-top: 10px;
        @include mq($from: mobile) {
            font-size: 24px;
        }
        @include mq($from: desktop) {
            font-size: 40px;
            margin-bottom: 27px;
        }

        a {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 50%;
            font-weight: 600;
            color: $color-black;
            text-decoration: none;
            vertical-align: top;
            display: inline-block;
            transform: translate(4px, 4px);

            &:hover {
                text-decoration: underline;
                color: $color-blue;
            }
        }
    }
}