.c-error {

    &-banner {
        position: relative;
        margin-bottom: 30px;
        @include mq($from: tablet) {
            margin-bottom: 50px;
        }
        &__img {
            height: 84px;

            @include mq($from: tablet) {
                height: 226px;
            }
        }
        &__title {
            text-align: center;
            position: absolute;
            top: -50px;
            width: 100%;
            z-index: 1;
            color: $color-blue;
            font-size: 50px;
            margin: 0;
            @include mq($from: tablet) {
                font-size: 71px;
                top: 18px;
                margin: auto;
            }

        }
    }
    
    
    &__title {
        color: $color-blue;
        margin-bottom: 20px;
        font-size: 24px;
        text-align: center;
        font-family: $font-oswald-medium;
        @include mq($from: tablet) {
            font-size: 36px;
        }
    }
    &__text {
        font-weight: 600;
        text-align: center;
        font-size: 16px;
        @include mq($from: tablet) {
            font-size: 20px;
        }
    }

    &-controls {
        text-align: center;
        padding-top: 30px;
    }
}

.c-error-search {
    position: relative;
    &-form {
        &__input {
            display: block;
            width: 100%;
            height: 60px;
            padding: 5px 60px 5px 32px;
            font-size: 18px;
            background-color: #fff;
            border-radius: 30px;
            border: 1px solid $color-silver;
            @include placeholder($color-black);
        }

        &__submit {
            position: absolute;
            top: 50%;
            right: 34px;
            font-size: 24px;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            background-color: transparent;
            justify-content: center;

            &:disabled {
                opacity: 0.9;
                cursor: wait;
            }
        }
    }
}

.c-error-suggested {
    margin-top: 70px;
    margin: 70px auto auto;
    @include mq($until: tablet) {
        width: 247px;
        text-align: center;
        margin-top: 30px;
    }
    &__title {
        font-weight: 600;
        margin: 20px 0;
        font-size: 19px;
        @include mq($from: tablet) {
            font-size: 24px;
        }
    }
    &__controls {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .c-button {
            width: 100%;
            text-align: center;
            margin-bottom: 17px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            @include mq($from: tablet) {
                width: calc(33.3% - 7px);
                margin-bottom: 0;
                box-shadow: none;
            }
        }
    }
}