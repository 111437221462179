/*/ ========================================================================
//   #TOP COMPANIES
//   ======================================================================== */
.c-top-companies {
    
    &__list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 800px;

        @include mq($from: tablet) {
            justify-content: center;
        }
    }
    &__item {
        border: 1px solid $color-grey-1;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        border-radius: 10px;
        display: flex;
        align-items: center;
        margin: 0 3px 18px;
        padding: 6px 7px;
        
        @include mq($from: mobile) {
            padding: 6px 7px;
        }

        @include mq($from: tablet) {
            margin: 0 6.5px 18px;
        }
    }
    &__image {
        border: 1px solid $color-grey-1;
        border-radius: 7px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 70%;
        }

        @include mq($from: mobile) {
            width: 44.69px;
            height: 45.15px;
            img {
                width: auto;
            }
        }
    }
    &__title {
        display: inline-block;
        margin-left: 8.3px;
        font-weight: 600;
        font-size: 14px;

        @include mq($from: mobile) {
            font-size: 16px;
        }
    }
}