.c-state-icons {
    margin-top: 30px;
    margin-bottom: -30px;

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 30px;            
            width: calc(50% - 15px);
            margin-left: 15px;
            font-weight: 600;
            @include mq($from: desktop) {
                width: calc(33.33% - 15px);
            }
        }
        &-icon {
            color: $color-blue;
            font-size: 30px;
            margin-right: 9px;
            // @include mq($from: desktop) {
            //     margin-right: 20px;
            // }
        }
        &-link {
            text-decoration: none;
            color: $color-black;
            &:hover {
                color: $color-blue;
                text-decoration: underline;
            }

            &--bold {
                font-weight: 600;
            }
        }
        &--big {

            @include mq($from: desktop) {
                padding-left: 30px;
            }
            .c-state-icons__list-item {         
                width: calc(50% - 15px);
                @include mq($from: desktop) {
                    width: calc(25% - 15px);
                }
            }
            .c-state-icons__list-link {
                color: $color-blue;
                text-decoration: underline;
                &:hover {
                    opacity: .8;
                }
            }
        }
    }
}