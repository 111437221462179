.c-office-stores{
    &__content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        @include mq($from: tablet) {
            margin-top: 35px;
        }
    }

    &__item {
        width: 100%;
        background-color: $color-light-blue;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;
        padding: 27px 21px 23px;

        @include mq($from: tablet){
            margin: 0 40px 45px 0;
        }
        @include mq($from: desktop) {
            margin: 0 20px 45px 0;
            background-color: $color-white;

            &:nth-child(3n) {
                margin: 0 0 45px 0;
            }
        }
        @include mq($from: wide){
            margin: 0 30px 45px 0;
        }

        &--50 {
            @include mq($from: tablet) {
                width: calc(50% - 51px);
            }
        }

        &--33 {
            @include mq($from: desktop) {
                max-width: 300px;
            }

        }
    }

    &__address {
        margin: 0;
        color: $color-black;
        text-decoration: none;
        &:hover {
            color: $color-blue;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        margin: 0;

        .office-profile & {
            font-weight: 700;
        }
    }

    &__status {
        margin-top: 8px;

        strong {
            font-weight: 600;
        }

        &--open {
            strong {
                color: $color-blue;
            }
        }
        &--closing {
            strong {
                color: $color-blue;
            }
        }
        &--closed {
            strong {
                color: $color-orange;
            }
        }
    }
    &__heading {
        display: flex;
        justify-content: space-between;

        .o-icon {
            color: $color-blue;
            font-size: 24px;
            margin-right: 9px;
        }
        a {
            color: $color-black;
            font-weight: 600;
            font-size: 16px;
            @include mq($from: tablet) {
                font-size: 18px;
            }
            span {
                &:first-child {
                    display: none;
                }
                &:last-child {
                    display: block;
                }
                @include mq($from: tablet) {
                    &:first-child {
                        display: block;
                    }
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
        &--link {
            display: flex;
            align-items: center;
        }

    }

    &__phone {
        margin-top: 9px;


        &-number {
            color: $color-black;
            display: flex;
            > span {
                font-weight: 600;
            }
            &:hover {
                color: $color-blue;
            }
        }
        &--icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 1px solid $color-blue;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 7px;
            .o-icon {
                color: $color-blue;
                font-size: 12px;
            }
        }

    }

    .c-office-teaser__controls {
        position: relative;
        top: 0;
        right: 0;
    }
}