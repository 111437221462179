///* ========================================================================
//   #SITE HEADER CONTROLS DESKTOP
//   ======================================================================== */

.c-site-header-controls-desktop {
    display: flex;
    align-items: center;
    
    &__control {
        margin-left: 24px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $color-black;
        padding: 0;
        background-color: transparent;
        transition: color 0.2s ease;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            color: $color-blue;
            transition: color 0.2s ease;
        }
    }

    .js-site-header-desktop-quote-wrapper {
        display: none;
        margin-left: 24px;
    }

    &__icon {
        font-size: 16px;
    }

    &__text {
        display: inline-block;
        margin-left: 6px;
        font-size: 14px;
        line-height: 1.1;
    }
}