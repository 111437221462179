.c-all-product-list {
    @include mq($from: desktop) {
        margin-top: 60px;
    }

    &__list {
        margin-bottom: 40px;
        @include mq($from: tablet) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 24px;
        }
        @include mq($from: desktop) {
            margin-bottom: 40px;
        }
    }

    &__item {
        margin-bottom: 16px;
        @include mq($from: tablet) {
            width: calc(50% - 10px);
        }
        @include mq($from: desktop) {
            width: calc(50% - 20px);            
            margin-bottom: 40px;
        }
    }
}

.c-all-product-teaser {

    &__link {
        display: block;
        position: relative;
        border: 1px solid $color-silver;
        background-color: #fff;
        border-radius: 8px;
        padding: 15px 35px 15px 55px;
        text-decoration: none;
        transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
        height: 100%;
        @include mq($from: desktop) {
            padding: 24px 18px 24px 90px;
        }

        &:hover {
            color: $color-blue;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            border: 1px solid $color-orange;
            transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
        }
    }

    &__title {
        color: $color-black;
        font-size: 18px;
        font-weight: 600;
        line-height: 1;
        transition: color 0.2s ease-in-out;
        @include mq($from: desktop) {
            font-size: 20px;
            display: flex;
            align-items: center;
        }

        &-text {
            font-size: 18px;
            font-weight: 600;
            line-height: 1;
            margin: 0;
        }

        .c-all-product-teaser__link:hover & {
            color: $color-blue;
            transition: color 0.2s ease-in-out;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        font-size: 32px;
        color: $color-blue;
        @include mq($from: desktop) {
            transform: translateY(0);
            top: 10px;
            font-size: 48px;
            left: 24px;
        }
    }

    &__icon-arrow {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        font-size: 24px;
        color: $color-blue;
        transition: color 0.2s ease-in-out;
        @include mq($from: desktop) {
            position: static;
            margin-left: 4px;
            transform: translateY(0);
        }

        .c-all-product-teaser__link:hover & {
            color: $color-orange;
            transition: color 0.2s ease-in-out;
        }
    }

    &__text {
        display: none;
        margin-top: 8px;
        color: $color-black;
        line-height: 1.37;
        font-size: 16px;

        @include mq($from: desktop) {
            display: block;
        }
    }
}