.c-kc-faqs {
    &__container {
        @include mq($from: tablet) {
            display: flex;
        }
    }
    &__wrapper {
        @include mq($until: tablet) {
            text-align: center;
        }
    }
    .c-section__content {
        @include mq($until: tablet) {
            margin-top: 0;
        }
    }
    &-aside {
        width: 280px;
        width: 100%;
        display: none;
        @include mq($from: tablet) {
            display: block;
        }
        @include mq($from: desktop) {
            width: 340px;
        }
        &__title {

            font-family: $font-oswald-medium;
            font-size: 32px;
            font-weight: normal;
            padding-bottom: 35px;
            margin: 0 0 0;

        }
        &__content {
            overflow: auto;
            width: 280px !important;
            border-top: 1px solid $color-sky-blue;
            padding-top: 24px;
            @include mq($from: desktop) {
                width: 340px !important;
            }
            &::-webkit-scrollbar {
                -webkit-appearance: none;
                background-color: $color-light-blue;
            }
            &::-webkit-scrollbar:vertical {
                width: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0,71,187,.5);
                border-radius: 20px;
            }
            
            &::-webkit-scrollbar-track {
                border-radius: 10px;  
            }
        }
        &-list {
            padding-top: 4px;
            height: calc(100% - 4px);

            &__item {
                color: $color-black;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 15px;
                padding-left: 4px;
                @include mq($from: desktop) {
                    margin-bottom: 30px;
                    font-size: 20px;
                }
                &:last-child {
                    margin-bottom: 0;
                }

                &:first-child {
                    padding-top: 4px;
                }

                &.is-active,&:hover {
                    color: $color-blue;
                    .c-kc-faqs-aside-list__link {
                        text-decoration: underline;
                    }
                }
            }
            &__link {
                color: inherit;
                text-decoration: none;
            }
        }

        &__controls {
            color: $color-orange;
            font-weight: 600;
            border-top: 1px solid $color-sky-blue;
            margin-top: 35px;
            padding-top: 35px;
            font-size: 20px;
            a {
                color: inherit;
            }
        }
    }

    &-aside__select {
        max-width: 450px;
        width: 100%;
        height: 62px;
        padding: 5px 26px 5px 18px;
        font-weight: 600;
        margin: 0 auto;
        text-align: left;
        margin-bottom: 45px;
        border: 1px solid $color-black;
        html[lang=es-US] & {
            border-color: $color-silver;
            border-radius: 8px;
        }
        @include mq($from: tablet) {
            display: none;
        }
    }
}
.c-kc-faqs-content {
    @include mq($from: tablet) {
        padding-left: 50px;
    }
    @include mq($from: desktop) {
        padding-left: 65px;
    }

    &__heading {
        display: flex;
        align-items: center;
    }
    &__icon {
        width: 40px;
        height: 40px;
        @include mq($from: tablet) {
            width: 60px;
            height: 60px;
        }
    }   
    &__title {
        color: $color-blue;
        font-family: $font-oswald;
        font-size: 20px;
        margin: 0 0 0 14px;
        @include mq($from: tablet) {
            font-size: 32px;
            margin: 0 0 0 20px;
        }

        &--big {
            @include mq($from: tablet) {
                font-size: 40px;
            }
        }
    }     
}
.c-kc-faqs-questions{
    &__title {
        color: $color-blue;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2;
        margin-top: 30px;
        display: flex;
        align-items: baseline;
        &:first-child {
            margin-top: 20px;
        }
        @include mq($from: tablet) {
            font-size: 22px;
        }
        strong {
            color: $color-black;
            @include mq($from: tablet) {
                font-size: 20px;
            }

        }
        .o-icon {
            font-size: 16px;
            margin-right: 10px;
        }
    }
    
    &__text {
        html[lang=es-US] & {
            padding-left: 25px;
        }
        a {
            color: $color-blue;
            html[lang=es-US] & {
                text-decoration: none;
                font-weight: 600;
            }
        }
        strong {
            font-weight: 600;
        }
        ul {
            display: grid;
            gap: 12px 0;
            padding-left: 16px;
            @include mq($from: tablet) {
                padding-left: 40px;
            }
            
            li {

                &::marker{
                    color: $color-green;
                }
            }
        }

    }
    &__subitem {
        padding-left: 20px;
        @include mq($from: tablet) {
            padding-left: 40px;
        }
    }

    &__item {

        p {
            a {
                color: $color-blue;
            }
            strong {
                font-weight: 600;
            }
        }
        
    }
}

.c-faqs-index {
    + .c-kc-faqs-questions {
        border-top: 1px solid $color-silver;
        margin-top: 40px;
        padding-top: 10px;
        @include mq($from: tablet){
            padding-top: 20px;
        }
    }

    &__title {
        font-size: 24px;
        font-weight: normal;
        font-family: $font-oswald-medium;
        @include mq($from: tablet) {
            font-size: 30px;
            + .c-faqs-index__list{
                margin-top: 30px;
            }
        }
    }
    &__list {
        list-style-image: url('../images/components/sections/icon-question.svg') ;
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px 0;
        padding-left: 26px;
        margin-top: 30px;
        @include mq($from: desktop){
            margin-top: 40px;
        }
    }

    &__item {
        padding-left: 6px;
        &::marker {
            text-align: center;
        }
    }
    &__link {
        color: $color-blue;
        &:hover {
            font-weight: 600;
        }
    }
}