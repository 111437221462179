/*/ ========================================================================
//   #OFFICE LOCATOR PROFILE 
//   ======================================================================== */
.c-office-profile {

    &__title {
        font-family: $font-oswald;
        font-weight: normal;
        font-size: 24px;
        color: $color-blue;
        text-align: left;
        margin: 0;
        line-height: 1.2;
        width: 100%;
        @include mq($from: desktop){
            font-size: 32px;
        }
    }
}
.office-profile {
    .c-section__title {
        text-align: left;
        font-family: $font-oswald-medium;
    }
    .c-section--small-spacing {
        padding: 36px 0 0;
    }
}
