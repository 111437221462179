.featured-in {
    border-top: 1px solid $color-silver;
    border-bottom: 1px solid $color-silver;
    padding: 36px 0;

    &__list {
        margin-top: 36px;
        margin-bottom: -45px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &__item {
        margin: 0 auto 45px;
        padding: 0 10px;
    }
}