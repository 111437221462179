/*/ ========================================================================
//   #OFFICE LOCATOR PROFILE - NEWS
//   ======================================================================== */
.c-office-news {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    

    &__item {
        width: 100%;
        margin-bottom: 40px;

        @include mq($from: tablet) {
            width: calc(50% - 20px);
        }
        @include mq($from: desktop) {
            max-width: 440px;
        }
    }

    &__title {
        font-size: 20px;
        margin-top: 0;

        @include mq($from: desktop) {
            font-size: 24px;
        }
    }
    &__img {
        width: 100%;
        height: 161px;
        display: block;
        @include mq($from: tablet) {
            height: 220px;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }

    &__link {
        font-size: 18px;
        text-decoration: none;
        color: $color-blue;
        @include mq($from: tablet) {
            color: $color-black;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}