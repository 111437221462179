///* ========================================================================
//   #SITE HEADER TEASER BAR
//   ======================================================================== */

.teaser-bar {
    background-color: $color-blue;
    padding: 13px 50px 13px 55px;
    display: flex;
    align-items: center;
    position: relative;
    @include mq($from: wide) {
        background-color: transparent;
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: $color-white;
        font-size: 30px;
        left: 20px;
        @include mq($from: wide) {
            color: $color-blue;
        }
    }

    &__link {
        display: block;
        line-height: 1;
        color: inherit;
        text-decoration: none;
        font-size: 16px;
        color: $color-white;
        @include mq($from: desktop) {
            font-size: 18px;
        }
        @include mq($from: wide) {
            color: $color-blue;
        }

        strong {            
            font-family: $font-oswald-medium;
            font-weight: normal;
        }

        span {
            color: $color-white;
            font-family: $font-oswald;
            text-decoration: underline;
            @include mq($from: wide) {
                color: $color-orange;
            }
        }
    }

    &__close {
        background-color: transparent;
        position: absolute;
        top: 54%;
        right: 20px;
        transform: translateY(-50%);
        display: block;
        line-height: 0.8;
        color: $color-white;
        @include mq($from: wide) {
            color: $color-black;
        }

        .o-icon {
            font-size: 18px;
        }
    }
}