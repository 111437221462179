.c-main-content-ds {
    .c-kc-teaser {
        &__item {
            @include mq($until: tablet) {
                margin: 0 0 24.5px;
            }
        }
        
        @include mq($until: tablet) {
            .o-crop--fill>.o-crop__content {
                width: 100%;
            }
        }
        
    }
    
}
.c-about-img-ds {
    max-width: 800px;
    width: 100%;
}