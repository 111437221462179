.c-reviews {
    .c-quick-quote {
        margin-top: 56px;
    }
}
.c-reviews-testimonies {
    margin-top: 56px;
    &-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__item {
            border: 1px solid $color-silver;
            padding: 15px;
            margin-bottom: 20px;
            background-color: $color-white;
            position: relative;
            &--100 {
                width: 100%;
            }
            &--d33{
                @include mq($from: tablet){
                    width: calc(33.33% - 12px);
                }
            }
        }
        &__popular {
            .c-reviews-testimonies-list__item {
                @include mq($from: tablet){
                    height: 350px;
                }
            }
        }
    }
    &__content {
        display: flex;
        align-items: center;
    }
    &__name {
        color: $color-blue;
        font-family: $font-oswald-medium;
        font-weight: 500;
        font-size: 20px;
    }
    &__date {
        font-size: 12px;
    }
    &__heading{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &-rating {
            display: flex;
        }
        &-name {
            display: flex;
            align-items: center;
            font-weight: 600;
        }
        &-logo{
            width: 24px;
        }
        &-link {
            padding: 0 5px 0 9px;
            color: $color-black;
            text-decoration: none;
        }

    }
    &-rating {
        margin-left: 10px;
        &__star {
            width: 11px;
        }
    }
    &__controls {
        text-align: center;
        &--orange {
            
            .c-button {
                color: $color-orange;
            }
        }
        &--blue {
            .c-button {
                color: $color-blue;
            }
        }
        .c-button {
            height: 45px;
        }
    }
    &__footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        left: 0;
        @include mq($from: tablet){
            position: absolute;
            bottom: 20px;
            padding: 0 15px;
        }
        
        a {
            color: $color-black;
            font-weight: 700;
            &:hover {
                color: $color-blue;
            }
        }
        img {
            width: 24px;
        }
    }
}