.c-alert-header {
    color: $color-white;
    background-color: $color-blue;
    font-size: 12px;
    position: relative;
    padding: 8px 0;
    text-align: center;
    padding-right: 35px;
    @include mq($from: desktop) {
        font-size: 14px;
        padding: 19px 0;
        padding-right: 20px;
    }
    @include mq($from: wide) {
        font-size: 17px;

    }
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
            font-family: $font-opensans;
            line-height: 1.2;
            margin: 0;
            @include mq($from: desktop) {
                font-weight: 600;
            }
        }
        a {
            color: inherit;
            @include mq($from: desktop) {
                font-weight: 700;
            }
        }
    }

    
    &__close {
        background-color: transparent;
        position: absolute;
        top: 54%;
        right: 20px;
        transform: translateY(-50%);
        display: block;
        line-height: 0.8;
        color: $color-white;

        .o-icon {
            font-size: 18px;
        }
    }
}
.alert-header {
    .c-main-content {
        margin-top: 183px;
        .no-office & {
            margin-top: 138px;
        }
        @include mq($from: 500px) {
            .no-office & {
                margin-top: 125px;
            }
            
        }
    
        @include mq($from: desktop) {
            margin-top: 254px;
            .no-office & {
                margin-top: 206px;
            }
            
        }
        @include mq($from: wide) {
            margin-top: 194px;
            .no-office & {
                margin-top: 194px;
            }
        }

    }
    
}