.c-modal-turbo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
    display: flex;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30px);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility ease-in-out 0.3s;

    &.is-open {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility ease-in-out;
    }

    .o-wrapper {
        width: 100%;
        min-width: 100%;
    }
    &__title {
        color: $color-blue;
        font-size: 24px;
        font-family: $font-oswald-medium;
        font-weight: normal;
        text-align: center;
        margin-bottom: 23px;
        width: 100%;
        @include mq ($from: desktop) {
            font-size: 32px;
            margin-bottom: 40px;
        }
    }
    &__img-product {
        width: 32px;
        @include mq ($from: desktop) {
            width: 39px;
        }
    }

    &__content {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 59px 26px 37px;
        max-width: 750px;
        margin: 24px auto 0;
        background-color: $color-light-blue;
        position: relative;

        @include mq($from: desktop) {
            padding: 61px 44px;
            min-width: 697px;
            margin-top: 56px;
        }
        .c-quote {
            &__item {
               @include mq ($from: desktop) {
                    &:nth-child(1) {
                        min-width: 258px;
                        margin-right: 12px;
                    }

                    &:nth-child(2) {
                        width: 128px;
                        margin-right: 12px;
                    }
               }
                &-product {
                    margin-top: 4px;
                    display: block;
                    width: 100%;
                    height: 50px;
                    border-radius: 8px;
                    border: 2px solid $color-orange;
                    background-color: $color-white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    @include mq ($from: desktop) {
                        padding: 0 8px;
                    }
                    img {
                        margin-right: 12px;
                        @include mq ($from: desktop) {
                            margin-right: 17px;
                        }

                    }
                }
            }
            &__input, &__select {
                @include placeholder($color-black);
                @include mq ($until: desktop) {
                   font-size: 16px;
                }
            }
            &__label {
                letter-spacing: -0.03em;
                padding-left: 5px;
            }
            &__controls {
                min-width: 200px;
            }
        }
    }

    &__name-product {
        //text-transform: capitalize;
        font-weight: 700;
        font-size: 16px;
        @include mq($from: desktop) {
            font-size: 20px;
        }
    }

    &__product-link {
        text-align: center;
        margin-top: 30px;
        font-weight: 600;

        a {
            color: $color-blue;
        }
    }

    &__close {
        position: absolute;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: $color-black;
        top: 17px;
        right: 14px;
        @include mq($from: desktop) {
            top: 23px;
            right: 23px;
            font-size: 25px;
        }
    }
   
}

.js-button-quote-turbo {
    background-color: transparent;
    text-decoration: none;
    color: $color-blue;
    font-weight: 600;
    
    &:hover {
        text-decoration: underline;
    }
    
}