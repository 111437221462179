.c-banner-wl {
    height: 442px;
    margin-bottom: 80px;
    @include mq($from: desktop) {
        margin-bottom: 40px;
    }

    &__container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        background-color: #F6F6F6;
        padding: 0 0px 20px;
        position: relative;

        @include mq($from: 700px) {
            padding: 57px 40px 10px 20px;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__title {
        font-size: 22px;
        margin: 0 0 16px;
        line-height: 1.125;
        color: #111111 !important;
        font-family: Arial, Helvetica, sans-serif !important;
        font-weight: 700 !important;
        @include mq($from: desktop) {
            font-size: 34px;
            max-width: 380px;
        }

        &--small {
            font-size: 18px !important;
            line-height: 1.3;

            @include mq($from: desktop) {
                font-size: 28px !important;
                max-width: initial;
                line-height: 1.126;
            }

            + .c-banner-wl__text {
                @include mq($from: desktop)  {
                    max-width: initial;
                }
            }
        }

        span {
            color: #0070CA;
        }
    }

    &__text {
        margin: 0;
        font-family: Arial, Helvetica, sans-serif;
        color: #111111;
        
        @include mq($from: desktop) {
            font-size: 18px;
            max-width: 320px;
            br {
                display: none;
            }
        }
    }

    &__text-bottom {
        p {
            font-size: 8px;
            font-family: Arial, Helvetica, sans-serif;
            margin: 0;
            text-align: right;
            @include mq($from: tablet) {
                font-size: 11px;
            }
        }
        @include mq($from: tablet) {
            position: absolute;
            right: -25px;
            bottom: 0px;
        }
    }

    &__content {
        z-index: 1;
        position: relative;
    }

    &__pic {
        width: 279px;
        height: 119px;
        margin: auto;
        @include mq($from: 700px) {
            width: 366px;
            height: 277px;
        }
        @include mq($from: desktop) {
            width: 388px;
            height: 326px;
            position: absolute;
            top: 54px;
            left: 20px;
            //transform: translateY(-50%);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__card {
        background-color: $color-white;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
        border-radius: 15px;
        width: 280px;
        min-height: 254px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 20px 12px;
        text-align: center;
        margin-bottom: 17px;

        @include mq($from: desktop) {
            min-height: 273px;
            padding: 36px 35px;
            width: 492px;
        }

        &--medium {
            width: 300px;

            @include mq($from: desktop) {
                width: 492px;
            }
        }
    }

    &__logo {
        text-align: center;
        margin-bottom: 5px;
        
    }
    &__button {
        margin-top: 23px;

        .c-button {
            background-color: #0070CA;
            color: $color-white;
            font-size: 15px;
            width: 230px;
            height: 46px;
            font-family: Arial, Helvetica, sans-serif;
            @include mq($from: tablet) {
                font-size: 18px;
            }
            &:hover {
                background-color: #0561ac;
                transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
            }
        }
    }
}

.o-wrapper {
    .c-banner-wl {
        .o-wrapper  {
            padding: 0 !important;
        }
    }
}