.c-product-imagen-wp {
    max-width: 760px;
    width: 100%;
    border-radius: 24px;
    //box-shadow: 16px 16px 29px rgba(0, 0, 0, .08);
    // margin-bottom: 20px;
    height: auto;
    display: block;
    margin: 0 auto 23px;
}

.c-product-page {
    .s-cms-content {
        strong {
            font-weight: 600;
        }
        p:not(:has(iframe)) {
            text-align: left;
        }
    }
    .c-section__title {
        text-align: left;
        font-family: $font-oswald-medium;
        &--center {
            text-align: center !important;
            @include mq($from: desktop) {
                
            }
        }
    }
    .c-media-block__title {
        text-align: center;
        @include mq($from: desktop) {
            text-align: left;
        }
    }
}

.c-main-content--blog .wp-block-embed iframe {
    max-width: 880px;
    aspect-ratio: 16 / 9;
    height: auto;
    width: calc(100% - 40px);
    margin: 0 20px;
}