.c-sitemap {
    margin: 40px 0;
    @include mq($from: desktop) {
        margin: 55px 0;
    }
    &__item {
        margin-bottom: 30px;
        @include mq($from: desktop) {
            margin-bottom: 56px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-family: $font-oswald;
        font-weight: 400;
        font-size: 24px;
        padding: 12px 0;
        border: 1px solid $color-silver;
        border-left: 0;
        border-right: 0;
        margin: 0;
        @include mq($from: desktop) {
            font-size: 28px;
            padding: 20px 0;
        }
    }

    &__sublist {
        margin-top: 20px;
        @include mq($from: desktop) {
            margin-top: 30px;
            column-count: 3;
            column-gap: 20px;
        }
    }

    &__subitem {
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }
        @include mq($from: desktop) {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
        }
    }

    &__link {
        font-size: 16px;
        color: $color-black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: $color-blue;
        }
    }
}