///* ========================================================================
//   #MENU MOBILE NAV
//   ======================================================================== */

.c-nav-desktop {
    background-color: #fff;
    padding: 22px 20px;

    &__list {
        display: flex;
        align-items: center;
    }

    &__item {
        margin-right: 24px;
        
        &:last-child {
            margin-right: 0;
        }

        &--payment {
            margin-left: auto;
            margin-right: 0;
        }
    }

    &__link {
        display: inline-block;
        font-weight: 600;
        font-size: 15px;
        color: $color-black;
        text-decoration: none;
        transition: color 0.3s ease-in-out;

        &.has-dropdown {
            padding-right: 24px;
            position: relative;
        }

        &.is-active, &:hover , &:focus {
            color: $color-blue;
            transition: color 0.3s ease-in-out;
        }

        &--payment {
            color: $color-blue-green;
        }
    }

    &__item-icon-dropdown {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        color: $color-black;
        font-size: 18px;
        transition: $global-transition;

        .c-nav-desktop__link.is-active & {
            transform: translateY(-50%) rotate(180deg);
            transition: $global-transition;
            color: $color-orange;
        }
    }

    &__sublist-wrapper {
        padding: 20px 5px 20px 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        visibility: hidden;
        transform: translateY(-5%);
        z-index: -5;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
        transition: transform 0.4s ease-in-out, visibility 0.4s ease-in-out 0.4s;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;


        @include mq($from: wide) {
            padding: 35px 25px 20px 50px;
            
        }

        &--columns {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &.is-open {
            visibility: visible;
            transform: translateY(100%);
            transition: transform 0.4s ease-in-out, visibility 0.4s ease-in-out;
        }

        .c-nav-desktop__sublist-item{
            &:last-child {
                padding-right: 0;
            }
        }
    }

    &__sublist-item {
        max-width: 224px;
        padding-right: 15px;

        @include mq($from: 1200px) {
            width: 100%;
            
        }
        &:focus-within .c-nav-desktop__category-title {
            color: $color-blue;
            
        }
    }

    &__category-title {
        color: $color-black;
        font-size: 15px;
        font-weight: 700;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 16px;
        padding: 4px 0 4px 0px;
        transition: color 0.3s ease-in-out;

        .o-icon {
            font-size: 36px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            color: $color-blue;
        }

        .c-nav-desktop__sublist-item:hover & {
            color: $color-blue;
            transition: color 0.3s ease-in-out;
        }
    }

    &__sublist {

        &--columns2 {
            column-count: 2;
            column-gap: 34px;

            @include mq($from: wide) {
                column-gap: 64px;
            }
        }
    }

    &__subitem {
        margin-top: 10px;
        //padding-right: 15px;
        //max-width: 180px;
        @include mq($from: 1100px) {
            //max-width: 220px;
        }

        &:first-child {
            margin-top: 0;          
        }

        &--new {
            display: flex;
            align-items: flex-start;
            font-weight: 600;
        }
    }

    &__all-products {
        padding-top: 15px;
        border-top: 1px solid $color-silver;
        width: 100%;
        margin-top: 20px;
    }

    &__sublink {
        display: inline-block;
        text-decoration: none;
        color: inherit;
        font-size: 15px;

        &:hover, &:focus {
            color: $color-blue;
            text-decoration: underline;
        }

        .c-nav-desktop__all-products & {
            text-decoration: underline;
            font-weight: 700;
        }
    }
}
.c-nav-sublink--new {
    border-radius: 4px;
    color: $color-white;
    background-color: $color-orange;
    font-size: 12px;
    padding: 0 6px;
    margin-left: 7px;
    margin-top: 2px;

    @include mq($from: desktop) {
        margin: 2px 0 0 7px;
    }
}