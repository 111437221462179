.c-all-authors {
    padding-top: 30px;
    margin: 30px 0;
    border-top: 1px solid $color-silver;
    @include mq($from: desktop) {
        margin: 60px 0;
    }

    &--profile {
        @include mq($from: desktop) {
            margin: 38px 0 60px;
        }
    }

    &--others {
        border: none;
        padding-top: 0;
    }

    &__title {
        margin: 24px 0 20px;
        font-family: $font-oswald;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.07;
        @include mq($from: desktop) {
            margin: 30px 0;
            font-size: 28px;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        @include mq($from: desktop) {
            gap: 40px;
        }
    }
}