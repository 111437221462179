.c-useful-widget {
    margin: 40px 0;

    &__title {
        font-weight: 700;
        font-size: 22px;
        margin: 0;
        text-align: center;
        @include mq($from: desktop) {
            font-size: 28px;
            margin-right: 40px;
        }
    }
    &__wrapper {
        border-top: 1px solid $color-silver;
        border-bottom: 1px solid $color-silver;
        padding: 30px 0;
    }
    &__question {
        @include mq($from: desktop) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__controls, .c-button__wrapper {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq($from: desktop) {
            margin-top: 0
        }
    }

    &__ok, &__no-ok {
        display: none;
    }

    &__control {
        color: $color-black;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        &:first-child {
            margin-right: 40px;
        }
        @include mq($from: desktop) {
            font-size: 20px;
        }
        &--ok {

            &:hover, .is-keyboard-pressed &:focus {
                .c-useful-widget__icon {
                    transform: rotate(-15deg);
                    transition: $global-transition;
                    color: $color-green;
                }
            }
            .c-useful-widget__icon {
                color: $color-green;
            }
        }
        &--no-ok {
            &:hover, .is-keyboard-pressed &:focus {
                .c-useful-widget__icon {
                    transform: rotate(-15deg);
                    transition: $global-transition;
                    color: $color-red;
                }
            }
        }
    }

    &__icon {
        transition: $global-transition;
        font-size: 48px;
        margin-right: 10px;
    }

    .c-button {
        margin-top: 16px;
        width: auto;
       
    }
}