/*/ ========================================================================
//   #ANCLA FOOTER
========================================================================== */
.c-ancla-footer {
    position: absolute;
    width: 48px;
    height: 48px;
    background-color: $color-blue;
    border-radius: 50%;
    right: 4.95%;
    top: -23px;

    &__link {
        font-size: 24px;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        padding: 0;
        margin: 0;
    }

    .o-icon {
        color: $color-white;
    }
}