.c-page-breadcrumb {
    display: none;
    background-color: #fff;
    padding: 14px 0;

    @include mq($from: wide) {
        display: block;
    }

    &__list {
        display: flex;
    }

    &__item {
        margin: 0 3px;
        display: flex;
        align-items: center;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        display: block;
        color: $color-black;
        text-decoration: none;
        font-size: 14px;

        &:hover {
            text-decoration: underline;
        }

        &--current {
            font-weight: 600;

            &:hover {
                text-decoration: none;
            }
        }
    }
}