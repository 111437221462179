.c-hippo-transition {
    padding: 40px 0;
    display: none;

    &--visible {
        display: block;
    }

    &__picture {
        display: block;
        width: 300px;
        margin: 0 auto;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
        @include mq($from: tablet) {
            width: 500px;
        }
    }

    &__text {
        color: $color-blue;
        line-height: 1.22;
        text-align: center;
        margin: 23px auto 0;
        max-width: 500px;

        span {
            display: block;
            font-weight: 600;
            font-size: 18px;
            @include mq($from: tablet) {
                font-size: 21px;
            }
            @include mq($from: desktop) {
                font-size: 24px;
            }
        }

        strong {
            font-weight: 700;
            font-size: 18px;
            display: block;
            @include mq($from: tablet) {
                font-size: 24px;
            }
            @include mq($from: desktop) {
                font-size: 30px;
            }
        }
    }
}

.c-hippo-price {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    border: 1px solid $color-green;
    border-radius: 20px;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.05);
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include mq($from: desktop) {
        padding: 18px 52px 24px;
        max-width: 378px;
    }

    &__description {
        font-size: 12px;
        font-weight: 600;
        width: 100%;
        text-align: center;
        p {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }

    &__text {
        font-size: 32px;
        color: $color-blue;
        font-weight: 500;
        line-height: 1.09;
        font-family: $font-oswald-medium;

        @include mq($from: desktop) {
            font-size: 36px;
        }

        strong {
            display: block;
            font-size: 16px;
            font-weight: 700;
            color: $color-black;
            font-family: $font-opensans;
            margin-top: 3px;

            @include mq($from: desktop) {
                font-size: 18px;
            }
        }
    }

    &__price {
        width: 116px;
        height: 116px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        margin-left: 12px;
        flex-direction: column;
        @include mq($from: tablet) {
            width: 118px;
            height: 118px;
        }

        @include mq($from: desktop) {
            width: 136px;
            height: 136px;
        }

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            background-image: url('../images/components/hippo/price-bg.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            @keyframes bg-price-hippo {
                0% { transform: scale(1.05);}
                33% { transform: scale(1);}
                66% { transform: scale(1.05);}
                100% { transform: scale(1);}
            }
        }

        &--animated::before {
            animation: bg-price-hippo 1000ms ease-in;
        }

        &-content {
            display: flex;
            justify-content: center;
            z-index: 1;
        }

        &-text {
            font-size: 20px;
            width: 100%;
            color: $color-white;
            z-index: 1;
            text-align: center;
            font-family: $font-oswald;
            font-weight: normal;
            margin-top: -13px;

            @include mq($from: desktop) {
                margin-top: -13px;
            }
        }
    }

    &__symbol {
        color: #fff;
        font-size: 32px;
        font-weight: 600;
        position: relative;
        z-index: 1;
        transform: translate(0px, 10px);

        @include mq($from: desktop) {
            font-size: 36px;
            
        }
    }

    &__number {
        color: #fff;
        font-size: 40px;
        font-family: $font-oswald-medium;
        font-weight: 500;
        position: relative;
        z-index: 1;


        @include mq($from: desktop) {
            font-size: 44px;
        }

        &.has-4-digits {
            font-size: 30px;
            @include mq($from: tablet) {
                font-size: 38px;
            }

            @include mq($from: desktop) {
                font-size: 38px;
            }
        }
    }
}

.c-hippo-coverages {
    margin-top: 40px;

    &__box {
        background: #fff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }
    
    &__title {
        font-size: 18px;
        font-weight: 700;
        color: $color-black;
        text-align: center;
        margin: 0;
    }

    &__list {
        margin-top: 22px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: calc(100% + 20px);
        margin-left: -20px;
        @include mq($from: desktop) {
            margin-top: 30px;
        }
    }

    &__item {
        width: 140px;
        margin-bottom: 20px;
        padding: 0 15px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        @include mq($from: desktop) {
            padding: 0 4px;
        }
    }

    &__img {
        width: 36px;
        height: auto;
        display: block;
        margin: 0 auto;
        @include mq($from: desktop) {
            width: 40px;
        }
    }

    &__subtitle {
        font-weight: 600;
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 12px;
        text-align: center;
        line-height: 1.33;
        @include mq($from: desktop) {
            margin-top: 15px;
            margin-bottom: 16px;
            font-size: 15px;
        }
    }

    &__value {
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        line-height: 0.8;
        margin-top: auto;
        @include mq($from: desktop) {
            font-size: 24px;
        }
    }

    &__legal-text {
        margin-top: 40px;
        max-width: 760px;
        margin-left: auto;
        margin-right: auto;
        @include mq($from: desktop) {
            margin-top: 30px;
        }

        .s-cms-content {
            font-size: 12px;
            text-align: left;
            @include mq($from: desktop) {
                text-align: center;
            }
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        @include mq($from: desktop) {
            margin-top: 24px;
        }
    }

    &__bonus-text {
        margin-top: 40px;
        @include mq($from: desktop) {
            margin-top: 50px;
        }

        .s-cms-content {
            font-size: 14px;
        }
    }
}