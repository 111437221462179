.c-hero-home-radio {
    &__wrapper {
        width: 320px;
        margin: auto;
        padding-bottom: 40px;

        @include mq($from: tablet) {
            max-width: 500px;
            width: 100%;
            padding-bottom: 45px;
        }
    }
    &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;

        @include mq($from: tablet) {
            margin-bottom: 40px;
        }

        &-title {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 21px;
            text-align: center;
            width: 100%;
            @include mq($from: tablet) {
                font-size: 16px;
                padding-top: 22px;
            }
        }
        &-content {
            border: 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0;
            width: 100%;
        }

        &-item {
            width: 158px;
            display: flex;
            text-align: center;
            height: 49px;
            position: relative;
            margin: 0 0 11.77px;
            align-items: center;
            background-color: $color-white;

            .is-keyboard-pressed &:focus-within {
                border-radius: 10px;
    	        outline: 2px solid orange;
            }        

            html[lang=es-US] & {
                @include mq($until: 450px) {
                   // width: 148px;
                }
            }

            @include mq($from: tablet) {
                height: 69px;
                width: 242px;
            }
            @include mq($until: tablet) {
                &:first-child {
                    width: 100%;
                    height: 72px;
                    .form.c-hero-home-radio__form-label {
                        padding-left: 105px ;
                    }
                    .c-hero-home-radio__form-text {
                        font-size: 18px;
                        &_promotion {
                            font-size: 20px;
                        }
                    }
                    .c-hero-home-radio__form-icon {
                        font-size: 52px;
                        padding-left: 26px;
                        &-check {
                            margin-right: 8px;
                            @include mq($from: tablet) {
                                margin-right: 7px;
                            }
                        }
                    }
                    
                }
                &:last-child {
                    display: none;
                }
            }
        }
        fieldset {
            border: 0;
            padding: 0;
            width: 100%;
            margin: 0;
        }

        .form.c-hero-home-radio__form-label{
            width: 100%;
            height: 100%;
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            border-radius: 10px;
            left: 0;
            padding-left: 73px;

            @include mq($until: tablet) {
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-left: 42px;
            }
            @include mq($from: desktop) {
                text-align: center;
            }
        }        

        .c-hero-home-radio__form-text{
            font-size: 15px;
            color:$color-black;
            font-weight: 700;
            display: flex;
            align-items: center;

            @include mq($from: desktop) {
                font-size: 20px;
            
                html[lang=es-US] & {
                    font-size: 18px;
                }
            }
            &_promotion {
                font-size: 18px;
                font-weight: 700;
                display: flex;
                > span {
                    font-size: 13px;
                    padding-left: 2px;
                }
                html[lang=es-US] & {
                    font-size: 17px;
                }
               
            }
            + .c-hero-home-radio__form-text_promotion{
                margin-top: -5px;
                @include mq($from: tablet) {
                    margin-top: -15px;
                }
            }
        }
        input[type="radio"] {
            opacity: 0;
            position: absolute;
            
            + label {
                border: 1px solid $color-silver;
                @include mq($from: desktop) {
                    border: 2px solid $color-silver;
                }
            }
            
            &:checked {
                + label {
                    border: 2px solid $color-orange;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                    
                    .c-hero-home-radio__form-icon-check {
                        display: block;
                        color: $color-green;
                    }
                    .c-hero-home-radio__form-text {
                        color: $color-orange;
                    }
                }
            }
            &:focus {
                + label {
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                }
              }
        }
        &-icon-check {
            display: none;
            margin-right: 4px;
            @include mq($from: tablet) {
                margin-right: 7px;
            }
        }
        &-icon {
            font-size: 30px;
            color: $color-blue;
            height: auto;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding-left: 8px;

            @include mq($from: tablet) {
                font-size: 49px;
                height: 45px;
                margin-bottom: 5px;
                padding-left: 14.5px;
            }
        }

        &-zipcode {
            width: 140px;
            margin: 0 auto 12px;
            color: $color-black;
            text-align: center;
            position: relative;

            @include mq($from: tablet) {
                font-size: 24px;
                width: 40%;
                margin: 0 20px 0 0;
                text-align: left;
                html[lang=es-US] & {
                    width: 34.615%;
                }
            }
            
            label.form__error {
                background-color: $color-orange;
                color: #fff;
                font-size: 12px;
                padding: 6px 9px;
                display: inline-block;
                margin-top: 7px;
                border-radius: 6px;
                position: relative;
                //margin-left: 10px;
                box-shadow: 0 3px 6px 0 rgba(0,0,0,.16);
                @include mq($from: tablet) {
                    font-size: 14px
                }
                &::before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: -5px;
                    left: 20px;
                    width: 1px;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-bottom: 5px solid $color-orange;
                }
            }

            &-label {
                font-size: 16px;
                color: #959595;
                position: absolute;
                top: 10px;
                left: 12px;
                pointer-events: none;
                text-align: left;
                transition: all 0.2s ease;

                @include mq($from: tablet) {
                    font-size: 20px;
                    font-weight: 600;
                }

                &.is-floating {
                    font-size: 11px;
                    top: 0;
                    transition: all 0.2s ease;

                    @include mq($from: tablet) {
                        font-size: 12px;
                    }
                }
            }
        }
        &-items {
            //width: 295px;
            margin: 2px auto 0;
            display: flex;
            justify-content: space-between;
            @include mq($from: tablet) {
                flex-direction: row;
                margin: 6px 0 0;
                justify-content: center;
                width: 100%;
            }
           
            &-input {
                width: 100%;
                height: 45px;
                border: 1px solid $color-silver;
                border: 0;
                padding: 10px 12px 5px;
                @include placeholder(#959595);
                font-size: 16px;
                box-shadow: 0px 0px 4px rgba(0, 71, 187, 0.6);
                border-radius: 12px;

                @include mq($from: tablet) {
                    border-radius: 8px;
                    box-shadow: 0px 0px 6px rgba(0, 71, 187, 0.8);
                    height: 50px;
                    font-size: 20px;
                    font-weight: 600;
                }
               
                &.form__valid {
                    border: 1px solid $color-green;
                }
        
                &.form__error {
                    border: 1px solid $color-orange;
                }
            
            }
            .c-button {
                font-size: 18px;
                padding: 5px 16px;
                min-width: 150px;
                display: flex;
                gap: 5px;
                //width: auto;
                @include mq($until: tablet) {
                    html[lang=es-US] & {
                        width: 145px;
                    }
                }

                @include mq($from: tablet) {
                    font-size: 24px;
                    padding: 5px 33px;
                    
                }

                img {
                    display: none;
                    width: 30px;
                    height: auto;
                    .form--loading & {
                        display: block;
                    }
                }
            }
        }
    }
    &__link {
        display: block;
        text-align: center;
        text-decoration: none;
        color: $color-black;
        font-size: 14px;
        transition: color 0.2s ease-in-out;
        @include mq($from: tablet) {
            font-size: 18px;
        }

        &-button {
            background-color: transparent;
            font-weight: 600;
            color: $color-black;
        }

        &:hover &-button {
           color: $color-blue;
           transition: color 0.2s ease-in-out;
        }
    }
}
