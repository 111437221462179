.c-affiliated-table {
    margin-top: 30px;
    width: 100%;
    @include mq($until: desktop) {
        display: block;
    }
    &__title {
        @include mq($until: tablet) {
            font-size: 18px;
            font-family: $font-opensans;
            font-weight: 600;
            text-align: left;
        }
    }
    &__table {
        width: 100%;
        border-spacing: 1px;

        
        tr {
            height: auto;
            @include mq($from: tablet) {
                height: 65px;
                &:nth-child(even) {
                    background-color: $color-sky-blue;
                }
            }
        }
        td{
            &[data-content='State'] {
                @include mq($from: tablet) {
                    color: $color-blue;
                }
            }
            &[data-content='DBA'] {
                @include mq($until: tablet) {
                    height: 50px;
                }
            }
        }
        th,td {
            @include mq($from: tablet) {
                padding: 8px 15px; 
            }
        }
    }
    thead {
        background-color: $color-blue;
        color: $color-white;
        font-size: 18px;
        @include mq($until: tablet) {
            display: none; 
        }
        th {
            font-weight: 600;
            text-align: left;
        }
    }
    tbody {
        width: 100%; 
        @include mq($until: tablet) {
            display: block;
        }
        tr {
            padding: 24px 0;
            border-top: 1px solid rgba(47, 128, 237, 0.2);; 
            @include mq($until: tablet) {
                display: block;
            }
            @include mq($from: tablet) {
                padding: 24px 20px;
            }
        }
        td {
            font-size: 16px; 
            @include mq($until: tablet) {
                display: block;
                margin-bottom: 10px;
            }
            &:first-of-type {
                color: $color-blue; 
            }
            &::before {
                display: block;
                content: attr(data-content);
                font-size: 16px; 
                font-weight: 600;
                color: $color-black;
            }
        }
    }
   
    @include mq($from: tablet) {
        thead{
            width: 100%;
        } 
        tbody {

            td {
                text-align: left;
                
                &:before {
                    display: none;
                }
                
            }
        } 
    }
}
