.c-author-info {

    &--article {
        margin: 30px 0;
        padding: 30px 0;
        border-top: 1px solid $color-silver;
        border-bottom: 1px solid $color-silver;
        @include mq($from: desktop) {
            padding: 30px 20px;
            display: flex;
            gap: 0 40px;
            align-items: center;
            margin: 40px 0;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 20px;

        &--profile {
            position: relative;
        }
    }

    &__pic {
        width: 100px;
        height: 100px;
        display: block;

        .c-author-info__header--profile & {
            @include mq($from: desktop) {
                width: 180px;
                height: 180px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &__data {
        width: calc(100% - 120px);

        .c-author-info__header--profile & {
            @include mq($from: desktop) {
                width: 100%;
                padding-left: 200px;
            }
            
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        border-radius: 50%;
        object-fit: cover;
    }

    &__social {
        margin: 4px 0 0 -5px;
        display: flex;
        @include mq($from: desktop) {
            margin-top: 12px;
        }
    }

    &__name {
        font-family: $font-oswald-medium;
        font-weight: 500;
        font-size: 28px;
        color: $color-blue;
        line-height: 1.19;
        margin: 0 0 2px;
        @include mq($from: desktop) {
            font-size: 32px;
            font-weight: 400;
            font-family: $font-oswald;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 15px;
        @include mq($from: desktop) {
            font-size: 16px;
        }
    }

    &__content {
        margin-top: 30px;
        font-size: 16px;
        line-height: 1.5;

        .c-author-info--article & {
            @include mq($from: desktop) {
                width: calc(100% - 345px);
                margin-top: 0;
            }
        }

        .c-author-info__header--profile + & {
            @include mq($from: desktop) {
                padding-left: 200px;
            }
        }

        p {
            margin: 0 0 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__controls {
        margin-top: 30px;
        @include mq($from: desktop) {
            margin-top: 18px;
        }
    }

}