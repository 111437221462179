.c-channel-page {
    padding-top: 30px;
    @include mq($from: desktop) {
        padding-top: 50px;
    }
    &__title {
        font-family: $font-oswald-medium;
        font-weight: 500;
        display: flex;
        align-items: center;
        &--big {
            color: $color-blue;
            text-align: center;
            font-size: 28px;
            @include mq($from: desktop) {
                font-size: 47px;
            }
        }
        &--small {
            font-size: 14px;
            @include mq($from: desktop) {
                font-size: 20px;
            }
        }
        
        &--featured {
            color: $color-orange;
            font-size: 20px;
            margin-bottom: 15px;
            @include mq($from: desktop) {
                font-size: 32px;
            }
        }
        &--black {
            font-size: 24px;
            @include mq($from: desktop) {
                font-size: 30px;
            }
        }
        &-icon {
            color: $color-blue;
            font-size: 34px;
            margin-top: 1px;
            margin-right: 15px;
        }
    }
    &__featured {
        margin-top: 30px;
        .s-cms-content {
            margin-bottom: 60px;
        }
    }
    &-videos {
        +.s-cms-content {
            h2 {
                color: $color-blue;
            }
            a {
                color: $color-black;
            }
        }
    }
    &-video {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__item {
            margin-bottom: 32px;
            position: relative;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            
            &--big {
                max-width: 760px;
                display: block;
                width: 100%;
                height: 190px;
                @include mq($from: 600px) {
                    height: 320px;
                }
                @include mq($from: desktop) {
                    height: 430px;
                }
            }
            &--small {
                width: 100%;
                height: 190px;
                @include mq($from: 600px) {
                    height: 280px;
                }
                @include mq($from: desktop) {
                    width: calc(50% - 10px);
                    height: 328px;
                }
            }
            iframe {
                width: 100%;
                height: 100%;
            }
        }

        &__video {
            width: 100%;
            height: 100%;
        }
        
        &__content {
            position: absolute;
            height: 40px;
            background-color: $color-sky-blue;
            color: $color-blue;
            bottom: 0;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 5px 20px;
            @include mq($from: 600px) {
                height: 70px;
            }
            @include mq($from: desktop) {
                height: 86px;
            }
        }
        &__title {
            font-size: 14px;
            font-weight: 700;
            line-height: 1.15;
            @include mq($from: 600px) {
                font-size: 20px;
            }
            @include mq($from: desktop) {
                font-size: 24px;
            }
        }
    }
    &__controls {
        text-align: center;
        margin: 24px 0 56px;
        .c-button {
            color: $color-blue;
        }
    }
}
.c-channel-page-heading {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-top: 1px solid $color-silver;
    border-bottom: 1px solid $color-silver;
    margin-bottom: 25px;
    @include mq($from: desktop) {
        padding: 22px 0;
    }
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            color: $color-blue;
            text-decoration: underline;
        }
    }
}