
/* ==========================================================================
   #INPUT
   ========================================================================== */

input, select {
    font-family: inherit;
    appearance: none;
    color: inherit;
    @include placeholder(#666);
}

button {
    color: inherit;
}

select {
    padding-right: 32px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: calc(100% - 10px) center;
    background-size: 18px;
    line-height: 1.4;
    color: inherit;
    background-image: url('../images/components/forms/icon-chevron-down.svg');
    cursor: pointer;

    &::-ms-expand {
        display: none;
    }

    option {
        font-weight: normal;
    }
}