.c-faqs-page-accordion {
    list-style: none;
	
	&__item {
		position: relative;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #8AB2CB;
        @include mq($from: desktop){
            margin-bottom: 40px;
            padding-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
	
    &__title {
        padding-bottom: 3px;
        margin: 0;
        display: flex;
        position: relative;
        @include mq($from: desktop) {
            line-height: 1.5;
        }

        .o-icon {
            font-size: 16px;
            margin-right: 8px;
            color: $color-blue;
            transform: translate(0 , 5px);
            @include mq($from: desktop) {
                transform: translate(0 , 10px);
            }
        }

        h4 {
            color: $color-black;
            font-weight: 600;
            font-size: 18px;
            line-height: 1.5;
            margin: 0;

            @include mq($from: desktop) {
                font-size: 22px;
            }
            &.blue {
                color: $color-blue;
                font-weight: 700;
            }
        }
    }

    &__button {
        display: flex;
        position: relative;
        padding-right: 36px;
        width: 100%;
        background-color: transparent;
        font-size: 18px;
        font-weight: 700;
        color: $color-blue;
        text-align: left;

        @include mq($from: desktop) {
            font-size: 22px;
        }

        &:hover {
            color: $color-blue;
        }

        &::after {
            content: url('../images/components/faqs/icon-chevron.svg');
            display: flex;
            width: 26px;
            height: 26px;
            position: absolute;
            top: 0;
            right: 0;
        }

        &.active {
            color: $color-blue;

            &::after {
                transform: rotate(.5turn);
            }
        }
    }

    &__content{
        padding: 0 25px 0;
        
        p {

            a,strong {
                color: $color-blue;
                font-weight: 600;
            }
            a {
                word-break: break-word;
            }
        }

        ul {
            list-style: none;
            li{
                margin-bottom: 16px;
                &:last-child{
                    margin-bottom: 0;
                }
                span {
                    display: block;
                    font-weight: 600;
                }
                strong {
                    color: $color-blue;
                }
            }
            &.c-faqs-page-accordion__list--circle {
                list-style: disc;
                li {
                    &::marker {
                        color: $color-green;
                    }
                }
                a {
                    color: $color-blue;
                    font-weight: 600;
                }
                
            }
        }
    }

    h3 {
        color: $color-blue;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 18px;
        padding: 20px 0;
        @include mq($from: desktop){
            font-size: 22px;
        }
    }
}
	