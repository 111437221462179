.c-section {
    padding: 40px 0;
    background-color: #fff;

    &--light-blue {
        background-color: $color-light-blue;
        + .c-quick-quote {
            padding-top: 40px;
        }
    }
    &--light-silver {
        background-color: $color-light-silver;
        + .c-quick-quote {
            padding-top: 40px;
        }
    }
    
    &--small-spacing {
        padding: 36px 0;
    }
    &--spacing-bottom {
        padding-bottom: 26px ;
    }
    &--top-0-spacing {
        padding-top: 0;
    }
    &--bottom-0-spacing {
        padding-bottom: 0;
    }
    &--footer-disclaimer {
        margin-top: -50px;
        position: relative;
        @include mq($from: desktop) {
            margin-top: -50px;
        }
    }

    &__title {
        font-family: $font-oswald;
        text-align: center;
        font-size: 30px;
        color: $color-blue;
        font-weight: normal;
        margin: 0;
        line-height: 1.2;

        strong {
            font-family: $font-oswald-medium;
            font-weight: normal;
        }

        @include mq($from: desktop) {
            font-size: 36px;
        }

        &--black {
            color: $color-black;
        }
        &--blue-green {
            color: $color-blue-green;
        }

        &--small {
            font-size: 24px;
            @include mq($from: desktop) {
                font-size: 24px;
            }
        }
        &--medium{
            font-size: 24px;
            @include mq($from: desktop) {
                font-size: 32px;
            }
        }
        &--big {
            font-size: 28px;
            @include mq($from: desktop) {
                font-size: 52px;
            }
        }
        &--blue-strong {
            color: $color-blue;
            font-weight: 600;
        }
        &--left {
            text-align: left;
        }

        + .c-section__title--black  {
            font-size: 24px;
            margin-top: 24px;
            @include mq($from: desktop) {
                font-size: 32px;
            }
        }

        &.mb--large {
            margin-bottom: 36px;
            @include mq($from: desktop) {
                margin-bottom: 70px;
            }
        }
        
        &.u-text-centered {
            text-align: center !important;
        }

        &--center {
            text-align: center !important;
        }
    }

    &__subtitle {
        font-family: $font-opensans;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        margin: 8px 0 0;
        line-height: 1.2;
        @include mq($from: desktop) {
            font-size: 20px;
            text-align: left;
        }
    }

    &__intro-text {
        text-align: center;
        font-size: 16px;
        margin-top: 10px;
        @include mq($from: wide) {
            margin-top: 15px;
        }

        p {
            margin: 0 10px 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__content {
        margin-top: 56px;

        &:only-child {
            margin: 0;
        }

        .c-section__intro-text + &, &--small-top-margin {
            margin-top: 30px;
            @include mq($from: wide) {
                margin-top: 35px;
            }
        }
        /* looks like this cause a bug in the kc section home
        .c-section__intro-text + &, &--0-top-margin {
            margin-top: 0;
        }*/
    }
    &-question {
        h3 {
            font-size: 20px;
            margin-bottom: 16px;
            @include mq($from: desktop) {
                font-size: 24px;
                margin-bottom: 24px;
            }
        }
    }
}
.c-sections__list-column {

    ul {
        list-style: disc;
        margin: 25px 0 10px 15px;
        padding: 0;
        padding-left: 20px;
        text-align: left;

        ul{
            margin: 25px 0 0px 15px;
        }
        @include mq($from: tablet) {
            column-count: 1 ;
            column-gap: 0;
            
        }
        @include mq($from: desktop) {
            column-gap: 0;
        }
        
        li {
            padding-left: 5px;
            padding-bottom: 15px;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            @include mq($from: desktop) {
                padding-left: 10px;
            }
            &:last-child{
                padding-bottom: 0px;
            }
    
            &::marker {
                color: $color-green;
            }
            a {
                color: $color-blue;
                font-weight: 600;
            }
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
            h3 {
                margin-top: 0;
            }

            li::marker {
                color: #828282;
            }

        }
    }

    ol {
        margin: 25px 0 10px 15px;
        padding: 0;
        padding-left: 20px;
        text-align: left;

        li {
            padding-left: 5px;
            padding-bottom: 15px;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            @include mq($from: desktop) {
                padding-left: 10px;
            }
    
            a {
                color: $color-blue;
                font-weight: 600;
            }

            &::marker {
                font-weight: 600;
            }
        }
    }
    &--4 {
        max-width: 832px;
        margin: 30px auto 0;


        ul {
            column-count: 2 !important;

            @include mq($from: desktop) {
                column-count: 4 !important;
                column-gap: 23px;
            }
        }
    }
    
    p {
        strong {
            font-weight: 600;
        }
        a {
            color: $color-blue;
            font-weight: 600;
            @include mq($until: tablet) {
                word-break: break-word;
            }
        }
    }
    strong {
        font-weight: 600;
    }
    a {
        color: $color-blue;
        font-weight: 600;
    }

    
}