.c-tags {
    padding: 24px 20px;
    border: 1px solid $color-silver;
    margin: 30px 0;
    border-radius: 8px;
    @include mq($from: desktop) {
        margin: 40px 0;
    }

    &__title {
        font-family: $font-oswald-medium;
        font-weight: 500;
        font-size: 24px;
        line-height: 1.25;
        margin: 0;
        color: $color-blue-green;
        @include mq($from: desktop) {
            font-size: 28px;
        }
    }

    &__list {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px 20px;
        @include mq($from: desktop) {
            margin-top: 24px;
            gap: 16px 24px;
        }
    }

    &__item {
        width: calc(50% - 10px);
        @include mq($from: desktop) {
            width: auto;
        }
    }

    &__link {
        display: inline-block;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 400;
        font-size: 15px;
        line-height: 1.5;
        color: $color-black;

        &:hover {
            color: $color-blue;
        }
    }
}