/*/ ========================================================================
//   #OFFICE LOCATOR PROFILE - HERO
//   ======================================================================== */
.c-office-hero {
    position: relative;
    @include mq($until: desktop) {
        display: flex;
        flex-direction: column-reverse;

    }
    @include mq($from: desktop) {
        margin-bottom: 80px;
    }

    &-banner {
        @include mq($from: desktop) {
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
        }

        &__img {
            height: 150px;
            display: block;
    
            @include mq($from: desktop) {
                height: 501px;
            }

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 1;

        .o-wrapper {
            @include mq($until: tablet) {
                padding: 0;
            }
        }
        @include mq($from: desktop) {
            position: relative;
            top: 74px;

        }
    }

    &-nearest-store {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        background-color: $color-white;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mq($from: desktop) {
            border-radius: 8px;
        }
    
        &__content {
            width: 460px;
            padding: 15px 20px 31px;

            @include mq($from: desktop) {
                padding: 25px 0px 31px 40px ;
            }
        }
        &__phone {
            display: flex;
            margin: 10px 0 15px;
            text-decoration: underline;
            font-size: 18px;
            text-decoration: none;
            color: $color-blue;
            font-weight: 600;
            align-items: center;
            height: 50px;
            border-radius: 30px;
            border: 2px solid $color-blue;
            width: 183px;
            justify-content: center;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
            @include mq($from: desktop) {
                height: 46px;
            }
    
            &:hover {
                background-color: $color-blue;
                transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
                color: $color-white;
            }
    
            .o-icon {
                font-size: 18px;
                margin-right: 9px;
            }
    
        }

        &__brand {
            color: $color-blue;
            font-weight: 700;
            font-size: 16px;
            @include mq($from: desktop) {
                font-size: 18px;
            }
        }

        &__affiliated {
            color: $color-blue-green;
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 6px;
            @include mq($from: desktop) {
                margin-bottom: 10px;
            }
        }
    
        &__status {
            color: $color-blue;
            font-size: 20px;
            margin-bottom: 8px;
            font-weight: 600;
            strong {
                font-weight: 600;
            }
            &--closed {
                color: $color-orange;
                strong {
                    font-weight: 700;
                }
            }
            
            
        }
        &__title {
            font-size: 20px;
            line-height: 1.2;
            @include mq($from: desktop) {
                font-size: 24px;
            }
        }
        &__info {
            margin-top: 13px;
            &_item {
                display: flex;
               >span {
                   width: calc(100% - 24px);
               }
            }

            &-icon {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin-right: 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                .o-icon {
                    font-size: 13px;
                }
                .c-office-hero-nearest-store__address & {
                    border: 1px solid $color-blue;
                }
                .c-office-hero-nearest-store__reference & {
                    border: 1px solid $color-black;
                }
                .c-office-hero-nearest-store__get-directions & {
                    border: 1px solid $color-fuchsia;
                }
            }
        }
        &__address {
            margin-bottom: 8px;
            
            @include mq($from: desktop) {
                margin-bottom: 20px;
            }
            
            .o-icon {
                color: $color-blue;
            }
        }
        &__get-directions {
            padding-top: 20px;
            .o-icon {
                color: $color-fuchsia;
            }
            a {
                color: $color-black;
                strong {
                    font-weight: 600;
                }
                &:hover {
                    color: $color-fuchsia;
                }
            }
        }
        &__hours,&__features {
            margin: 24px 0 8px;
            font-weight: 600;
            font-size: 16px;
        }
        &__map {
            width: 100%;
            height: 200px;
            @include mq($from: tablet) {
                height: 250px;
            }
            @include mq($from: desktop) {
                margin-top: 25px;
                margin-bottom: 25px;
                padding: 0 20px 0;
                width: 425px;
                height: 440px;
            }
            &-wrapper, #js-office-locator-profile-map {
                width: 100%;
                height: 100%;
            }
            
        }
        &__reference {
            font-size: 15px;
        }
    }

}
/*/ ========================================================================
//   #OFFICE LOCATOR PROFILE - MESSAGE ALERT
//   ======================================================================== */
.c-office-hero-alert {
    padding: 30px 20px 0 20px;
    width: 100%;
    @include mq($from: desktop) {
        padding: 15px 20px 0 40px; 
    }
    &__content {
        width: 100%;
        border-radius: 8px;
        border: 1px solid $color-orange;
        display: flex;
        padding: 14px 15px 16px;
        position: relative;
        @include mq($from: desktop) {
            padding: 14px 35px 18px;
        }
    }
    &__img {
        margin-right: 25px;
        @include mq($until: desktop) {
            display: none;
        }
    }
    &__message { 
        > strong {
            font-weight: 600;
            font-size: 18px;
            color: $color-orange;
        }

        p {
            margin: 0;
            strong {
                color: $color-orange;
                font-weight: 600;
                
            }
            span {
                color: $color-blue;
            }
            a {
                color: $color-blue;
                font-weight: 600;
            }
        }
        
    }
    &__close {
        background-color: $color-white;
        color: $color-orange;
        width: 24px;
        height: 24px;
        line-height: 0;
        font-size: 21px;
        position: absolute;
        right: -8px;
        top: -8px;
    }
}

/*/ ========================================================================
//   #OFFICE LOCATOR PROFILE - TOGGLE (office hours)
//   ======================================================================== */
.c-office-hero-toggle {

    strong {
        font-weight: normal;
        width: 130px;
    }
    
    &__item{
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__content{
        position: relative;
        width: 100%;
        font-size: 15px;
        color: $color-blue-green;
        width: 275px;

        .c-office-hero-toggle__item p{
            margin: 0;
            width: 138px;
        }
        > button.c-office-hero-toggle__item  {
            background-color: transparent;
            color: $color-blue-green;

            span:first-child {
                width: 138px;
                text-align: left;
            }
            
        }

        ul {
            display: none;
            width: 100%;
            color: $color-black;
        }
        
        .c-office-hero-toggle__icon {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            right: 0;
            top: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .3s ease;
            position: absolute;
            right: -29px;
            &::before , &::after{
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background-image: url(../images/components/office-locator/icon-add.svg);
                top: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
               
            }
            &::after {
                background-image: url(../images/components/office-locator/icon-line.svg);
            }
            &:hover {
               
                &:before , &:after {
                    
                }         
            }
        }
        &.active {
            
            .c-office-hero-toggle__icon {
                &::after {
                    background-image: url(../images/components/office-locator/icon-line.svg);
                }
                &::before {
                    display: none;
                }
            }
        }
    }
    
} 

/*/ ========================================================================
//   #OFFICE LOCATOR PROFILE - Features
//   ======================================================================== */
.c-office-hero-features {

    &__item {
        .o-icon {
            color: $color-blue;
            margin-right: 8px;
        }
        a {
            color: $color-black;
            &:hover{
                color: $color-blue;
            }
        }
    }
}
