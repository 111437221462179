.c-kc-page-article {
    .c-quick-quote {
        margin-bottom: 50px;
    }
    &-intro {
        margin-top: 40px;
        .o-wrapper {
            @include mq($until: tablet){
                padding: 0;
            }
        }
        &__content, .c-kc-page-article__text {
            @include mq($until: tablet){
                padding: 0 20px;
            }
        }
    }

    &__title {
        color: $color-blue;
        font-family: $font-oswald;
        font-size: 26px;
        margin: 0 0 13px;
        font-weight: 400;
        line-height: 1.3;
        text-align: center;
        @include mq($from: tablet) {
            font-size: 36px;
            margin: 0 0 30px;
        }
        &--black {
            font-size: 18px;
            font-weight: 600;
            font-family: $font-opensans;
            color: $color-black;
            text-align: center;
            padding-top: 30px;
            @include mq($from: tablet) {
                font-size: 24px;
                padding-top: 0;
            }
        }
        &--left {
            @include mq($from: tablet) {
                text-align: left;
            }
        }
    }
    &__text {
        @include mq($from: tablet) {
            padding-right: 10px;
        }
        a {
            color: $color-blue;
        }
        strong {
            font-weight: 600;
            &.c-kc-page-article__text-strong-blue {
                color: $color-blue;
                font-weight: 700;
            }
        }
    }
    &__data {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 10px;
        @include mq($from: tablet) {
            justify-content: space-between;
        }
    }
    &__author-date {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @include mq($from: tablet) {
            padding-right: 25px;
        }
    }
    &__author {
        span {
            margin-right: 6px;
            font-weight: 600;
        }
    }
    &__share {
        display: none;
        > span {
            margin-right: 7px;
        }
        @include mq($from: tablet) {
            display: flex;
            align-items: center;
        }
    }
    &__social-media {
        background-color: $color-white;
        border-radius: 50px;
        color: $color-blue;
        text-decoration: none;
        margin: 0 5px;
        width: 25px;
        height: 25px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__img {
        height: 192.5px;
        @include mq($from: tablet) {
            height: 300px;
        }
        @include mq($from: desktop) {
            height: 500px;
        }
    }
    .c-state-table {
        max-width: 800px;
    }
    .u-text--left {
        @include mq($until: tablet) {
            text-align: center;
        }
    }

    &__list{
        padding-left: 65px;
        margin-top: 30px;
        margin-bottom: 0;
        li {
            margin-bottom: 8px;
        }

        &--circle {
            li {
                padding-left: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
                &::marker {
                    color: $color-green;
                }
            }
        }
        &--check {
            margin-bottom: 30px;
            list-style: none;
            padding-left: 0;
            @include mq($from: tablet) {
                margin-left: 30px;
            }
            li {
                background-image: url('../images/components/list/icon-check.svg');
                background-repeat: no-repeat;
                background-position: left 4px;
                padding-left: 30px;
                margin-bottom: 10px;
                background-size: 16px;
                @include mq($from: tablet) {
                    background-size: 20px;
                    background-position: left 2px;
                    padding-left: 40px;
                }
            }
        }
    }

    &__img-text {
        margin-bottom: 25px;
        margin-top: 30px;
        flex-direction: column;
        padding-top: 40px;
        position: relative;
        @include mq($from: tablet) {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-top: 0;
        }
        &:last-child {
            margin-bottom: 50px;
        }
        &_text {
            p {
                margin: 12px 0 0;
            }
            strong {
                font-weight: 600;
                font-size: 18px;
                position: absolute;
                top: 0;
                line-height: 1;
                @include mq($from: mobile){
                    font-size: 20px;
                }
                @include mq($from: tablet) {
                    font-size: 22px;
                    position: relative;
                }
            }
        }
        &_img-small {
            width: 240px;
            height: 124px;

            @include mq($from: tablet) {
                margin-right: 20px;
            }
        }
        &_img-medium {
            max-width: 460px;
            width: 100%;
            img {
                width: 100%;
            }
            @include mq($from: tablet) {
                margin-right: 30px;
            }
        }
    }
    &__imgs-group {
        .o-wrapper {
            display: flex;
            flex-direction: column;
        }
        
        padding: 10px 0 25px;
        img {
            margin: 0 auto 30px;
        }
        &_big {
            width: 100%;
            height: auto;
            @include mq($from: tablet){
                height: 313px;
                width: 600px;
            }
        }
        &_medium {
            width: 287px;
            height: auto;
            @include mq($from: tablet){
                width: 460px;
                height: 240px;
            }
        }
        &_small {
            width: 240px;
            height: 124px;
        }
    }

    &__video {
        max-width: 460px;
        margin: 30px auto;
        video {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    &__additional-links {
        margin-top: -15px;
        border-top: 1px solid $color-silver;
        padding-top: 20px;
        margin-bottom: 56px;
        p {
            font-size: 18px;
            margin: 0 0 15px;
            @include mq($from: tablet) {
                font-size: 20px;
                margin: 0 0 10px;
            }
            a {
                color: $color-blue;
                &:hover {
                    opacity: .9;
                }
            }

        }
    }
}
