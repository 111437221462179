.c-article-preview {
    &__tags {
        display: flex;
        align-items: center;
        gap: 4px;
        flex-wrap: wrap;
        color: $color-blue-green;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.1;
    }

    &__info {
        @include mq($from: desktop) {
            display: flex;
            align-items: center;
            gap: 4px;
            flex-wrap: wrap;
        }
    }

    &__tag {
        text-decoration: none;
        color: $color-blue-green;

        &:hover {
            text-decoration: underline;
        }
    }

    &__read-time {
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        @include mq($from: desktop) {
            margin-top: 0;
            display: flex;
            gap: 4px;
            align-items: center;

            &::before {
                content: '|'
            }

            &:only-child::before {
                display: none;
            }
        }
    }

    &__content {
        margin-top: 8px;
        display: flex;
        gap: 18px;
        align-items: flex-start;
        @include mq($from: desktop) {
            margin-top: 20px;
            gap: 20px;
            .c-featured-articles & {
                margin-top: 10px
            }
        }
    }

    &__title-link {
        color: $color-black;
        text-decoration: none;
        width: calc(100% - 140px);
        display: flex;
        .c-minisite-preview__list--separator & {
            @include mq($from: desktop) {
                width: calc(100% - 218px);
            }
        }
        .c-featured-articles__item--box  & {
            width: calc(100% - 140px);
            @include mq($from: desktop) {
                width: calc(100% - 180px);
            }
        }
        .c-featured-articles__item--separator &{
            width: auto;
        }

        &:hover {
            text-decoration: underline;
            color: $color-blue;
        }
    }

    &__title {
        color: inherit;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.4;
        margin: 0;

        @include mq($from: desktop) {
            font-size: 20px;
            .c-featured-articles__item--box &{
                font-size: 22px;
            }
        }
        &.has-icon {
            position: relative;
            &:before {
                content: url('../images/components/blog/icon-youtube.svg');
                margin-right: 8px;
            }
        }
    }

    &__pic {
        display: block;
        width: 120px;
        overflow: hidden;
        cursor: pointer;

        &--video {
            position: relative;
            &:before {
                content: url('../images/components/blog/icon-youtube.svg');
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(2.1);
                display: block;
                line-height: 0.7;

                .c-article-header-media--video-list &, .c-minisite-preview__list--boxes & {
                    transform: translate(-50%, -50%) scale(1.15);
                }
            }
        }

        .c-minisite-preview__list--separator & {
            @include mq($from: desktop) {
                width: 200px;
            }
        }
        .c-featured-articles & {
            width: 140px;
            @include mq($from: desktop) {
                width: 180px;
            }
        }
    }

    &__img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
}