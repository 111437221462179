.u-text {
    &-small {
        font-size: 14px;

        a {
            color: $color-blue;
            font-weight: 600;
        }

    }
    &-centered {
        text-align: center;
    }
    &--left {
        text-align: left;
    }
    &-italic{
        font-style: italic;

        &--small {
            font-size: 14px;
        }
    }
    &-underline {
        text-decoration: underline;
    }
    &-uppercase {
        text-transform: uppercase;
    }

}