.c-office-description {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.c-office-detail {
    width: 100%;
    @include mq($from: desktop){
        width: calc(50% - 37px);
    }
}
.c-office-detail {
    margin-bottom: 40px;
    @include mq($from: desktop){
        margin-bottom: 0;
    }
    &__description {
        a {
            color: $color-blue;
        }
        strong {
            font-weight: 600;
        }
    }
    &__img {
        max-width: 434px;
        height: 163px;
        @include mq($from: tablet){
            height: 218px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
    }
}
.c-office-products-services {
    width: 100%;
    @include mq($from: desktop){
        width: calc(50% - 35px);
    }
    .s-cms-content p {
        margin: 16px 0 35px 0 !important;
    }
}

.c-office-products-list {
    margin-top: 16px;
    @include mq($from: tablet){
        column-count: 2;
        column-gap: 35px;
    }
    &--column {

        @include mq($from: tablet){
            column-count: 1;
            column-gap: 0;
        }
    }
    
    &__item {
        position: relative;
        padding-left: 20px;
        margin-bottom: 6px;

        &:before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $color-gray-3;
            left: 0;
            top: 8px;
            bottom: 0;
        }
        &:hover {
            &:before {
                background-color: $color-blue;;
            }
        }
    }

    &__link {
        text-decoration: none;
        color: $color-black;
        &:hover {
            color: $color-blue;
            text-decoration: underline;
        }
    }
}