.c-blog-section {
    margin: 30px 0;
    @include mq($from: tablet) {
        margin: 40px 0;
    }

    &:last-of-type {
        //margin-bottom: 0;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 0 8px;
    }

    &__icon {
        width: 32px;
        height: auto;
        display: block;
        @include mq($from: tablet) {
            width: 40px;
        }
        @include mq($from: desktop) {
            width: 48px;
        }
    }

    &__title {
        font-family: $font-oswald-medium;
        font-weight: 500;
        font-size: 24px;
        margin: 0;
        line-height: 1.3;
        color: $color-blue;
        @include mq($from: tablet) {
            font-size: 32px;
        }
        @include mq($from: desktop) {
            font-size: 40px;
        }

        strong {
            font-weight: normal;
            color: $color-black;
        }

        a {
            text-decoration: none;
            color: inherit;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__content {
        margin-top: 8px;
        @include mq($from: desktop) {
            margin-top: 24px;
        }

        &:first-child {
            margin-top: 0;
        }
    }
}