/*** ========================================================================
//   #SITE FOOTER LEGAL
//   ======================================================================== */
.c-site-footer-legal {
    background-color: $color-blue;
    padding: 30px 0;

    .o-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        @include mq($from: desktop) {
            flex-direction: row;
        }
    }
    
    &__text {
        color: $color-white;
        font-size: 14px;
        margin: 0;

        a {
            color: inherit;
            &:hover {
                opacity: .8;
            }
        }
    }
    &__security {
        display: flex;
        margin-top: 25px;
        
        @include mq($from: desktop) {
            margin-top: 0;
        }
        
        &-img {
            display: flex;
            margin-left: 6px;
            
            &:nth-child(1) {
                background-color: $color-white;
                border-radius: 2px;
                margin-top: 1px;
            }
        }
    }
}