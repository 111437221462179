.c-contact-us {
    max-width: 1000px;
    margin: auto;

   padding-bottom: 50px;
   @include mq($from: tablet) {
       padding-bottom: 100px;
       display: flex;
   }


    &__item {
        max-width: 370px;
        border: 1px solid $color-silver;
        padding: 25px 30px 20px;
        margin-bottom: 20px;
        min-height: 111px;
        @include mq($from: tablet) {
            // width: 370px;
            max-width: initial;
            width: 100%;
        }
        @include mq($from: desktop) {
            padding: 25px 34px 20px;
        }

        &:last-child {
            @include mq($from: tablet) {
                margin-right: 0;
                margin-bottom: 0;
            }
            
        }
    }
    &__title {
        color: $color-blue;
        font-family: $font-oswald;
        font-size: 24px;
        margin: 0;
        margin-bottom: 10px;
        font-weight: normal;
        @include mq($from: tablet) {
            font-size: 28px;
            margin-bottom: 15px;
            text-align: left;
        }
        &--big {
            font-size: 28px;
            line-height: 1.2;
            @include mq($from: tablet) {
                font-size: 36px;
            }
        }
    }
    &__controls {
        max-width: 260px;
        width: 100%;
        &-item {
            color: $color-black;
            display: flex;
            text-decoration: none;
            margin-bottom: 8px;
            gap: 11px;
            align-items: center;
        }
        &-icon {
            color: $color-blue;
        }
        
    }

    &-info {
        max-width: 410px;
        width: 100%;

        @include mq($from: tablet) {
            border-left: 1px solid $color-silver;
            margin-left: 50px;
            padding-left: 50px;
        }
        &__item {
            margin-top: 30px;
            @include mq($from: desktop) {
                margin-top: 15px;
                margin-bottom: 30px;
            }
        }

        &__title-icon {
            

            &--payment {
                color: $color-blue-green;
            }

        }

        &__icon {
            font-size: 30px;
            position: relative;
            //top: -2px;
        }

        &__title {
            color: $color-blue;
            font-size: 28px;
            font-family: $font-oswald;
            font-weight: 400;
            line-height: 1.07;
            margin: 0;
            margin-bottom: 5px;
            display: flex;
            gap: 12px;
            align-items: center;
            color: $color-blue;
            @include mq($from: tablet) {
                font-size: 36px;
            }

            .c-contact-us-info__title-icon--payment & {
                color: $color-blue-green;
            }
        }
        &__description {
            margin-top: 8px;
            margin: 0;

            a {
                color: $color-blue;
                font-weight: 600;
            }

            + .c-button {
                margin-top: 12px;
            }
        }
    }
}