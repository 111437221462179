.c-choose {
    &--v1 {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1.62rem;
        margin-top: 50px;
        @include mq($from: desktop) {
            margin-top: 55px;
        }
    }

    &-form {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 24px;
        margin-top: 50px;
        .c-choose--v1 &{
            margin-top: 0;
        }

        @include mq($from: desktop) {
            margin-top: 35px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            .c-choose--v1 &{
                margin-top: 0;
            }
        }


        &__item {
            width: 100%;
        }

        &__text {
            text-align: center;
            font-size: 18px;
            @include mq($from: tablet){
                font-size: 20px;
                br {
                    display: none;
                }
            }
            p {
                margin: 0;
            }
            strong {
                color: $color-blue;
            }
        }

        &__list {
            text-align: left;
            margin: 20px 0 0;
            padding-left: 30px;
            
            .single-plan & {
                list-style-image: url('../images/components/list/icon-check-orange.svg');
            }
            .family-plan & {
                list-style-image: url('../images/components/list/icon-check-blue-green.svg');
            }
            .c-choose--v1 &{
                list-style-image: url('../images/components/list/icon-check-green.svg');
            }


            li {
                margin-bottom: 16px;
                line-height: normal;

                &:last-child {
                    margin-bottom: 0px;
                }

                &::marker {
                    font-size: 1rem;
                }
            }
        }

        &__control {
            margin-top: 20px;
            text-align: center;
        }
    }

    &-switch {
        background-color: $color-white;
        padding: 10px 12px;
        border-radius: 34px;
        max-width: 370px;
        margin: 0 auto;

        &__content {
            display: flex;
            border-radius: 34px;
            overflow: hidden;
            position: relative;
            width: 100%;
            justify-content: space-around;
        }

        .is-keyboard-pressed &:focus-within {
            outline: 2px solid #FF6200;
        }

        &__radio:checked+&__label {
            color: #fff;
            transition: color 0.2s ease-in-out;
            img {
                display: block;
                
            }
        }

        &__radio:nth-child(3):checked~&__indicator {
            transform: translateX(calc(100% + 2px));
            transition: transform 0.2s ease-in-out;
        }

        &__label {
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            font-size: 18px;
            color: $color-black;
            transition: color 0.2s ease-in-out;
            position: relative;
            z-index: 2;
            font-weight: 700;
            width: 50%;
            cursor: pointer;

            @include mq($from: desktop) {
                padding: 0 24px;
                font-size: 20px;
            }
            img {
                display: none;
                width: 20px;
                margin-left: 8px;
            }
        }

        &__indicator {
            width: 50%;
            height: 100%;
            background-color: $color-blue;
            position: absolute;
            left: -1px;
            top: 0;
            z-index: 1;
            border-radius: 34px;
            transition: transform 0.2s ease-in-out;
        }
    }

    &-plans {
        background-color: $color-white;
        border-radius: 16px;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
        padding: 30px;
        width: 260px;
        position: relative;
        margin-top: 15px;

        &.most-popular {
            border: 1px solid $color-green;
        }

        @include mq($from: desktop) {
            padding: 30px 48px;
            width: 306px;
        }
    }

    &-heading {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq($from: desktop) {
            flex-direction: column;
        }
    }

    &__title {
        font-size: clamp(1.25rem, 4vw, 1.5rem);
        margin: 0 0 0 6px;
        line-height: 1.2;

        @include mq($from: desktop) {
            margin: 0;
        }

        &--orange {
            color: $color-orange;
        }

        &--blue-green {
            color: $color-blue-green;
        }

        &--blue {
            color: $color-blue;
        }
    }

    &__icon {

        display: block;
        width: 48px;
        height: auto;

        @include mq($from: desktop) {
            width: 72px;
            height: 72px;
            margin: 0 auto 8px;
        }

    }

    &__badge {
        background-color: $color-green;
        padding: 9px 10px;
        color: $color-white;
        font-weight: 700;
        text-align: center;
        border-radius: 8px;
        position: absolute;
        top: -20px;
        width: 127px;
        left: 0;
        right: 0;
        margin: auto;
    }

    &__options {
        margin-top: 13px;
        display: flex;
        justify-content: center;
        gap: 10px;

        @include mq($from: desktop) {
            margin-top: 20px;
            gap: 16px;
        }
    }

    &__option {

        .is-keyboard-pressed &:focus-within {
            outline: 2px solid orange;
        }

        &:last-child {
            margin-bottom: 0;
        }
        span {
            font-size: 1rem;
            color: #828282;
            font-weight: 700;
            .c-choose--v1 & {
                color: $color-black;
                font-weight: normal;
            }
        }

        strong {
            font-size: 24px;

            @include mq($from: desktop) {
                margin-top: 5px;
                display: inline-block;
                font-size: 32px;
            }

            .single-plan & {
                color: $color-orange;
            }

            .family-plan & {
                color: $color-blue-green;
            }

        }
    }
    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
        text-align: center;
    }
    legend {
        display: flex;
        align-items: center;
        text-align: center;
        @include mq($from: desktop){
            flex-direction: column;
        }
    }

    &__label {
        display: block;
        background-color: $color_white;
        border-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
        border: 2px solid $color_white;
        cursor: pointer;
        transition: box-shadow 0.2s linear;
        position: relative;
        text-align: center;
        padding: 6px 5px;
        height: auto;
        width: 100px;
        // unususable for the moment
        cursor: none;
        pointer-events: none;
        @include mq($from: desktop) {
            padding: 17px 5px;
        }
        &:hover {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.28);
            transition: box-shadow 0.2s linear;
        }
        .c-choose__radio:checked + & {
            border: 2px solid $color-green;
            .single-plan &{
                border: 2px solid $color-orange;
            }
            .family-plan &{
                border: 2px solid $color-blue-green;
            }
        }
        .single-plan & {
            color: $color-blue-green;
        }

        .family-plan & {
            color: $color-orange;
        }
        strong {
            font-size: clamp(1.25rem, 4vw, 1.5rem) !important;
        }
    }
}