/*/ ========================================================================
//   #KC TEASER
//   ======================================================================== */
.c-kc-teaser {
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 100%;
        background-color: $color-white;
        box-shadow: 0px 4px 12px rgba(65, 70, 72, 0.12);
        margin: 0 15px 24.5px;

        @include mq($from: desktop) {
            margin: 0 12.5px 25px;
        }

        &--50 {
            @include mq($from: tablet) {
                width: calc(50% - 51px);
            }
        }

        &--33 {
            @include mq($from: desktop) {
                width: calc(33.33% - 26px);
            }
        }
    }

    &-preview {
        display: block;
        text-decoration: none;
        color: inherit;

        &__img {
            height: 186px;
            display: block;
            cursor: pointer;
            @include mq($from: desktop) {
                height: 210px;
            }

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }
        }

        a {
            text-decoration: none;
            display: block;
            color: inherit;
        }

        &__title {
            font-size: 22px;
            font-weight: 600;
            margin: 0px 0 6px;
            line-height: 1.3;
            display: block;
            color: $color-black;

            &:hover {
                color: $color-blue;
                text-decoration: underline;
            }
        }

        &__link {
            font-size: 18px;
            font-weight: 600;
            color: $color-black;
            &:hover {
                color: $color-blue;
                text-decoration: underline;
            }
        }
        &__content {
            padding: 20px 17px 19px;

            a + .c-kc-teaser-preview__title {
                margin: 8px 0 6px;
            }
        }


        &__text {
            margin-top: 0;
            margin-bottom: 26px;
        }
    }
}