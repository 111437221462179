.c-quick-quote {
    padding-bottom: 30px ;
    + .c-section {
        padding-top: 10px;
    }
    &:last-child {
        margin-bottom: 50px;
        @include mq($from: desktop) {
            margin-bottom: 30px;
        }
    }
    

    &__content {
        background-color: $color-light-blue;
        padding: 35px 20px;
        @include mq($from: desktop) {
            padding: 56px 20px;
        }
    }

    &__title {
        font-family: $font-oswald;
        text-align: center;
        font-size: 30px;
        font-weight: normal;
        margin: 0;
        line-height: 1.2;
        @include mq($from: desktop) {
            font-size: 36px;
        }
    }

    &__form {
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 35px 26px;
        max-width: 715px;
        margin: 24px auto 0;
        @include mq($from: desktop) {
            padding: 35px 54px;
            max-width: 800px;
            margin-top: 36px;
        }
    }

    &__links {
        text-align: center;
        margin-top: 22px;
        @include mq($from: desktop) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 38px;
        }
    }

    &__learn-products {
        max-width: 70%;
        display: inline-block;
        text-decoration: underline;
        color: inherit;
        font-weight: 600;
        html[lang=es-US] & {
            max-width: 90%;
        }

        &:hover {
            color: $color-blue;
        }
    }

    &__phone {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        @include mq($from: desktop) {
            margin-top: 0;
        }

        &-text {
            color: $color-blue;
            font-size: 18px;
            font-weight: 700;
        }

        &-number {
            font-weight: 600;
            color: inherit;
            margin-left: 5px;
            display: inline-block;
            font-size: 16px;
            text-decoration: underline;
            html[lang=es-US] & {
                display: block;
            }
        }
    }

    &__reviews {
        margin-top: 30px;

        iframe {
            height: 42px !important;
            @include mq($from: 490px) {
                height: 20px !important;
            }
        }
    }
    label.form__error {
        background-color: $color-orange;
        color: #fff;
        font-size: 12px;
        padding: 6px 9px;
        display: inline-block;
        margin-top: 7px;
        border-radius: 6px;
        position: relative;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -5px;
            left: 20px;
            width: 1px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 5px solid $color-orange;
        }
    }
}
