///* ========================================================================
//   #SITE HEADER
//   ======================================================================== */

.c-site-header {
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    max-width: 1600px;
    width: 100%;
    z-index: 10;
    margin: 0 auto;
    @include mq($from: desktop) {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    }
    @include mq($from: wide) {
        background-color: #fff;
    }

    &--blog {
        position: relative;
    }

    .o-wrapper {
        @include mq($from: wide) {
            position: relative;
        }
    }

    &__logo {
        display: block;
        width: 100px;

        @include mq($from: desktop) {
            width: 155px;
        }

        @include mq($from: wide) {
            width: 170px;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        &--hippo {
            width: 157px;
            @include mq($from: desktop) {
                width: 208px;
            }

            @include mq($from: wide) {
                width: 249px;
            }
        }
    }

    &__controls-mobile {
        @include mq($from: desktop) {
            display: none;
        }
    }

    &__controls-desktop {
        display: none;
        @include mq($from: desktop) {
            display: block;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 10;
        padding: 22px 20px;
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

        @include mq($from: wide) {
            box-shadow: -20px 0px 0 0px white, 20px 0px 0 0px white;
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    &__menu-mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        transform: translateX(100%);
        visibility: hidden;
        transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out 0.3s;
        @include mq($from: desktop) {
            display: none;
        }

        &.is-open {
            transform: translateX(0%);
            visibility: visible;
            transition: transform 0.3s ease-in-out;
        }
    }

    &__nav-desktop {
        display: none;
        @include mq($from: desktop) {
            display: block;
            box-shadow: -20px 0px 0 0px white, 20px 0px 0 0px white;
        }
    }

    &__nearest-store {
        @include mq($from: wide) {
            position: absolute;
            top: 10px;
            left: 215px;
            z-index: 10;
            max-width: 412px;
        }
    }

    &__teaser-bar {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: -10;
        width: 100%;
        @include mq($from: wide) {
            position: absolute;
            top: auto;
            left: auto;
            width: auto;
            bottom: 9px;
            right: 0;
            z-index: 1;
        }
    }

    &__skip {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        pointer-events: none;
        background-color: #fff;
        text-decoration: none;
        padding: 10px 20px;
        display: inline-block;
        color: $color-black;
        text-decoration: underline;
        font-weight: 600;
        font-size: 14px;
        z-index: 100;
        border: 2px dashed $color-orange;
        &:focus {
            opacity: 1;
            pointer-events: all;
            outline: none;
            //border: 2px dashed #C74E10;;
        }
    }
}