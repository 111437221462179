.c-easy-banner {
    position: relative;

    &__content {
        position: absolute;
        width: 100%;
        padding: 0 20px;
        z-index: 1;
        top: 25px;
        @include mq($from: desktop) {
            top: 45px;
        }
    }
    &-img {
        background-color: $color-light-blue;
    }
    &__img {
        height: 180px;
        @include mq($from: tablet) {
            height: 250px;
        }
        @include mq($from: desktop) {
            height: 321px;
        }
    }

    &__title {
        text-align: center;
        padding-bottom: 15px;
        font-size: 28px;
        font-family: $font-oswald-medium;
        font-weight: 500;
        margin: 0;
        line-height: 1;
        color: $color-blue;
        @include mq($from: desktop) {
            font-size: 52px;
        }
    }
    &-franchise {
        .c-easy-banner__title {
            font-size: 26px;

            @include mq($from: desktop) {
                font-size: 52px;
            }
        }
    }
}