.c-press {

    &__wrapper {
        
        @include mq($from: tablet) {
            padding-top: 30px;
        }
    }
    
    @include mq($until: tablet) {
        .c-easy-banner {
            display: flex;
            flex-direction: column-reverse;

            &__content{
                padding: 40px 15px 10px;
                position: relative;
                top: auto;
            }
            &__img {
                @include mq($until: tablet) {
                    height: 135px;
                }
            }
        }
        
    }

    &__title {
        font-size: 24px;   
        font-family: $font-oswald;
        font-weight: 400;
        border-bottom: 1px solid $color-silver;
        margin-bottom: 0;
        padding-bottom: 15px;
        margin-top: 40px;
        &:first-of-type {
            margin-top: 0;
        }
        @include mq($from: tablet) {
            font-size: 28px;
            margin-top: 56px;
        }
    }
    &-content {

        &__img {
            &-img {
                width: 100px;
                @include mq($from: tablet) {
                    width: 150px;
                    margin-right: 30px;
                }
            }
        }
        &__item {
            border-bottom: 1px solid $color-silver;
            display: flex;
            flex-direction: column;
            padding: 12px 0 12px;
            @include mq($from: tablet) {
                flex-direction: row;
                padding: 30px 5px 30px 0;
            }
            &:last-of-type {
                margin-bottom: 39px;
            }
        }
        &__description {
            &-date {
                margin-top: 8px;
            }
            &-link {
                color: $color-black;
                font-size: 20px;
                &:hover {
                    color: $color-blue;
                }
            }
        }
    }
    &-controls {
        text-align: center;
        .c-button {
            color: $color-orange;
            margin-bottom: 56px;
        }
    }
}
