.c-quick-facts {
    max-width: 815px;
    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq($from: desktop) {
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__item {
        width: 100%;
        margin: 0 0 26px;
        display: flex;
        align-items: flex-start;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__image {
        width: 52px;
        height: auto;
        display: block;

        @include mq($from: desktop) {
            width:auto;
        }
        
    }

    &__content {
        padding-left: 22px;
        @include mq($from: desktop) {
            padding-left: 30px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        margin: 0 0 5px 0;
    }

    &__text {
        line-height: 1.5;
        font-size: 16px;
        margin: 0;
        a {
            color: $color-blue;
            font-weight: 600;
        }
    }
    @media screen and (min-width:0\0){
        .c-quick-facts__image {
            width: auto;
        }
    }
    + .s-cms-content {
        margin-top: 35px;
    }
}
.s-cms-content {
    +.c-quick-facts {
        margin-top: 35px;
    }
}

