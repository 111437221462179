.page-franchise {
    .c-section__title {
        font-size: 28px;
        @include mq($from: desktop) {
            font-size: 36px;
        }
        @include mq($until: desktop) {
            br {
                display: none;
            }
        }
    }
    .c-easy-banner {
        &-franchise {
            .c-easy-banner__title {
                font-size: 22px;
                @include mq($from: desktop) {
                    font-size: 52px;
                }
            }
        }
    }
}