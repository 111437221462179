/*** ========================================================================
//   #SITE FOOTER MENU
//   ======================================================================== */
.c-site-footer-menu {
    text-align: left;
    padding: 39px 0 0;

    @include mq($from: desktop) {
        width: calc(100% - 307px);
        padding: 0px 0 0;
    }

    &__category-title, &__category-link {
        font-family: $font-opensans;
        font-weight: 700;
        font-size: 16px;
        color: $color-black;
        margin: 0 0 16px 0;
        @include mq($from: desktop) {
            font-size: 18px;
        }
    }

    &__category-link {
        margin-bottom: 0;
    }
    
    &__item {
        a {
            text-decoration: none;
            display: inline-block;
            &:hover {
                text-decoration: underline;
            }

            // &.js-osano-trigger {
            //     display: inline-flex;
            //     align-items: center;
            //     gap: 5px;

            //     &::after {
            //         content: url('../images/components/footer/privacyoptions.svg');
            //         width: 30px;
            //         display: inline-block;
            //         height: auto;
            //         transform: translateY(1px);
            //     }
            // }
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item-33 {
        @include mq($from: tablet) {
            width: 33%;
        }
    }

    &__item--m50 {
        @include mq($until: tablet) {
            width: 50%;
        }
    }
    
    &__item-100 {
        width: 100%;
    }

    #products {
        .c-submenu-footer {
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
        }
    }
    #state, #cars_models {
        .c-submenu-footer {
            &__list {
                display: flex;
                flex-wrap: wrap;
                gap: 0 12px;
            }

            &__item {
                margin-bottom: 5px;
                &--bold {
                    a {
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.c-submenu-footer {
    &__list {
        margin-bottom: 35px;
        padding-right: 15px;
    }
    &__item {
        margin-bottom: 10px;
    }
    &__link {
        transition: all .25s, outline 0s, outline-offset 0s;
        text-decoration: none;
        font-size: 14px;
        color:$color-black;

        &:hover {
            transition: all .25s, outline 0s, outline-offset 0s;
            color: $color-blue;
            text-decoration: underline;
        }
        @include mq($from: tablet) {
           font-size: 15px;
        }
    }
}