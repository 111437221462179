///* ========================================================================
//   #SITE HEADER MENU MOBILE
//   ======================================================================== */

.c-menu-mobile {
    &__header {
        padding: 22px 20px;
        display: flex;
        justify-content: flex-end;
        background-color: $color-blue;

        .c-site-header-controls-mobile__control {
            color: #fff;
        }
    }

    &__content {        
        padding: 26px 20px;
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__search {
        margin-top: 24px;
    }

    &__nav {
        margin-top: 20px;
    }

    &__social-media {
        margin-top: 32px;
    }
}