.c-product-category-banner {

    .o-wrapper {
        display: flex;
        flex-direction: column-reverse;
        @include mq($from: desktop) {
            position: relative;
        }
    }

    &__img {
        height: 135px;
        width: calc(100% + 40px);
        margin-left: -20px;
        @include mq($from: desktop) {
            height: 226px;
            width: 100%;
            margin-left: 0;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    &__title {
        text-align: center;
        padding: 24px 0;
        font-size: 30px;
        font-family: $font-oswald;
        font-weight: normal;
        margin: 0;
        line-height: 1;
        @include mq($from: desktop) {
            position: absolute;
            top: 80px;
            left: 60px;
            font-size: 36px;
            text-align: left;
            z-index: 1;
            padding: 0;
        }

        &--blue {
            color: $color-blue;
            font-weight: 500;
            font-size: 28px;
            @include mq($from: desktop){
                font-size: 40px;
            }
        }
    }
}