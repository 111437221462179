/*/ ========================================================================
//   #OFFICE LOCATOR PROFILE - PRODUCTS
//   ======================================================================== */
.c-office-products{
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    @include mq($from: tablet) {
        margin-top: 35px;
    }

    &__item {
        width: 100%;
        background-color: $color-white;
        box-shadow: 0px 4px 12px rgba(65, 70, 72, 0.12);
        margin-bottom: 30px;

        @include mq($from: tablet){
            margin: 0 40px 45px 0;
        }
        @include mq($from: desktop) {
            margin: 0 20px 45px 0;
            
            &:nth-child(3n) {
                margin: 0 0 45px 0;
            }
        }
        @include mq($from: wide){
            margin: 0 30px 45px 0;
        }

        &--50 {
            @include mq($from: tablet) {
                width: calc(50% - 51px);
            }
        }

        &--33 {
            @include mq($from: desktop) {
                max-width: 300px;
            }

        }
    }

    &-preview {

        &__content {
            padding: 10px 17px 19px;
        }

        &__img {
            min-height: 124px;
            height: 100%;
            background-color: $color-light-blue;
            display: block;

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }
        }

        &__img--link {
            display: block;
            width: 50%;
        }
        a {
            text-decoration: none;
            display: block;
        }

        &__title {
            font-size: 20px;
            font-weight: 400;
            margin: 8px 0 6px;
            line-height: 1.3;
            font-family: $font-oswald;

            a {
                color: $color-blue;
                &:hover {
                    color: $color-black;
                    text-decoration: underline;
                }
            }
            @include mq($from: desktop) {
               font-size: 22px;

               a {
                color: $color-black;
                    &:hover {
                        color: $color-blue;
                        text-decoration: underline;
                    }
               }
            }
        }

        &__link {
            font-size: 18px;
            font-weight: 600;
            color: $color-blue;
            text-decoration: underline;
            &:hover {
                color: $color-black;
            }
            @include mq($from: desktop){
                color: $color-black;
                &:hover {
                    color: $color-blue;
                    text-decoration: underline;
                }
            }
        }

        &__text {
            margin-top: 0;
            margin-bottom: 18px;
        }
    }
}