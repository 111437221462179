.c-accessibility {
    @include mq($from: desktop) {
        padding-top: 20px;
    }
    &__wrapper {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mq($from: tablet) {
            flex-wrap: initial;
            &:first-child {
                margin-right: 10px;
            }
        }
    }
    &-info {
        max-width: 370px;
        width: 100%;
        padding: 30px 20px 15px 30px;
        border: 1px solid $color-blue-green;
        margin-bottom: 20px;
        @include mq($from: tablet) {
            &:first-child {
                margin-right: 10px;
            }
            margin-bottom: 0;
        }
        @include mq($from: desktop) {
            padding: 30px 30px 15px 45px;
            
        }
        &__heading {
            color: $color-blue;
            font-size: 25px;
            display: flex;
            align-items: center;
        }
        &__title {
            font-size: 24px;
            font-family: $font-oswald;
            font-weight: 400;
            margin: 0 0 0 16px;
            @include mq($from: desktop) {
                font-size: 28px;
                margin-left: 13px;
            }
        }
        &__description {
            padding-left: 38px;
            margin-top: 3px;
            a {
                color: $color-blue;
                font-weight: 600;
            }
        }
    }
}

.c-accessibility-form {
    .c-quote-product {
        margin-top: 0;
    }
    
    &__wrapper {
        background-color: $color-light-blue;
        padding: 40px 0 56px;
    }

    &__title {
        color: $color-blue;
        font-size: 28px;
        text-align: center;
        font-family: $font-oswald;
        font-weight: 500;
        margin-top: 0;

        @include mq($from: desktop) {
            font-size: 36px;
        }
    }
    &__description {
        strong {
            font-weight: 600;
        }
        a {
            color: $color-blue;
        }
    }
    &__form {
        background-color: $color-white;
        padding: 30px 20px 55px;
        @include mq($from: desktop) {
            padding: 30px 33px 55px;
        }
        .c-quote-product__controls {
            justify-content: flex-start;
        }
    }
}