.c-steps-list {

    .s-cms-content + & {
        margin-top: 30px;        

        @include mq($from: desktop) {
            margin-top: 48px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 35px;
        .c-steps-list--big &{
            gap: 20px;
        }
        @include mq($from: desktop) {
            gap: 80px;
            .c-steps-list--big & {
                gap: 60px;
            }
        }
        @include mq($from: wide) {
            gap: 90px;
            .c-steps-list--big &{
                gap: 60px;
            }
        }
    }

    &__item {
        width: 100%;
        position: relative;
        &:last-child{
            margin: 0;
        }

        @include mq($from: tablet) {
            width: calc(33.33% - 30px);
            margin-bottom: 0;
        }

        @include mq($from: desktop) {
            width: calc(33.33% - 60px);
            .c-steps-list--big &{
                width: calc(33.33% - 40px);
            }
        }
    }

    &__img {
        width: 70px;
        height: auto;
        display: block;
        .c-steps-list--big & {
            width: 48px;
            position: absolute;
            left: 0;
            top: 17px;
        }
        @include mq($from: desktop) {
            width: 80px;
            .c-steps-list--big & {
                width: 100px;
                position: relative;
                top: 0;
            }
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 29px;
        .c-steps-list--big & {
            margin-top: 16px;
        }
    }

    &__step {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: $color-sky-blue;
        color: $color-blue;
        font-size: 36px;
        font-weight: 600;
        display: flex;
        line-height: 1;
        justify-content: center;
        align-items: center;

        @include mq($from: desktop) {
            width: 52px;
            height: 52px;
            font-size: 42px;
        }
        + .c-steps-list__text {
            width: calc(100% - 60px);
            padding-left: 8px;
        }
    }

    &__text {
       
        p {
            margin-top: 0;
            &:last-child {
                margin-bottom: 0;
            }
            .c-steps-list--big & {
                margin-top: 16px;
            }
        }
        a {
            color: $color-blue;
        }
        strong {
            color: $color-blue;
            font-weight: 600;
        }

        h3 {
            font-weight: 700;
            font-size: 16px;
            margin: 0;
            
            &.blue {
                color: $color-blue;
            }
            .c-steps-list--big & {
                font-size: clamp(1.125rem,5vw,1.375rem);
                padding-left: 64px;
                height: 48px;
                display: flex;
                align-items: center;
                @include mq($from: desktop) {
                    padding: 0;
                    height: auto;
                }
            }

            span {
                display: inline-block;
                font-size: 9px;
                transform: translate(-0px, -10px);
            }
        }
    }
}