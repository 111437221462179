.c-advice {
    width: 100%;
    padding: 24px 15px;
    border: 2px solid $color-blue-green;
    border-radius: 8px;
    margin-top: 30px;
    @include mq($from: desktop) {
        padding: 24px 40px;
    }

    strong {
        color: $color-blue;
    }
    a {
        color: $color-blue;
        font-weight: 600;
    }
    p {
        margin: 0;
        + p {
            margin-bottom: 20px;
        }
    }
    ul {
        li {
            margin-bottom: 5px;
            &::marker {
                color: $color-green;
            }
        }
    }
    + .s-cms-content {
        margin-top: 25px;
    }
}