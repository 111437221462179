///* ========================================================================
//   #MEDIA BLOCK
//   ======================================================================== */

.c-media-block {
    @include mq($from: desktop) {
        padding-left: 380px;
        position: relative;
        min-height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--right {
            padding-right: 380px;
            padding-left: 0;
        }
        
    }
    &--border {
        border: 1px solid #8AB2CB;
        border-radius: 16px;
        padding: 40px 20px;
        @include mq($from: desktop) {
            padding: 40px 30px 40px 390px;
        }
    }

    &__title {
        span {
            color: $color-blue;
        }
        + .c-media-block__img {
            margin: 34px auto 0;
            @include mq($from: desktop) {

                margin-top: 0;
            }
        }

    }
    p{
        a {
            color: $color-blue;
            font-weight: 600;
        }
    }

    .c-section__title {
        font-family: $font-oswald-medium;
        font-weight: normal;
        text-align: center ;
        @include mq($from: desktop) {
            text-align: left !important;
        }
        &.c-section__title--left-mb {
            text-align: left !important;
        }
    }
    &__subtitle {
        margin: 24px 0 0;
        font-size: clamp(1.125rem,4vw,1.375rem);
        text-align: left;
        margin-bottom: 20px;
        @include mq($from: desktop){
            margin-bottom: 0;
        }
    }

    &__img {
        width: 240px;
        height: 240px;
        margin: 0 auto;
        //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        @include mq($from: desktop) {
            width: 320px;
            height: 320px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            //margin-top: 0;
        }

        .c-media-block--right & {
            right: 0;
            left: auto;
        } 
        .c-media-block--border &{
            width: 100%;
            height: 140px;
            margin: 20px 0 0;
            @include mq($from: desktop){
                width: 300px;
                height: 230px;
                left: 30px;
                margin: 0 auto;
            }
        }

        &--circle {
            border-radius: 50%;
            overflow: hidden;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
    &__content {
        margin-top: 20px;
        @include mq($from: desktop) {
            margin-top: 24px;
        }
        &-img {
            width: 237px;
            margin-top: 36px;
            @include mq($until: desktop) {
                margin: 30px auto auto;
                width: 203px;
            }
            picture {
                display: block;
            }
            img {
                width: 100%;
            }
        }
    }

    &__button {
        margin-top: 24px;
        text-align: center;
        @include mq($from: desktop) {
            text-align: left;
        }
    }

    .s-cms-content {
        
        @include mq($until: desktop) {
            p {
                text-align: center;
               
            }
            a:not([class]) {
                white-space: nowrap;
            }
        }
        @include mq($from: desktop) {
            text-align: left;
        }

        +.c-media-block__list {
            margin-top: 15px;
        }
        &--left-mb {
            
            @include mq($until: desktop) {
                p {
                    text-align: left !important;
                   
                }
                a {
                    white-space: nowrap;
                }
            }
        }
    }

    &__social-media {
        ul {
            display: flex;
            @include mq($until: desktop) {
                justify-content: center;
            }
            li {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    width: 38.9px;
                    height: 38.9px;
                    border-radius: 50%;
                    background-color: $color-blue-green;
                    color: $color-white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;

                    @include mq($from: desktop) {
                        width: 42px;
                        height: 42px;
                        font-size: 22px;
                    }
                }
            }
        }
    }

    &__list {
        +.s-cms-content {
            margin-top: 20px;
        }
        a {
            color: $color-blue;
            font-weight: 600;
        }

        &--check {
            padding-top: 10px;
            list-style: none;

            @include mq($until: 500px) {
                max-width: 285px;
                margin: 0 auto;
            }
            &.is-light {
                margin-left: 0;
                li {
                    font-size: 16px;
                    font-weight: normal;
                    margin-bottom: 20px;
                    padding-left: 25px;
                }
            }

            li {
                width: 100%;
                background-image: url('../images/components/list/icon-check.svg');
                background-repeat: no-repeat;
                background-position: left 6px;
                background-size: 16px;
                padding-left: 28px;
                margin-bottom: 30px;
                font-weight: 600;
                font-size: 20px;
                

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &--number {
            margin-top: 24px;
            padding-left: 20px;
            > li {
                list-style: decimal;
                margin-bottom: 16px;
                &::marker {
                    color: $color-blue;
                    font-weight: 700;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}