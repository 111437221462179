.c-modal-quote {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: $color-light-blue;
    z-index: 10;
    display: flex;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30px);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility ease-in-out 0.3s;

    &.is-open {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility ease-in-out;
    }

    .o-wrapper {
        width: 100%;
        min-width: 100%;
    }

    &__title {
        font-size: 18px;
        text-align: center;
        font-weight: 700;
        @include mq($from: desktop) {
            font-size: 20px;
        }
    }

    &__form {
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 35px 26px;
        max-width: 715px;
        margin: 24px auto 0;
        @include mq($from: desktop) {
            padding: 35px 54px;
            max-width: 800px;
            margin-top: 56px;
        }
    }

    &__product-link {
        text-align: center;
        margin-top: 30px;
        font-weight: 600;

        a {
            color: $color-blue;
        }
    }

    &__close {
        position: absolute;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        color: $color-black;
        top: 30px;
        right: 24px;
        @include mq($from: desktop) {
            top: 45px;
            right: 45px;
        }
    }
}