/*/ ========================================================================
//   # wallpapers
//   ======================================================================== */
.c-videos {
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 100%;
        background-color: $color-white;
        box-shadow: 0px 4px 12px rgba(65, 70, 72, 0.12);
        margin: 0 15px 24.5px;

        @include mq($from: desktop) {
            margin: 0 12.5px 25px;
        }

        &--50 {
            @include mq($from: tablet) {
                width: calc(50% - 51px);
            }
        }

        &--33 {
            @include mq($from: desktop) {
                width: calc(33.33% - 26px);
            }
        }
    }

    &-preview {
        @include mq($until: tablet) {
            .o-crop--fill>.o-crop__content {
                width: 100%;
            }
        }

        &__img {
            min-height: 154px;
            height: 100%;
            @include mq($from: desktop) {
                min-height: 184px;
            }
        }

        &__img--link {
            display: block;
            width: 50%;
        }
        a {
            text-decoration: none;
            display: block;
            &:hover {
                text-decoration: underline;
            }
        }

        &__content {
            padding: 10px 10px 15px 14px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;

            @include mq($from: desktop) {
                padding: 11px 23px 33px 16px;
            }
            
            h3 {
                font-size: 16px;
                margin: 0 0 0 8px;
                a {
                    color: $color-blue;
                    font-weight: 700;
                    //display: inline-flex;
                }
            }
            .o-icon {
                font-size: 20px;
                padding-left: 5px;
                color: $color-blue;
                padding-top: 3px;
            }
        }
    }
}