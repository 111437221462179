.c-faqs-widget{
    list-style: none;
    max-width: 1200px;
    width: 100%;
    margin: 40px auto;
    padding: 0 20px;
    &__content {
        border: 1px solid $color-silver;
        padding: 30px 20px;
        border-radius: 8px;
    }

    &__header {
        display: flex;
        align-items: flex-start;
    }

    &__title {
        font-family: $font-oswald-medium;
        font-weight: 500;
        font-size: 24px;
        margin: 0 0 0 16px;
        color: $color-blue;
        line-height: 1.2;

        @include mq($from: desktop) {
            font-size: 38px;
        }
    }
	
	&__item {
		position: relative;
        padding: 20px 0;
        border-bottom: 1px solid $color-silver;
        @include mq($from: desktop){
            padding: 25px 18px;
        }
        &:first-child{
            border-top: 1px solid $color-silver;
            margin-top: 30px;
        }
    }
	
    &__question {
        margin: 0;
        .o-icon {
            color: $color-blue-green;
            font-size: 22px;
            margin-left: auto;
            margin-right: 0;
            transition: 0.4s ease-in-out;
            @include mq($from: desktop) {
                font-size: 25px;
            }
        }

        .is-active .o-icon {
            transform: rotate(180deg);
        }

        button {
            background-color: transparent;
            text-align: left;
            padding-bottom: 3px;
            display: flex;
            position: relative;
            font-size: 16px;
            font-weight: 600;
            color: $color-blue;
            width: 100%;
            @include mq($from: desktop) {
                line-height: 1.5;
                font-size: 18px;
            }
        }

        &:hover {
            color: $color-black;
        }

        &.active {
            color: $color-blue;
            .o-icon {
                transform: rotate(180deg);
                color: $color-black;
            }
        }
    }

    &__answer{
        padding: 0 25px 0;
        display: none;
        
        p {

            a,strong {
                color: $color-blue;
                font-weight: 600;
            }
            a {
                word-break: break-word;
            }
        }
    }
    &__controls {
        padding-top: 25px;
        .c-button {
            color: $color-blue-green ;
        }
    }
}
	