.c-resources {
    padding-top: 40px;

    + .c-section {
        margin-bottom: 0px;
    }
    &__container {
        margin-bottom: 50px;
    }
    &__content {
        padding: 30px 0 0;
        margin-top: 15px;
        border-top: 1px solid $color-blue-green;
        border-bottom: 1px solid $color-blue-green;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include mq($from: tablet) {
            justify-content: flex-start;
        }
        @include mq($from: desktop) {
            padding: 35px 0;
        }
        
    }

    &__item {
        width: 301px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 60px 8px 8px 60px;
        margin-bottom: 30px;
        overflow: hidden;
        @include mq($from: 600px) {
            margin: 0 8px 30px;
        }
        @include mq($from: desktop) {
            margin: 0 8px 0;
        }
        
    }

    &__img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        margin-right: 15px;
        
    }
    &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: 600;
        color: $color-black;
        padding-right: 5px;

        &:hover {
            color: $color-blue;
            background-color: $color-light-blue;
        }
    }
}