.c-regional-page {

    .c-section__title {
        text-align: left;
        font-family: $font-oswald-medium;
        + .c-section__content {
            margin-top: 16px;
        }
        
    }
    .c-media-block  {
        &.c-section__title {
            text-align: center;
            @include mq($from: desktop) {
                text-align: left;
            }
        }
        
    }
    .c-sections__list-column {
        ul {
            margin-left: 30px;
            li {
                padding-left: 0;
            }
        }
    }
    .s-cms-content {
        text-align: left;
        strong {
            font-weight: 600;
            color: $color-black;
        }
        p {
            text-align: left;
        }
        
    }
    
    &__content {
        margin-top: 16px;
        .s-cms-content  {
            p {
                text-align: left;
            }
        }
    }
    
    @include mq($from: desktop) {
        .c-office-locator-form__item {
            width: 45.75%;
        }
        .c-office-locator-form__input {
            font-size: 18px;
        }
    }
    
    &__list {
        margin-top: 20px;

        &--check {
            list-style: none;
            padding-left: 15px;

            > li {
                background-image: url('../images/components/list/icon-check-blue.svg');
                background-repeat: no-repeat;
                background-position: 0 3px;
                background-size: 16px;
                padding-left: 28px !important;
                margin-top: 20px;
                strong {
                    color: $color-blue;
                    font-weight: 600;
                    a {
                        color: inherit;
                    }
                }

            }
            ul {
                list-style: disc;
                margin: 20px 0;
                @include mq($from: desktop) {
                    margin: 40px 0;
                }
                li {
                    margin-top: 15px;
                    &::marker {
                        color: $color-green;
                        font-size: 21px;
                    }
                    strong {
                        font-weight: 600;
                    }
                   
                }
            }
        }
        p {
            strong {
                color: $color-blue;
                font-weight: 600;
                a {
                    color: inherit;
                }
            }
        }
        a {
            color: $color-blue;
            font-weight: 600;
            
        }

        &--number {
            list-style: decimal;

            li {
                margin-bottom: 16px;
                &::marker, strong , a {
                    color: $color-blue;
                    font-weight: 600;
                }
                p {
                    margin-bottom: 0;
                }

            }
            + .s-cms-content {
                padding: 25px 0 15px;
            }
        }
    }
    &__information-booklet {
        margin: 29px 0;
        
        &-table {
            border: 1px solid $color-silver;
            &-medium {
                max-width: 643px;
                width: 100%;
                .c-regional-page__information-booklet--sub-item {
                    &:first-child, &:nth-of-type(2){
                        width: 19%;
                        @include mq($from: desktop) {
                            width: 17%;
                        }
                    }
                    &:last-child {
                        width: 81%;
                        @include mq($from: desktop) {
                            width: 83%;
                        }
                    }
                }
                .c-regional-page__information-booklet--item {
                    display: flex;
                    
                }
            }
            &-small {
                max-width: 454px;
                width: 100%;
                .c-regional-page__information-booklet--item {
                    &:nth-child(odd) {
                        background-color: $color-light-blue;
                    }
                }
            }
            
        }
        &--item {
            //border-top: 1px solid $color-silver;
            border-bottom: 1px solid $color-silver;
            padding: 11px 13px ;
            &:last-child {
                border-bottom: 0;
            }
            @include mq($from: desktop) {
                padding: 9.5px 20px 9.5px 40px;
            }
        }
    }
    &__controls {
        margin-top: 30px;
        padding: 0 20px;
        .c-button {
            font-size: 18px;
            @include mq($from: mobile) {
                font-size: 21px;
            }
            @include mq($from: desktop) {
                font-size: 24px;
            }
        }
    }

    &-city {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 360px;
        margin-top: 24px;
        @include mq($from: 600px) {
            max-width: 700px;
            margin-top: 36px;
        }
        @include mq($from: desktop) {
            max-width: 100%;
            justify-content: flex-start;
        }
        &__link {
            width: 150px;
            display: block;
            background-color: $color-white;
            box-shadow: 0px 4px 12px rgba(65, 70, 72, 0.12);
            border-radius: 2px;
            text-decoration: none;
            color: $color-black;
            margin-bottom: 20px;
            &:hover {
                color: $color-blue;
            }
            @include mq($from: 575px) {
                width: 300px;
                margin-right: 22px;
                margin-bottom: 25px;
            }
            @include mq($from: desktop) {
                margin-right: 28px;
                &:nth-child(3n+0) {
                    margin-right: 0;
                }
            }
            &.city-hidden {
                display: none;
            }
        }
        &__img {
            width: 100%;
            height: 86px;
            display: block;
            position: relative;
            @include mq($from: 575px) {
                height: 172px
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            
        }
        &__title {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            @include mq($from: 575px) {
                padding: 12px 16px;
            }
            h3 {
                color: inherit;
                font-size: 16px;
                font-family: $font-oswald-medium;
                font-weight: 500;
                margin: 0;
                @include mq($from: 575px) {
                    font-size: 24px;
                }
            }
            
        }
        
        &__view-more {
            margin-top: 10px;
            @include mq($from: desktop) {
                margin-top: 20px;
            }
            .c-button {
                color: $color-blue;
                font-weight: 700;
                height: 45px;
                @include mq($from: desktop) {
                    height: 45px;
                }
                &:hover {
                    color: $color-black;
                }
            }
        }
    }
}
.c-regional-page-table {

    &__scroll {
        overflow-x: auto;
        margin-bottom: 20px;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            background-color: $color-grey-1;
        }

        &::-webkit-scrollbar:horizontal {
            height: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-orange;
        }
    }
    &__table {
       
        &--large {
            border-collapse: collapse;
            border: 1px solid $color-silver;
            border-spacing: 0;
            max-width: 753px;
            width: 100%;
            //margin-top: 20px;
            tr {
                &:nth-child(even) {
                    background-color: $color-light-blue;
                    border: 1px solid $color-silver;
                }
                
                td {
                    padding: 12px 15px;
                    font-size: 14px;
                    text-align: center;
                    @include mq($from: mobile) {
                        padding: 12px 18px;
                        font-size: 16px;
                    }
                    @include mq($from: desktop) {
                        padding: 12px 38px;
                    }
                    a {
                        color: $color-blue;
                        display: flex;
                        text-align: left;
                    }
                    span {
                        vertical-align: top;
                        transform: translate(8px, 7px);
                        font-size: 9px;
                    }
                }
                td:first-child, th:first-child{
                    text-align: left;
                }
                th {
                    color: $color-blue;
                    font-weight: 600;
                    font-size: 16px;
                    padding: 12px 15px;
                    @include mq($from: mobile) {
                        padding: 12px 18px;
                        font-size: 18px;
                    }
                    @include mq($from: desktop) {
                        padding: 12px 38px;
                    }
                    a {
                        color: $color-blue;

                    }
                }
            }
            &.table-text--center {
                text-align: center;
            }
        }
        &--small {
            border-spacing: 1px;
            margin-top: 26px;
    
            tr {
                display: flex;
                @include mq($until: 800px) {
                    //display: flex;
                }
    
                &:nth-child(odd) {
                    background-color: $color-light-blue;
                }
    
                a {
                    color: $color-black;
    
                    &:hover,&:active {
                        color: $color-blue;
                    }
                }
            }
            td,th {
                min-width: 35%;
                width: 100%;
                text-align: left;
                @include mq($from: tablet) {
                    min-width: 30%;
                    
                }
                @include mq($from: desktop) {
                    min-width: 25%;
                    
                }
            }

            th {
                font-weight: 600;
                padding:15px 5px;
                display: table-cell;
                vertical-align: middle;
                padding: 15px 10px;
                height: auto;
                background-color: $color-light-blue;
                display: flex;
                align-items: center;

                // @include mq($until: desktop) {
                //     display: table-cell;
                //     vertical-align: middle;
                //     border-right: 1px solid white;
                //     padding: 15px 10px;
                //     height: auto;
                    
                // }
                @include mq($from: desktop){
                    height: 75px;
                    text-align: left;
                }
            }

            td {
                padding: 22px 10px;
                vertical-align: baseline;
                
            }
            &.table-text--center {
                text-align: center;
            }
        }

        &--full {
            max-width: none;
            &.table-text--center {
                text-align: center;
            }
        }
        
    }

    &__text {
        p {
            font-size: 14px;
        }
    }
}

.c-regional-page-hero {

    background-color: $color-light-blue;
    @include mq($from: desktop) {
        position: relative;
        background-color: transparent;
    }

    &__img {
        height: 156px;
        @include mq($from: tablet) {
            height: 240px;
        }
        @include mq($from: desktop) {
            height: 400px;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        padding: 30px 0;
        @include mq($from: desktop) {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            z-index: 5;
            padding: 20px 0;
            transform: translateY(-50%);
        }
    }

    &__title {
        text-align: center;
        font-size: 28px;
        font-family: $font-oswald-medium;
        font-weight: normal;
        color: $color-blue;
        line-height: 1.08;
        margin: 0;
        span {
            color: $color-black;
        }
        @include mq($from: tablet) {
            font-size: 32px;
            br {
                display: none;
            }
        }
        @include mq($from: desktop) {
            text-align: left;
            font-size: 44px;
        }
        @include mq($from: wide) {
            font-size: 52px;
        }
    }

    &__text {
        margin-top: 8px;
        font-family: $font-oswald;
        font-size: 26px;
        text-align: center;
        line-height: 1;
        @include mq($from: desktop) {
            text-align: left;
            font-size: 31px;
        }
        @include mq($from: wide) {
            font-size: 36px;
        }

        p {
            margin: 0 0 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.c-regional-page-quote {
    margin-top: 29px;

    &__wrapper {
        max-width: 360px;
        width: 100%;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
        background-color: $color-white;
        border-radius: 8px;
        padding: 15px 19px 10px;
        @include mq($until: desktop) {
            border: 2px solid $color-silver;
            margin: auto;
        }
        @include mq($from: tablet) {
            padding: 23px 35px 10px;
            max-width: 600px;
            html[lang=es-US] & {
                max-width: 640px;
            }
        }
    }
    &__form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 29px;
        html[lang=es-US] & {
            justify-content: center;
            @include mq($from: desktop) {
                justify-content: space-between;
            }
        }

        &-title {
            font-weight: 600;
            margin-bottom: 22px;
            width: 100%;
            color: $color-black;
            margin-top: 0;
            margin-bottom: 21px;
            font-size: 16px;
            @include mq($from: tablet) {
                font-size: 20px;
            }
        }
        &-content {
            border: 0;
            display: flex;
            justify-content: space-between;
            padding: 0;
            
        }
        label.form__error {
            background-color: $color-orange;
            color: #fff;
            font-size: 12px;
            padding: 6px 9px;
            display: block;
            margin-top: 7px;
            border-radius: 6px;
            position: relative;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            
    
            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                top: -5px;
                left: 20px;
                width: 1px;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 5px solid $color-orange;
            }
        }
        label.c-regional-page-quote__form-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            width: 140px;
            border: 2px solid $color-orange;
            border-radius: 10px;
            height: 50px;
            html[lang=es-US] & {
                width: 200px;
                text-align: center;
                margin-bottom: 20px;
            }
            @include mq($from: 375px) {
                width: 155px
            }
            @include mq($from: tablet) {
                width: 180px;
                html[lang=es-US] & {
                    width: 180px;
                    text-align: left;
                    margin-bottom: 0;
                }
                
            }
        }
        &-item {
            position: relative;
            //overflow: hidden;
            width: 115px;

            @include mq($from: mobile) {
                width: 123px;
                html[lang=es-US] & {
                    width: 200px;
                }
            }
            @include mq($from: tablet) {
                width: 130px;
                html[lang=es-US] & {
                    width: 165px;
                }
               
            }
        }
        
        input[type="radio"] {
            display: none;
        }
        &-input {
            border: 1px solid $color-silver;
            border-radius: 10px;
            height: 50px;
            padding: 5px 14px;
            font-size: 20px;
            width: 100%;
            @include mq($from: mobile) {
                padding: 5px 17px;
                html[lang=es-US] & {
                    padding: 5px 15px;
                }
            }

            &.form__error {
                border: 1px solid $color-orange;
            }
            &::placeholder {
                color: #BDBDBD;
                font-weight: 600;
            }
        }
        &-icon-check {
            display: block;
            color: $color-green;
            font-size: 20px;
            @include mq($from: desktop){
                margin: 4px 0 4px 10px;
            }
        }
    
        &-icon {
            font-size: 36px;
            color: $color-blue;
            @include mq($from: desktop) {
                font-size: 36px;
            }
        }
        &-text {
            color: $color-orange;
            font-size: 20px;
        }
        &-controls {
            max-width: 200px;
            width: 100%;
            .c-button {
                width: 100%;
                @include mq($from: desktop) {
                    font-size: 24px;
                }
            }
            @include mq($until: tablet){
                margin: 20px auto 0;
            }
        }    
    }
    
    &__link {
        display: block;
        text-decoration: none;
        color: $color-black;
        font-size: 14px;
        padding-bottom: 12px;
        transition: color 0.2s ease-in-out;
        @include mq($from: tablet) {
            font-size: 18px;
            padding-bottom: 15px;
        }

        &-button {
            background-color: $color-white;
            font-weight: 600;
            color: $color-black;

        }

        &:hover, &.is-active {
           color: $color-blue;
           transition: color 0.2s ease-in-out;
        }
    }
    
}


