.c-ok-list {

    .s-cms-content + & {
        margin-top: 30px;        

        @include mq($from: desktop) {
            margin-top: 48px;
        }
    }

    &--with-media {
        @include mq($from: desktop) {
            display: flex;
            align-items: center;
        }
    }

    &__list {
        list-style-image: url('../images/components/list/icon-ok.svg');
        padding-left: 30px;
        margin-bottom: -16px;
        @include mq($from: desktop) {
            margin-bottom: -30px;

            .c-ok-list:not(.c-ok-list--with-media):not(.c-ok-list--full-width) & {
                column-count: 2;
                column-gap: 114px;
            }

            .c-ok-list--with-media & {
                width: 500px;
                padding-right: 66px;
            }
        }
    }

    &__item {
        padding-left: 5px;
        padding-bottom: 16px;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        @include mq($from: desktop) {
            padding-bottom: 30px;
        }
    }

    &__title {
        color: $color-black;
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 8px;

        @include mq($from: desktop) {
            font-weight: 600;
            margin: 0 0 16px;
        }

        &-link {
            color: inherit;
            
            &:hover,&:active {
                color: $color-blue;
            }
        }
    }

    &__text {
        font-size: 16px;
        line-height: 1.37;

        p {
            margin: 0 0 20px 0;
            
            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            text-decoration: underline;
            color: $color-blue;
        }

        strong {
            font-weight: 600;
            color: $color-blue;
        }
    }

    &__media {
        @include mq($from: desktop) {
            width: calc(100% - 500px);
            margin: 0;
        }
    }

    &__img, &__video {
        display: block;
        max-width: 460px;
        margin: 40px auto 0;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        @include mq($from: desktop) {
            margin-top: 0;
        }

        img, video {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}