///* ========================================================================
//   #SEARCH BAR
//   ======================================================================== */

.c-search-bar {
    position: relative;

    &__input {
        display: block;
        width: 100%;
        height: 50px;
        border: 1px solid $color-silver;
        padding: 5px 48px 5px 24px;
        border-radius: 60px;
        @include placeholder($color-black);
    }

    &__submit {
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        line-height: 0.8;
        background-color: transparent;

        &:disabled {
            opacity: 0.9;
            cursor: wait;
        }
    }
}