.c-about {
    .c-media-block {
        margin: 55px 0 ;
    }
    &-category{
        &__img {
            width: 100%;
            height: 160px;
            margin-bottom: 30px;
            display: block;
            @include mq($from: desktop) {
                height: 260px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__description {
            strong {
                @include mq($from: desktop) {
                    font-size: 19px;
                }
            }
        }
        &__list {
            column-count: 2;
            column-gap: 30px;
            @include mq($from: desktop) {
                column-count: 3;
                column-gap: 80px;
                margin-top: 20px;
            }
            &-item {
                display: flex;
                align-items: center;
                margin-bottom: 30px;
            }
            &-icon {
                color: $color-blue;
                font-size: 30px;
                margin-right: 15px;
                @include mq($from: desktop) {
                    margin-right: 20px;
                }
            }
            &-link {
                text-decoration: none;
                color: $color-black;
                &:hover {
                    color: $color-blue;
                    text-decoration: underline;
                }

                &--bold {
                    font-weight: 600;
                }
            }
        }
    }
    &-team {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mq($from: 450px) {
            justify-content: center;
        }

        &__item {
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 152px;
            margin-top: 20px;
            &:first-child {
                margin-left: 0;
            }
            @include mq($from: 450px) {
                margin-top: 56px;
                margin-left: 50px; 
            }
        }
        &__img {
            background-color: $color-light-blue;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        &__name {
            font-size: 18px;
            margin-top: 14px;
        }
        &__position {
            font-size: 15px;
        }
    }
    &-testimonie {
        &__video {
        max-width: 460px;
        margin: 30px auto 0;
            .c-blog-section & {
                max-width: 720px;
                border-radius: 8px;
                overflow: hidden;
            }
            
            video {
                display: block;
                width: 100%;
                height: auto;
                position: relative;
                @include mq($from: desktop) {
                    height: 100%;
                    object-fit: cover;
                }
            }
            &-iframe {
                height:0px;
                width:100%;
                padding-top:56.25%;
                position:relative;
                iframe {
                    position:absolute;
                    height:100%;
                    width:100%;
                    top:0px;
                    left:0px;
                }
            }
            
            &-links {
                max-width: 460px;
                padding-top: 10px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                .c-blog-section & {
                    max-width: 720px;
                }
                a {
                    color: $color-blue;
                    font-weight: 600;
                    font-size: 14px;
                    @include mq($from: desktop) {
                        .c-blog-section & {
                            font-size: 16px;
                        }
                    }
                    &:hover {
                        opacity: .8;
                    }
                }
            }
        }
        &__controls{
            margin-top: 30px;
        }
    }
    &-companies {
        padding: 0 2px;

        &__map {
            max-width: 600px;
            width: 100%;
            text-align: center;
            padding: 20px 0 40px;
            margin: auto;

            &-img {
                width: 100%;
            }
        }
    }
}
