.c-car-models {
    margin-top: 30px;
    margin-bottom: 30px;
    @include mq($from: desktop) {
        margin-top: 80px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 30px 0;
        @include mq($from: desktop) {
            gap: 40px 0;
        }
    }

    &__item {
        @include mq($from: desktop) {
            display: flex;
            gap: 40px;
        }
    }

    &__picture {
        display: none;
        min-width: 180px;
        height: 180px;
        border: 1px solid $color-silver;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        @include mq($from: desktop) {
            display: flex;
        }
    }

    &__logo {
        display: block;
        width: 120px;
        height: auto;
    }
}

.c-car-model {
    @include mq($from: desktop) {
        width: 100%;
    }
    &__list {
        margin-top: 22px;
        padding-top: 22px;
        border-top: 1px solid #8AB2CB;
        display: grid;
        gap: 24px 0;
        grid-template-columns: 1fr;
        @include mq($from: desktop) {
            grid-template-columns: 1fr 1fr;
            gap: 24px 28px;
        }
    }

    &__item {
        padding-left: 28px;
        position: relative;

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: $color-green;
            left: 0;
            top: 13px;
            border-radius: 50%;
        }
    }

    &__link {
        color: $color-blue;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.78;
        text-decoration: underline;
        @include mq($from: desktop) {
            font-size: 20px;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    &__title {
        color: $color-black;
        font-size: 24px;
        font-family: $font-oswald;
        font-weight: 400;
        line-height: 1.33;
        margin: 0;
        @include mq($from: desktop) {
            font-size: 32px;
        }
    }   
}