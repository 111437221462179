.c-ds-banner {
    position: relative;
    margin-bottom: 10px;

    @include mq($from: desktop) {
        margin-bottom: 29px;
        &.c-ds-banner--h400d {
            margin-bottom: 0;
        }
    }

    &__content {
        position: absolute;
        max-width: 1200px;
        width: 100%;
        margin: auto;
        left: 0;
        right: 0;
        padding: 0 20px;
        z-index: 1;
        top: 42px;
        @include mq($from: desktop) {
            top: 125px;
        }
    }
    &-img {
        background-color: $color-light-blue;
    }
    &__img {
        height: 425px;

        @include mq($from: desktop) {
            height: 540px;
            .c-ds-banner--h400d &{
                height: 400px;
            }
        }
    }

    &__title {
        padding-bottom: 15px;
        font-size: 35px;
        font-family: $font-oswald-medium;
        font-weight: 500;
        line-height: 1;
        color: $color-blue;
        max-width: 320px;
        margin: auto;
        text-align: center;
        .c-ds-banner--h400d &{
            max-width: 300px;
        }

        @include mq($from: tablet) {
            font-size: 52px;
            max-width: 453px;
            margin-left: 100px;
            text-align: left;
            .c-ds-banner--h400d &{
                max-width: 453px;
            }

            html[lang=es-US] & {
                max-width: 480px;
            }
        }

        span {
            color: $color-green;
        }
    }
}