.c-social-media-rating {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 39px 0 0;

    @include mq($from: desktop) {
        justify-content: end;
    }
    
    &__item {
        padding: 0 20px;
        text-align: center;
        margin-bottom: 40px;
        
        @include mq($from: desktop) {
            padding: 0 25px 0 0;
            margin-bottom: 25px;
        }
    }
}