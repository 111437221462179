/*/ ========================================================================
//   #FAQS
//   ======================================================================== */
.c-faqs {
    .c-section__content {
        @include mq($from: tablet) {
            margin-top: 80px;
        }
    }
    &__container {
        display: flex;
        flex-direction: column-reverse;
        @include mq($from: tablet) {
            margin-top: 80px;
            flex-direction: row;
        }
    }
    &-questions {
        &-name {
            color: $color-blue;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 20px;
            @include mq($from: desktop) {
                font-size: 22px;
            }
        }
        &-list {
            @include mq($from: tablet) {
                padding-left: 70px;
            }
            @include mq($from: desktop) {
                padding-left: 102px;
            }
            &__item {
                outline: none!important;
            }
            &__title {
                font-size: 18px;
                margin-bottom: 16px;
                font-weight: 600;
                &.is-active {
                    color: $color-blue;
                }
                @include mq($from: desktop) {
                    font-size: 22px;
                }
            }
            &__description {
                font-size: 16px;
                margin-bottom: 30px;
                a {
                    color: $color-black;
                    &:hover {
                        color: $color-blue;
                    }
                }
                strong {
                    a {
                        color: $color-blue;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &__subitem {
                padding-left: 25px;
                &-text {
                    &:first-child {
                        margin-top: 0;
                    }
                    strong {
                        font-weight: 600;
                    }
                }
                &-strong  {
                    font-weight: 600;
                }
            }
        }
    }
}

.c-faqs-aside {
    >span {
        @include mq($from: tablet) {
            width: 260px !important;
            display: block !important;
        }
        @include mq($from: desktop) {
            width: 300px !important;
            display: block !important;
        }
    }
    &-list {
        display: none;
        @include mq($from: tablet) {
            width: 260px !important;
            display: block;
        }
        @include mq($from: desktop) {
            width: 300px !important;
        }
        &__item {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 34px;
        }
        &__link {
            color: $color-black;
            text-decoration: none;
            &:hover,
            &.is-active {
                color: $color-blue;
            }
        }
    }
    &-controls {
        color: $color-blue;
        font-size: 20px;
        font-weight: 700;
        padding-top: 30px;
        border-top: 1px solid rgba(47, 128, 237, 0.2);
        &__title {
            margin-bottom: 20px;
        }
    }
}

.c-faqs-page {
    .c-faqs {
        .c-section__content {
            margin-top: 56px;
            .c-faqs__container {
                @include mq($from: tablet) {
                    margin-top: 56px;
                }
            }
        }
        
        .c-faqs-questions-list__title {
            @include mq($until: 1000px) {
                color: $color-blue;
                padding-right: 21px;
                position: relative;
                &:before {
                    content: '-';
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    border: 1px solid $color-blue-green;
                    border-radius: 50%;
                    right: 5px;
                    top: 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-bottom: 6px;
                    font-size: 27px;
                    font-weight: 100
                }
                &.active {
                    &:before {
                        content: '+';
                        padding: 1px 0;
                        font-size: 16px;
                    }
                }
            }
        }
        .c-faqs-aside {
            display: none;
            @include mq($from: 1000px) {
                display: block !important;
            }
        }
        .c-faqs-questions-list__content {
            @include mq($until: 1000px) {
                display: none;
            }
            
        }
    }
    .c-faqs-aside-list__item {
        margin-bottom: 30px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    
}