.js-expandable-content {
    .is-hidden {
        display: none;
    }

    &.is-expanded {
        .js-expandable-content-more-button {
            display: none;
        }

        .js-expandable-content-less-button {
            display: block;
        }
    }

    .js-expandable-content-less-button {
        display: none;
    }
}