/*/ ========================================================================
//   #THANKS PAGE
//   ======================================================================== */
.thanks {
    padding-bottom: 40px;
    @include mq($from: tablet) {
        padding-bottom: 50px;
    }

    &__icon {
        width: 91px;
        margin: 0 auto 20px;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    &__title {
        font-family: $font-oswald;
        font-weight: 500;
        color: $color-blue;
        font-size: 30px;
        text-align: center;
        margin-bottom: 20px;
        @include mq($from: tablet) {
            margin-bottom: 40px;
            font-size: 40px;
        }
    }

    &__subtitle {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        @include mq($from: tablet) {
            font-size: 24px;
        }
    }

    &-steps {
        margin-top: 30px;
        @include mq($from: tablet) {
            margin-top: 40px;
        }

        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            @include mq($from: tablet) {
                display: flex;
                justify-content: center;
            }
        }

        &__item {
            padding: 18px 8px 18px 50px;
            position: relative;
            background-color: $color-light-blue;
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 20px;
            @include mq($from: tablet) {
                width: calc(33.33% - 10px);
                margin: 0 11px 0;
                padding: 14px 20px 15px 45px;
            }

            strong {
                position: absolute;
                font-size: 18px;
                top: 18px;
                left: 18px;
                font-family: $font-oswald;
                font-weight: 700;
                color: $color-blue;
                @include mq($from: tablet) {
                    top: 10px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    + .s-cms-content {
        font-size: 18px;

        br {
            display: none;
            @include mq($from: 760px) {
                display: block;
            }
        }
        a {
            @include mq($until: tablet){
                display: block;
            }
        }
    }
}