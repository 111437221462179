.c-hero-blog {
    //position: relative;
    width: 100%;
    height: 140px;
    @include mq($from: tablet) {
        height: 181px;
    }
    @include mq($from: desktop) {
        height: 181px;
    }

    &__pic {
        /*position: absolute;
        top: 0;
        left: 0;
        z-index: -1;*/
        width: 100%;
        height: 100%;
        display: block;
        
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
        @include mq($from: tablet) {
            object-position: left center;
        }
    }

    /*&__content {
        padding: 32px 20px 40px;
        @include mq($from: tablet) {
            padding: 55px 20px;
        }
        @include mq($from: desktop) {
            padding: 70px 20px;
        }
    }

    &__title {
        margin: 0;
        font-family: $font-oswald-medium;
        font-weight: 500;
        font-size: 20px;
        line-height: 1.2;
        max-width: 60%;
        @include mq($from: tablet) {
            font-size: 26px;
        }
        @include mq($from: desktop) {
            font-size: 32px;
        }
    }*/
}