.c-careers {
    &-opportunity {
        text-align: center;
        /*@include mq($until: desktop) {
            margin-bottom: 40px;
        }*/

        &__content {
            background-color: $color-light-blue;
            padding: 40px 20px;
            
            @include mq($from: desktop) {
                padding: 50px 30px;
            }
        }
        &__title {
            color: $color-blue;
            margin: 0;
            margin-bottom: 16px;
            font-family: $font-oswald;
            font-weight: 400;
            font-size: 28px;
            @include mq($from: desktop) {
                font-size: 36px;
                margin-bottom: 30px;
            }
        }
        &__description {
            font-size: 18px;
            font-weight: 600;
            @include mq($from: desktop) {
                font-size: 20px;
            }

            a {
                color: $color-blue;
            }
        }
        &__controls {
            margin: 24px auto 0;
            text-align: center;
            @include mq($from: desktop) {
                margin: 52px auto 0;
            }
            .c-button {
                font-size: 20px;
                @include mq($from: desktop) {
                    font-size: 24px;
                }
                span {
                    font-weight: 700;
                }
            }
        }
    }
    
}