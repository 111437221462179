///* ========================================================================
//   #SITE HEADER NEAREST STORE
//   ======================================================================== */

.c-nearest-store {
    position: relative;
    z-index: 5;

    &__toggle {
        display: flex;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        align-items: center;
        padding: 0 50px 0 20px;
        font-size: 12px;
        text-align: left;
        line-height: 1.2;
        height: 45px;
        transition: box-shadow 0.3s ease-in-out;

        &:focus {
            outline-offset: -2px;
        }

        strong {
            @include mq($from: wide) {
                font-weight: 600;
            }

            &:last-of-type {
                color: $color-orange;
                font-weight: normal;
            }
        }

        &.is-active {
            background-color: $color-light-blue;
            @include mq($from: wide) {
                background-color: #fff;
                box-shadow: none;
                transition: box-shadow 0.3s ease-in-out;
            }
        }

        br {
            @include mq($from: wide) {
                display: none;
            }
        }
    }

    &__icon {
        font-size: 16px;
        margin-right: 6px;
        color: $color-blue;
    }

    &__toggle-indicator {
        font-size: 18px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        transition: $global-transition;

        .c-nearest-store__toggle.is-active & {
            transform: translateY(-50%) rotate(180deg);
            transition: $global-transition;
        }

    }

    &__content {
        padding: 45px 25px 25px;
        font-size: 16px;
        line-height: 1.5;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        position: absolute;
        top: 100%;
        background-color: #fff;
        width: 100%;
        @include mq($from: wide) {
            max-width: 375px;
        }

        &::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #fff;
            transform: rotate(-45deg);
            position: absolute;
            top: -4px;
            left: 85px;
            @include mq($from: wide) {
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
            }
        }

        &::after {
            content: '';
            display: inline-block;
            width: 20px;
            height: 10px;
            position: absolute;
            background: #fff;
            top: 0;
            left: 85px;
        }

        &.is-close {
            transform: translateY(-20px);
            opacity: 0;
            visibility: hidden;
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility ease-in-out 0.3s;
        }

        &.is-open {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility ease-in-out 0.3s;
        }
    }

    &__miles {
        margin: 0;
        position: absolute;
        top: 45px;
        right: 25px;
    }

    &__status {
        margin: 0 0 10px;

        strong {
            color: $color-orange;
        }
    }

    &__address {
        margin: 0 0 15px;

        strong {
            font-size: 18px;
        }
    }

    &__phone-number {
        @include mq($from: wide) {
            padding: 0;
            height: auto;
            border: 0;

            .o-icon {
                color: $color-orange;
            }

            &:hover {
                box-shadow: none;
            }
        }
    }

    &__details {
        @include mq($from: wide) {
            padding: 0;
            height: auto;
            border: 0;
            text-decoration: underline;

            &:hover {
                box-shadow: none;
            }
        }
    }

    &__more-stores {
        margin-top: 36px;
        display: inline-flex;
        align-items: center;
        color: $color-blue;
        text-decoration: none;
        font-size: 18px;
        font-weight: 700;

        .icon {
            font-size: 18px;
            margin-left: 4px;
        }

        &:hover {
            text-decoration: underline;
        }

        @include mq($from: wide) {
            margin-top: 16px;
        }
    }

    &__close-control {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        background-color: transparent;
        position: absolute;
        top: 10px;
        right: 20px;
    }
}