.c-car-types-widget {
    
    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    
        @include mq($from: desktop) {
            justify-content: center;
        }
    }

    &__item {
        height: auto;
        width: 150px;
        background-color: $color-white;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        border-radius: 8px;
        border: 1px solid $color-silver;
        margin-bottom: 30px;
        
        @include mq($from: desktop) {
            height: 170px;
            width: 168px;
            margin: 0 10px 30px;
        }
        &.is-hidden {
            display: none;
        }
       
    }

    &__link {
        text-align: center;
        padding: 20px 3px;
        display: block;
        font-size: 16px;
        text-decoration: none;
        color: $color-blue;
        font-weight: 600;
        @include mq($from: desktop) {
            padding: 20px 0 0;
        }
        &:hover {
            text-decoration: underline;
        }

        img {
            @include mq($from: desktop) {
                width: 168px;
                height: 100px;
            }
        }

        p {
            margin: 0;
            line-height: 1.38;
        }
    }
    +.c-button__container {
        margin-top: 0;
        .c-button {
            color: $color-blue;
            &:hover {
                color: $color-black;
            }
        }
    }
}