.c-quote {
    @include mq($from: 780px) {
        display: flex;
    }
    &__item {
        margin-bottom: 10px;
        @include mq($from: 780px) {
            margin-bottom: 0;

            &:nth-child(1) {
                min-width: 290px;
                margin-right: 20px;
            }
            &:nth-child(2) {
                width: 140px;
                margin-right: 20px;
                html[lang=es-US] & {
                    width: 195px;
                }
            }
        }
        @include mq($from: desktop) {
            &:nth-child(2) {
                width: 150px;
                margin-right: 20px;
                html[lang=es-US] & {
                    width: 225px;
                }
            }
        }
    }

    &__label {
        font-size: 15px;
    }

    &__select, &__input {
        margin-top: 4px;
        display: block;
        width: 100%;
        height: 50px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 20px;
        border: 1px solid $color-silver;
        padding-left: 12px;
        background-color: #fff;
    }
    
    &__input {
        padding-right: 12px;
        &.form__valid {
            border: 1px solid $color-green;
        }

        &.form__error {
            border: 1px solid $color-orange;
        }
    }
    label.form__error {
        background-color: $color-orange;
        color: #fff;
        font-size: 12px;
        padding: 6px 9px;
        display: inline-block;
        margin-top: 7px;
        border-radius: 6px;
        position: relative;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -5px;
            left: 20px;
            width: 1px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 5px solid $color-orange;
        }
    }

    .is-nsd label.form__error  {
        @include mq($from:780px) {
            width: 180%;
            margin-left: -42%;

            &::before {
                left: 49%;
            }
        }
    }

    &__controls {
        margin-top: 15px;
        text-align: center;        
        @include mq($from: 780px) {
            min-width: 200px;
            margin-top: 30px;            

            html[lang=es-US] & {
                min-width: 130px;
            }
        }
        @include mq($from: desktop) {
            min-width: 225px;
            margin-top: 28px;
            

            html[lang=es-US] & {
                min-width: 170px;
            }
        }
    }

    &__submit {
        @include mq($from: 780px) {
            width: 100%;
        }

        &:disabled {
            opacity: 0.9;
            cursor: wait;
        }
    }

    &__loading {
        display: none;
        width: 28px;
        height: auto;
        margin-left: 6px;

        .c-quote.is-loading & {
            display: block;
        }
    }
}