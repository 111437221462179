/*/ ========================================================================
//   #CHECKED LIST
//   ======================================================================== */
.c-checked-list {

    .s-cms-content + & {
        margin-top: 30px;
        @include mq($from: desktop) {
            margin-top: 48px;
        }
    }

    &--with-media {
        @include mq($from: desktop) {
            display: flex;
            align-items: center;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include mq($from: desktop) {
            .c-checked-list--with-media & {
                width: 500px;
                padding-right: 66px;
                display: block;
            }
        }
    }

    &__item {
        width: 100%;
        background-image: url('../images/components/list/icon-check.svg');
        background-repeat: no-repeat;
        background-position: left 6px;
        background-size: 16px;
        padding-left: 28px;

        @include mq($from: desktop) {
            width: calc(50% - 45px);
            background-size: 22px;
            padding-left: 45px;
            background-position: left 3px;

            .c-checked-list--full-width & {
                width: 100%;
            } 
        }

        .c-checked-list--with-media & {
            width: 100%;
        }
    }

    &__title {
        color: $color-black;
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 8px;

        @include mq($from: desktop) {
            font-weight: 600;
            margin: 0 0 16px;
        }

        &-link {
            color: inherit;
            
            &:hover,&:active {
                color: $color-blue;
            }
        }
    }

    &__text {
        margin: 0 0 30px;

        @include mq($from: desktop) {
            margin: 0 0 36px;
        }

        a {
            color: $color-blue;
        }
        p {
            margin-top: 0;
        }
        strong {
            color: $color-blue;
            font-weight: 600;
        }
    }

    &__item:last-child {
        .c-checked-list__text {
            margin-bottom: 0;
        }
    }

    &__media {
        @include mq($from: desktop) {
            width: calc(100% - 500px);
            margin: 0;
        }
    }

    &__img, &__video {
        display: block;
        max-width: 460px;
        margin: 40px auto 0;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        @include mq($from: desktop) {
            margin-top: 0;
        }

        img, video {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}