.c-product-card-home {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;


    @include mq($from: tablet) {
        justify-content: space-between;
        padding-top: 50px;
    }
    

    &__item {
        max-width: 320px;
        width: 100%;
        padding-bottom: 20px;
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        }

        @include mq($from: tablet) {
            margin-bottom: 40px;
            width: calc(50% - 15px);
            max-width: inherit;

            &:last-child {
                margin-bottom: 40px;
            }
        }
    }

    &__title {
        font-family: $font-oswald;
        font-weight: 400;
        font-size: 24px;
        position: absolute;
        left: 20px;
        top: 0;
        bottom: 0;
        max-width: 192px;
        margin: 0;
        line-height: 1.27;
        height: 100%;
        display: flex;
        align-items: center;

        @include mq($from: desktop) {
            font-size: 30px;
            max-width: 306px;
            top: 20px;
            bottom: auto;
            height: auto;
            display: block;
        }

        a {
            text-decoration: none;
            color: inherit;

            &:hover {
                color: $color-blue;
            }
        }
    }   

    &__heading {
        width: 100%;
        height: 120px;
        position: relative;

        @include mq($from: desktop) {
            height: 140px;
        }
    }

    &__picture {
        display: block;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right;
        }
    }

    &__info {
        margin-top: 14px;
        padding: 0 20px;

        p {
            margin: 0;
        }
    }

    &__button {
        text-decoration: none;
        color: $color-black;
        font-size: 16px;
        font-weight: 700;
        display: inline-block;
        margin-top: 8px;
        @include mq($from: desktop) {
            font-size: 18px;
        }

        &:hover {
            color: $color-blue;
        }
    }
}
