/*/ ========================================================================
//   #GLOSSARY
//   ======================================================================== */
.c-glossary{

    &-letters {

        &__wrapper {
            border-bottom: 1px solid $color-silver;
        }

        &__list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            max-width: 671px;
            margin: auto;
            padding: 10px 0 36px;
            @include mq($from: desktop) {
                justify-content: center;
                padding: 40px 0 36px;
            }
        }

        &__item {
            width: 32px;
            height: 32px;
            background-color: $color-sky-blue;
            border-radius: 50%;
            margin: 6px;
            @include mq($from: 375px) {
                margin: 7px;
            }
            @include mq($from: tablet) {
                margin: 8px;
            }
        }
        &__link {
            color: $color-blue;
            font-size: 20px;
            font-weight: 600;
            text-decoration: none;
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;

            &.disabled {
                color: $color-silver;
                pointer-events: none;
            }
        }
    }

    &-description {

        &__heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 34px 0 24px;
            &:first-child {
                margin: 30px 0 24px;
            }
            @include mq($from: desktop) {
                margin: 60px 0 33px;
                &:first-child {
                    margin: 30px 0 33px;
                }
            }
        }

        &__letter {
            font-size: 36px;
            font-weight: 500;
            color: $color-blue;
            font-family: $font-oswald;
            margin: 0;
        }

        &__back-link {
            font-weight: 600;
            font-size: 18px;
            color: $color-black;

            &:hover {
                color: $color-blue;
            }
        }
        &__item {
            margin-bottom: 15px;
            @include mq($from: tablet) {
                margin-bottom: 30px;
            }
        }
        &__title {
            font-weight: 700;
        }

        &__text {
            margin: 5px 0 0;
            @include mq($from: tablet) {
                margin: 3px 0 0;
            }
        }
    }
}