.c-office-locator-results {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: 30px;
    @include mq($from: wide) {
        width: calc(100% + 20px);
    }

    &__item {
        border-bottom: 1px solid #DFDFDF;

        &:last-child {
            border-bottom: 0;
        }
    }
    
    .s-cms-content {
        text-align: left;
        padding: 0 20px 30px;

        ul {
            margin-left: 0;
            column-count: 1;
        }

        button {
            text-decoration: underline;
            font-weight: 600;
            background-color: transparent;

            &:hover {
                color: $color-blue;
            }
        }
    }
}


.c-office-teaser {
    background-color: #fff;
    padding: 25px 20px 30px;
    @include mq($from: desktop) {
        padding: 20px 200px 20px 30px;
        position: relative;
    }

    &:hover {
        background-color: $color-light-blue;
    }

    &__link {
        text-decoration: none;
        color: $color-black;
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.37;
        margin: 0;
        display: inline;
    }

    &__distance {
        font-weight: normal;
    }

    &__rating {
        margin-top: 10px;
    }

    &__phone {
        display: flex;
        margin-top: 10px;
        text-decoration: underline;
        font-size: 18px;
        text-decoration: none;
        color: $color-blue;
        font-weight: 600;
        align-items: center;
        height: 46px;
        border-radius: 30px;
        border: 2px solid $color-blue;
        width: 183px;
        justify-content: center;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

        &:hover {
            background-color: $color-blue;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
            color: $color-white;
        }

        .o-icon {
            font-size: 18px;
            margin-right: 9px;
            //text-decoration: none;
        }

    }

    &__time {
        margin-top: 10px;
    }

    &__status {
        font-weight: 600;
        font-size: 16px;

        &--open {
            color: $color-blue;
        }

        &--closed {
            color: $color-orange;
        }
    }

    &__controls {
        margin-top: 20px;
        display: flex;
        @include mq($from: desktop) {
            position: absolute;
            top: 20px;
            right: 30px;
            margin-top: 0;
        }
    }

    &__control {
        margin-right: 16px;
        display: block;
        text-align: center;
        text-decoration: none;

        &--phone {
            @include mq($from: desktop) {
                display: none;
            }
        }

        .o-icon {
            line-height: 0.6;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: color 0.2s ease-in-out;
            margin-left: auto;
            margin-right: auto;
            font-size: 16px;
           
        }
        &-icon {
            &--details {
                color: $color-blue-green;
                border: 1px solid $color-blue-green;
                font-size: 15px !important;
            }
            &--get-directions {
                color: $color-fuchsia;
                border: 1px solid $color-fuchsia;
            }
        }

        &:hover {

            .c-office-teaser__control-icon--details {
                transition: color 0.2s ease-in-out;
                + .c-office-teaser__control-text {
                    color: $color-blue-green;
                }
            }
            .c-office-teaser__control-icon--get-directions {
                transition: color 0.2s ease-in-out;
                + .c-office-teaser__control-text {
                    color: $color-fuchsia;
                }
            }
            
        } 

        &:last-child {
            margin-right: 0;
        }

        &-text {
            font-size: 15px;
            text-decoration: none;
            color: $color-black;
            margin-top: 6px;
            font-weight: 600;
            text-decoration: underline;
            @include mq($from: desktop) {
                display: block;
            }
        }
    }

    &__affiliated {
        margin-top: 22px;
        display: flex;
        align-items: center;
        @include mq($from: desktop) {
            margin-top: 10px;
        }

        &-text {
            font-weight: 600;
            font-size: 14px;
            color: $color-blue-green;
        }

        &-logo {
            display: block;
            margin-left: 10px;
        }
    }

    &__franchise {
        color: $color-blue-green;
        font-size: 16px;
        margin: 12px 0 10px;
        line-height: 1.33;

        .c-office-hero & {
            font-size: 18px;
            @include mq($from: desktop) {
                font-size: 20px;
            }
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        strong {
            font-weight: 700;
            display: block;
        }
    }
}

.c-office-teaser-rating {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 15px;
    @include mq($from: desktop) {
        font-size: 16px;
    }
    
    &__stars {
        margin-left: 8px;
        display: flex;
        align-items: center;
    }

    &__star {
        display: block;
        margin-left: 7px;

        &:first-child {
            margin-left: 0;
        }
    }

    &__reviews {
        margin-left: 9px;
        text-decoration: none;
        color: $color-black;

        &:hover {
            color: $color-blue;
        }
    }
}