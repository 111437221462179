/** ========================================================================
//   #SITE FOOTER
//   ======================================================================== */

.c-site-footer {
    background-color: $color-light-blue;
    text-align: center;
    padding: 37px 0 0;
    position: relative;

    @include mq($from: desktop) {
       text-align: left;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding-bottom: 56px;
        
        @include mq($from: desktop) {
            flex-direction: row;
        }
    }

    &__logo {
        display: inline-block;
        margin-bottom: 36px;

        @include mq($from: desktop) {
            margin-bottom: 20px;
        }
        img {
            @include mq($from: desktop) {
                width: 170px;
                height: 43px;
            }
        }
    }

    &__contact {
        width: 100%;

        @include mq($from: desktop) {
            width: 307px;
            padding-right: 38px;
        }
    }

    &__text {
        font-size: 16px;
        margin: 0;
        @include mq($from: desktop) {
            font-size: 15px;
        }
    }

    &-controls{
        max-width: 320px;
        margin: 0 auto;

        &__title {
            font-family: $font-opensans;
            font-weight: 700;
            display: block;
            font-size: 16px;
            color: $color-black;
            margin-bottom: 5px;
            @include mq($from: desktop) {
                font-size: 18px;
                margin-bottom: -5px;
            }
        }

        &__mobile {
            display: block;
            
    
            .c-button {
                display: flex;
                margin: 0 auto 20px;
                &--blue, &--blue-green, &--fuchsia {
                    width: 222px;
                }
            }

            @include mq($from: desktop) {
                display: none;
            }
        }

        &__desktop {
            display: none;
            @include mq($from: desktop) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &__link {
            text-decoration: none;
            color: $color-black;
            font-family: $font-opensans;
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            margin: 0 0 25px;

            &:first-of-type {
                margin-top: 12px;
            }

            &:last-of-type{
                margin-bottom: 0;
            }
        }

        &__icon {
            padding-right: 8px;
            font-size: 23px;
        }
    }

    .c-social-media-list {
        //justify-content: center;
        padding: 36px 0;
        border-bottom: 1px solid $color-silver;

        @include mq($from: desktop) {
            //justify-content: end;
            border: 0;

            &--centered {
                justify-content: flex-start;
            }
        }
    }
    .c-social-media-rating {

        @include mq($from: desktop) {
            justify-content: flex-start;
        }
    }
}