///* ========================================================================
//   #MEDIA GRID
//   ======================================================================== */

.c-media-grid {

    &__list {        
        @include mq($from: tablet) {
            display: flex;
            align-items: center;
            margin-top: -30px;
            margin-left: -20px;
            width: calc(100% + 20px);

            &--column {
                align-items: initial;
            }
        }
    }

    &__item {
        display: flex;
        margin-bottom: 30px;
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }

        @include mq($from: tablet) {
            margin-bottom: 0;
            margin-top: 30px;
            margin-left: 20px;
            width: calc(50% - 20px);

            .c-media-grid__list--column & {
                width: 100%;
                max-width: 300px;
                margin-left: 0;
                margin-right: 30px;
                margin-top: 0;
                flex-direction: column;
                padding: 30px 0;

                &:hover {
                    background-color: $color-light-blue;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

                    .c-media-grid__title {
                        color: $color-blue;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__img {
        width: 120px;
        display: block;
        border-radius: 50%;
        //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        
        .c-media-grid__list--column & {
            width: 100px;
            box-shadow: none;
            overflow: hidden;
        }

        @include mq($from: desktop) {
            width: 200px;

            .c-media-grid__list--column & {
                width: 200px;
            }
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__content {
        padding-left: 10px;
        width: calc(100% - 120px);

        .c-media-grid__list--column & {
           padding-left: 20px;
        }

        @include mq($from: desktop) {
            width: calc(100% - 220px);
            padding-left: 10px;

            .c-media-grid__list--column & {
                width: 100%;
                padding: 0 20px;
            }
        }
    }

    &__title {
        font-size: 20px;
        font-family: $font-oswald-medium;
        margin: 0;
        font-weight: normal;
        line-height: 1.2;

        .c-media-grid__list--column & {
            font-family: $font-oswald;
            font-weight: 400;
        }

        @include mq($from: desktop) {
            font-size: 26px;

            .c-media-grid__list--column & {
                font-size: 30px;
                margin-top: 40px;
                margin-bottom: 16px;
            }
        }

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: $color-blue;
            }
        }
    }

    .s-cms-content {
        display: none;
        text-align: left;
        margin-top: 8px;
        
        @include mq($from: desktop) {
            display: block;
        }
    }

    &__button {
        margin-top: 10px;
        color: $color-black;
        text-decoration: none;
        font-size: 16px;
        font-weight: 700;
        display: inline-block;
        @include mq($from: desktop) {
            font-size: 18px;
        }

        &:hover {
            color: $color-blue;
            text-decoration: underline;
        }
    }
}