.c-blog-search-error {
    padding-left: 30px;

    &__item {
        margin: 16px 0 0;
        font-size: 16px;
        @include mq($from: desktop){
            font-size: 18px;
        }
        &::marker{
            font-size: 15px;
        }
    }
}