.c-slider-thumbnails {

    &__content {
        text-align: center;
        margin-bottom: 42px;

        h2{
            font-size: 16px;
            margin-bottom: 0;
        }
        p {
            margin-top: 14px;
        }
    }

    &__item {
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .slider-thumbnail {
        display: flex;
        justify-content: center;
        list-style: none;
        padding-left: 0;
        flex-wrap: wrap;
        max-width: 653px;
        gap: 5px;
        margin: 0 auto;

        @include mq($from: desktop) {
            gap: 9px 11px;
        }

        li {
            width: calc(20% - 5px);
            height: 45px;
            cursor: pointer;
            box-shadow: 0px 4px 12px rgba(65, 70, 72, 0.12);
            background-color: $color-white;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mq($from: desktop) {
                width: calc(20% - 11px);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .tns-nav-active {
            border: 3px solid $color-silver;
            border-radius: 4px;
        }
    }
}