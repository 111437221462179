.c-featured-articles {
    margin-top: 30px;
    @include mq($from: desktop) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 40px;
    }
    &-preview {
        
        @include mq($from: desktop) {
            width: calc(50% - 15px);
        }
        

        &__title {
            color: $color-blue;
            font-size: 26px;
            font-family: $font-oswald-medium;
            font-weight: normal;
            line-height: 1.2;
            margin: 12px 0 16px;
            @include mq($from: desktop) {
                font-size: 42px;
            }

            &-link {
                text-decoration: none;
                color: $color-blue;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &__pic {
            min-width: 360px;
            display: block;
            margin-left: -20px;
            margin-right: -20px;
            cursor: pointer;
            @include mq($from: desktop) {
                width: 100%;
                min-width: initial;
                margin: 0;
            }

            img {
                width: 100%;
                height: auto;
                display: block;
                
                @include mq($from: desktop) {
                    border-radius: 8px;
                }
            }
        }
    }
    &__content {
        @include mq($from: desktop) {
            width: calc(50% - 15px);
        }
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        margin: 30px 0 20px 0;
        @include mq($from: desktop) {
            font-size: 24px;
            margin: 0 0 24px 0;
        }
    }

    &__item {
        padding: 20px 16px;
        margin-bottom: 4px;

        &--box {
            border: 1px solid $color-silver;
            border-radius: 8px;
        }
        
        &--separator {
            border-bottom: 1px solid $color-silver;
        }
    }
}