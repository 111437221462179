.c-legal-table {
    @include mq($until: tablet) {
        overflow-x: auto;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            background-color: $color-grey-1;
        }

        &::-webkit-scrollbar:horizontal {
            height: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-orange;
        }
    }
   
    &__table {
        border-collapse: collapse;
        border: 1px solid $color-silver;
        border-spacing: 0;
        max-width: 753px;
        width: 160%;
        margin-top: 20px;
        @include mq($from: 575px) {
            width: 100%;
        }
        tr {
            &:nth-child(even) {
                background-color: $color-light-blue;
                border: 1px solid $color-silver;
            }
            
            td {
                padding: 12px 15px;
                font-size: 14px;
                text-align: center;
                @include mq($from: mobile) {
                    padding: 12px 18px;
                    font-size: 16px;
                }
                @include mq($from: desktop) {
                    padding: 12px 43px;
                }
            }
            td:first-child, th:first-child{
                text-align: left;
            }
            th {
                color: $color-blue;
                font-weight: 600;
                font-size: 16px;
                padding: 12px 15px;
                @include mq($from: mobile) {
                    padding: 12px 18px;
                    font-size: 18px;
                }
                @include mq($from: desktop) {
                    padding: 12px 43px;
                }
            }
            td ,th {
                width: 300px;
            }
        }
    }
}