.c-office-locator-form {
    margin-top: 12px;
    @include mq($from: desktop) {
        margin-top: 30px;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        width: calc(100% - 55px);
        padding-right: 8px;
        max-width: 456px;
        @include mq($from: desktop) {
            padding-right: 16px;
            width: 70%;
        }
        html[lang=es-US] & {
            @include mq($from: wide) {
                width: 66%;
            }
        }
    }

    &__input-wrapper {
        position: relative;
    }

    &__label {
        font-size: 14px;
        @include mq($from: desktop) {
            font-size: 15px;
        }
        
    }

    &__input {
        background-color: #fff;
        border: 1px solid $color-silver;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        display: block;
        height: 48px;
        padding: 0 60px 0 12px;
        font-weight: 600;
        margin-top: 8px;
        @include placeholder($color-black);
        @include mq($from: desktop) {
            height: 50px;
        }
        &.form__valid {
            border: 1px solid $color-green;
        }

        &.form__error {
            border: 1px solid $color-orange;
        }
    }
    label.form__error {
        background-color: $color-orange;
        color: #fff;
        font-size: 12px;
        padding: 6px 9px;
        display: inline-block;
        margin-top: 7px;
        border-radius: 6px;
        position: relative;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -5px;
            left: 20px;
            width: 1px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 5px solid $color-orange;
        }
    }

    &__get-location-button {
        line-height: 0.6;
        background-color: transparent;
        position: absolute;
        top: 25px;
        transform: translateY(-50%);
        right: 12px;
        font-size: 22px;
        color: $color-blue-green;
    }

    &__controls {
        width: 55px;
        margin-top: 32px;
        @include mq($from: desktop) {
            width: auto;
            margin-top: 34px;
        }
        
        html[lang=es-US] & {
            margin-top: 56px;
            
            @include mq($from: 370px) {
                margin-top: 32px;
            }
            @include mq($from: wide) {
                margin-top: 58px;
            }
            @include mq($from: 1216px) {
                margin-top: 34px;
            }
        }
    }

    &__submit {
        background-color: $color-blue-green;
        color: #fff;
        font-size: 25px;
        line-height: 0.6;        
        padding: 0 16px;
        height: 48px;
        width: 100%;
        border-radius: 12px;
        @include mq($from: desktop) {
            font-weight: 700;
            font-size: 24px;
            border-radius: 30px;
            padding: 0 32px;
        }
        
        &:disabled {
            opacity: 0.9;
            cursor: wait;
        }
        
        .o-icon {
            @include mq($from: desktop) {
                display: none;
            }
        }

        &-text {
            display: none;
            @include mq($from: desktop) {
                display: block;
            }
        }
    }

    &__filters {
        margin-top: 12px;
        display: flex;
        width: 100%;
        @include mq($from: desktop) {
            margin-top: 24px;
        }
    }

    &__distance {
        background-color: $color-light-blue;
        border: 1px solid $color-blue-green;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        height: 40px;
        padding-left: 10px;
        width: 115px;
        padding-right: 27px;
        @include mq($from: desktop) {
            height: 48px;
        }
    }

    &__time {
        margin-left: 12px;
    }
}

.c-office-locator-time-switch {
    border: 1px solid $color-blue-green;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    .is-keyboard-pressed &:focus-within {
        outline: 2px solid #FF6200;
    }

    &__radio:checked + &__label {
        color: #fff;
        transition: color 0.2s ease-in-out;
    }

    &__radio:nth-child(3):checked ~ &__indicator {
        transform: translate(101%);
        transition: transform 0.2s ease-in-out;
    }

    &__label {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        font-size: 16px;
        color: $color-black;
        transition: color 0.2s ease-in-out;
        position: relative;
        z-index: 2;
        cursor: pointer;

        html[lang=es-US] & {
            font-size: 15px;
            text-align: center;
            line-height: 1.1;
        }
        @include mq($from: desktop) {
            height: 48px;
            padding: 0 20px;
            
            html[lang=es-US] & {
                font-size: 16px;
            }
        }
    }
    
    &__indicator {
        width: 50%;
        height: 100%;
        background-color: $color-blue;
        position: absolute;
        left: -1px;
        top: 0;
        z-index: 1;
        border-radius: 8px;
        transition: transform 0.2s ease-in-out;
    }
}