/*/ ========================================================================
//   # wallpapers
//   ======================================================================== */
.c-wallpapers {
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 100%;
        background-color: $color-white;
        box-shadow: 0px 4px 12px rgba(65, 70, 72, 0.12);
        margin: 0 0 24.5px;

        @include mq($from: tablet) {
            margin: 0 12.5px 25px;
        }

        &--50 {
            @include mq($from: tablet) {
                width: calc(50% - 51px);
            }
        }

        &--33 {
            @include mq($from: desktop) {
                width: calc(33.33% - 26px);
            }
        }
    }

    &-preview {
        @include mq($until: tablet) {
            .o-crop--fill>.o-crop__content {
                width: 100%;
            }
        }

        &__img {
            min-height: 187px;
            height: 100%;
            @include mq($from: desktop) {
                min-height: 202px;
            }
        }

        &__img--link {
            display: block;
            width: 50%;
        }
        a {
            text-decoration: none;
            display: block;
            &:hover {
                text-decoration: underline;
            }
        }

        &__title {
            font-size: 16px;
            font-weight: 700;
            line-height: 1.3;
            margin: 0;
        }

        &__link {
            font-size: 18px;
            font-weight: 600;
            color: $color-black;
            &:hover {
                color: $color-blue;
                text-decoration: underline;
            }
        }
        &__content {
            padding: 23px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                color: $color-blue;
                font-weight: 600;
                display: inline-flex;
                .o-icon {
                    font-size: 24px;
                    padding-left: 5px;
                }
            }
        }
    }
}