.c-glossary-banner  {

    &--blog {
        margin: 30px 0;
        @include mq($from: desktop) {
            margin: 40px 0;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        margin: auto;
        flex-direction: column;
        padding: 16px 0 24px;
        border-top: 1px solid $color-blue-green;
        border-bottom: 1px solid $color-blue-green;
    
        @include mq($from: desktop) {
            padding: 23px 0;
            flex-direction: row;
        }
    }

    &__title {
        font-family: $font-oswald-medium;
        font-weight: normal;
        line-height: 1.2;
        margin: 8px 0 15px;
        text-align: center;
        font-size: 26px;
        max-width: 300px;
        color: $color-blue-green;

        @include mq($from: desktop) {
            font-size: 32px;
            max-width: inherit;
            margin: 0 24px;
        }
    }
    &__image {
        width: 160px;
        @include mq($from: desktop) {
            width: 230px;
        }
        img {
            width: 100%;
        }
    }

    &__controls {
        .c-button {
            height: 50px;
            color: $color-blue;
            min-width: 166px;
            font-weight: 700;
            &:hover {
                color: $color-black;
            }
        }
    }
}