/*/ ========================================================================
//   #WEB FORM - Heading Form 
//   ======================================================================== */
.c-heading-form {
    background-color: $color-light-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    padding: 5px 15px;
    height: 60px;
    @include mq($from: tablet) {
        padding: 20px 15px;
        height: auto;
    }
    @include mq($from: desktop) {
        padding: 20.5px 15px;
    }

    &--payment {
        height: 83px;
        @include mq($from: desktop) {
            padding: 54px 15px;
        }
    }

    &__title {
        font-family: $font-oswald-medium;
        font-weight: 500;
        font-size: 18px;
        margin: 0;
        
        @include mq($from: tablet) {
            font-size: 20px;
        }
        @include mq($from: desktop) {
            font-size: 36px;
        }
        &--big {
            font-size: 28px;   
            @include mq($from: desktop) {
                font-size: 52px;
            }
        }

        &--blue {
            color: $color-blue;
        }
        
    }

    &__icon {
        font-size: 32px;
        margin-right: 13px;
        padding-top: 2px;
        color: $color-blue;
        @include mq($from: tablet) {
            font-size: 38px;
        }
        @include mq($from: desktop) {
            font-size: 57px;
        }
    }
    + .c-quote-product {
        margin-top: 35px;
        @include mq($from: tablet) {
            margin-top: 45px;
        }
    }

}
/*/ ========================================================================
//   #WEB FORM - Quote product
//   ======================================================================== */
.c-quote-product {
    padding: 0 10px;
    //margin-top: -50px;
    @include mq($from: tablet) {
        //margin-top: -100px;
    }

    &__container {
        max-width: 890px;
        padding: 13px 0px 30px;
        border-radius: 16px;
        margin: auto;
        @include mq($from: tablet) {
            padding: 15px 20px 36px;
        }
        @include mq($from: desktop) {
            padding: 23px 98px 36px;
        }
    }

    &__title {
        font-weight: normal;
        font-size: 18px;
        line-height: 1.24;
        margin: 0 0 20px;
        color: $color-blue;
        width: 100%;
        font-weight: 700;
        @include mq($from: tablet) {
            font-size: 24px;
            margin: 40px 0 20px;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    &__note {
        font-size: 14px;
        margin-bottom: 24px;
    }

    &__form {
        //margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        @include mq($from: tablet){
            //margin-top: 50px;
        }
    }

    &__item {
        position: relative;
        margin-bottom: 24px;
        width: 100%;

        @include mq($from: tablet) {
            width: calc(50% - 12px)
        }

        &--100 {
            width: 100% !important;
        }
        &--margin-right {
            @include mq($from: tablet) {
                margin-right: 70px;
            }
        }
    }

    &__label {
        font-size: 14px;
        display: inline-block;
        padding-left: 8px;
        margin-bottom: 5px;
        color: #000;
    }

    &__icon {
        position: absolute;
        left: 15px;
        top: 39px;
        color: $color-blue;
        font-size: 24px;
    }

    &__input, &__select,&__textarea {
        border: 1px solid $color-blue-green;
        background-color: #fff;
        padding: 0 10px 0 18px;
        border-radius: 10px;
        font-size: 16px;
        color: #000;
        display: block;
        width: 100%;
        height: 48px;
        
        &.marital-status, &.only-date {
            padding-left: 52px;
        }

        &.form__valid {
            border: 1px solid $color-green;
        }

        &.form__error {
            border: 1px solid $color-orange;
        }
    }

    &__select {
        padding-right: 6px;
    }
    &__textarea {
        height: 100px;
        padding: 10px ;
    }

    label.form__error {
        background-color: $color-orange;
        color: #fff;
        font-size: 12px;
        padding: 6px 9px;
        display: inline-block;
        margin-top: 7px;
        border-radius: 6px;
        position: relative;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -5px;
            left: 20px;
            width: 1px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 5px solid $color-orange;
        }
    }
    

    &__text {
        font-size: 12px;
        line-height: 1.5;
        color: $color-black;

        p {
            margin: 0;
        }

        a {
            transition: color 0.2s linear;
            color: $color-black;

            &:hover {
                color: $color-blue;
                transition: color 0.2s linear;
            }
        }
    }

    &__controls {
        width: 100%;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq($from: tablet) {
            margin-top: 18px;
            justify-content: flex-end;
        }
    }

    &__control {

        &.c-button {
            height: 45px;
            font-size: 19px;

            @include mq($from: tablet) {
                font-size: 24px;
                padding: 0 25.2px;
            }
        }
        
        &--submit {
            margin-right: 0;
            max-width: 163px;
            width: 100%;
            &:disabled {
                opacity: 0.9;
                cursor: wait;
            }
        }
        &--back {
            margin-right: auto;
            max-width: 130px;
            width: 100%;
        }
    }
    &__radio {
        fieldset {
            border: 0;
            display: flex;
            flex-wrap: wrap;
            max-width: 375px;
            padding: 0;
            margin: 0;
            @include mq($from: 600px){
                max-width: initial;
            }
        }
        &-item {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 21px;
            &:last-child {
                margin-bottom: 0;
            }
            @include mq($from: 375px) {
                width: auto;
                margin-right: 30px;
                
                &:last-child {
                    margin-right: 0;
                }
                &:nth-child(even){
                    margin-bottom: 0;
                }
    
            }
            @include mq($from: 600px) {
                margin-bottom: 0;
            }
            @include mq($from: desktop) {
                width: auto;
            }

            .c-quote-product__radio--pet & {
                margin-right: 36px;
                width: auto;
                margin-bottom: 0;
            }
        }
        
        &-input {
            display: block;
            width: 36px;
            height: 36px;
        }

        &-label {
            margin-left: 12px;

            .c-quote-product__radio--pet & {
                color: #a5a5a5;
                font-size: 15px;
                font-weight: 600;
                display: flex;
                align-items: center;
                transition: color 0.2 ease;
                @include mq($from: tablet) {
                    font-size: 16px;
                }
            }

            .o-icon {
                font-size: 20px;
                position: absolute;
                top: 8px;
                left: 8px;
            }
        }
        &-input {

            &:before {
                display: flex;
                flex-shrink: 0;
                content: "";
                background-color: #fff;
                width: 1.5em;
                height: 1.5em;
                border-radius: 50%;
                transition: 0.25s ease;
                box-shadow: inset 0 0 0 1px $color-blue-green;

                .c-quote-product__radio--pet & {
                    width: 36px;
                    height: 36px;
                    border-radius: 8px;
                }
            }
            &:checked {
                &:before {
                    box-shadow: inset 0 0 0 7px $color-blue-green;

                    .c-quote-product__radio--pet & {
                        box-shadow: inset 0 0 0 2px $color-blue;
                    }
                }

                + .c-quote-product__radio-label {
                    color: $color-blue;
                    transition: color 0.2 ease;
                }
            }
        }
        &-title{
            width: 100%;
            font-size: 14px;
            margin-bottom: 20px;
            @include mq($from: tablet) {
                
            }
    
        }
    }
}
.c-quote-switch {
    border: 1px solid $color-blue-green;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    max-width: 144px;
    margin-top: 9px;
    justify-content: space-around;

    .is-keyboard-pressed &:focus-within {
        outline: 2px solid #FF6200;
    }

    &__radio:checked + &__label {
        color: #fff;
        transition: color 0.2s ease-in-out;
    }

    &__radio:nth-child(3):checked ~ &__indicator {
        transform: translateX(calc(100% + 2px));
        transition: transform 0.2s ease-in-out;
    }

    &__label {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        font-size: 16px;
        color: $color-black;
        transition: color 0.2s ease-in-out;
        position: relative;
        z-index: 2;
        cursor: pointer;
        @include mq($from: desktop) {
            height: 36px;
            padding: 0 24px;
        }
    }
    
    &__indicator {
        width: 50%;
        height: 100%;
        background-color: $color-silver-blue;
        position: absolute;
        left: -1px;
        top: 0;
        z-index: 1;
        border-radius: 8px;
        transition: transform 0.2s ease-in-out;
    }
}

/*/ ========================================================================
//   #WEB FORM - Process form
//   ======================================================================== */
.c-process-form {
    margin-bottom: 30px;
    &__wrapper {
        padding-top: 22px;
    }

    &__content {
        position: relative;
        width: 100%;
        margin: auto;
        @include mq($from: 500px) {
            width: 90%;
        }
    }

    &-list {
        display: flex;
        justify-content: space-between;

        &__text {
            height: 40px;
            line-height: 1.4;
        }

        &__circle {
            width: 14px;
            height: 14px;
            background-color: $color-gray-5;
            border: 4px solid $color-gray-5;
            border-radius: 50%;
            box-sizing: content-box;
            margin: -2% auto 0;
            position: relative;
            @include mq($from: tablet) {
                width: 20px;
                height: 20px;
            }
        }

        &__item{
            font-size: 12px;
            font-weight: 600;
            z-index: 2;
            text-align: center;
            width: 33.33%;
            html[lang=es-US] & {
                width: auto;
                @include mq($from: mobile) {
                    width: 33.33%;
                }
            }
            @include mq($from: 400px) {
                padding: 0 10px;
            }
            @include mq($from: tablet) {
                font-size: 14px;
                padding: 0;
            }
            &.active {
                color: $color-blue;

                .c-process-form-list__circle {
                    background-color: $color-sky-blue;
                    border: 4px solid $color-blue-green;
                    
                }
            }
            &--check {
                &.active {
                    .c-process-form-list__circle {
                        background-color: $color-blue-green;
                        &:before,&:after {
                            content: "";
                            width: 1px;
                            height: 5px;
                            background-color: #fff;
                            position: absolute;
                            top: 7px;
                            left: 4px;
                            transform: rotate(-46deg);
                            @include mq($from: tablet) {
                                top: 9px;
                                left: 6px;
                            }
                        }
                        &:after {
                            height: 10px;
                            left: 9px;
                            transform: rotate(45deg);
                            top: 3px;
                            @include mq($from: tablet) {
                                left: 11px;
                                top: 5px;
                            }
                        }
                    }
                }
                
            }
        }
    }
    .c-line-process {
        width: 75%;
        margin: auto;
        background-color: $color-gray-5;
        height: 5px;
        position: absolute;
        left: -5px;
        right: 0;
        bottom: 15%;
        border-radius: 50px;
        transition: all ease 0.9s;
        display: flex;
        justify-content: space-between;
        z-index: 1;
        @include mq($from: tablet) {
            //width: 75%;
            height: 6px;
            bottom: 20%;
        }
        html[lang=es-US] & {
            width: 79%;
            @include mq($from: mobile) {
                width: 75%;
            }
        }

        &__content {
            width: 0;
            height: 100%;
            background-color: $color-silver-blue;
            position: absolute;
            z-index: 2;
            border-radius: 50px;
            transition: all ease 0.9s;
            &--50 {
                width: 50%;
            }
            &--100 {
                width: 100%;
            }
        }
    }
    
}