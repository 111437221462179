.c-payment-options {
    &__wrapper {
        margin-top: 28px;
        @include mq($from: desktop) {
            margin-top: 40px;
        }
    }
    &__item {
        border: 1px solid $color-silver;
        padding: 25px 20px 15px;
        margin-bottom: 20px;
        @include mq($from: desktop){
            padding: 30px 33px 15px 45px;
        }
    }
    &__title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-family: $font-oswald;
        font-weight: normal;
        color: $color-blue;
        @include mq($from: desktop) {
            font-size: 28px;
        }
        &-text {
            margin-left: 15px;
            font-size: inherit;
            font-weight: normal;
            @include mq($from: desktop) {
                margin-left: 30px;
            }
        }
        &-icon {
            width: 36px;
            height: 36px;
            @include mq($from: desktop){
                width: 60px;
                height: 60px;
            }
        }
    }
    &__description {
        strong {
            font-weight: 600;
        }
        p {
            strong {
                color: $color-blue;
                font-weight: 600;
                a {
                    color: inherit;
                }
            }
            a {
                color: $color-blue;
            }
        }
        
    }
}