.c-blog {
    .c-kc-teaser-preview__title {
        font-size: 20px;
        @include mq($from: desktop) {
            font-size: 22px;
        }
    }
    &-intro {
        margin: 40px 0;
        .o-wrapper  {
            padding: 0;
        }
        .c-blog-intro--page &{
            margin: 0;
            
        }
        @include mq($until: desktop) {
            padding-top: 20px;
        }
        &__title {
            font-family: $font-oswald-medium;
            font-weight: 500;
            color: $color-blue;
            font-size: 30px;
            margin: 0;
            width: 100%;
            text-align: center;
            margin-bottom: 25px;
            .c-blog-intro--page &{
                text-align: left;
                margin-bottom: 0;
            }
            @include mq($from: tablet){
                text-align: left;
                width: auto;
                margin-bottom: 0;
            }
            @include mq($from: desktop){
                font-size: 52px;
                .c-blog-intro--page &{
                    font-size: 40px;
                }
            }
        }
        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            @include mq($from: tablet){
                justify-content: space-between;
            }
            .c-blog-intro--page & {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
    }
    &-categories {
        background-color: $color-light-blue;
        padding: 27px 0;
        &-list {
            &__item {
                display: inline-block;
                position: relative;
                padding: 0 16px;
                font-weight: 600;
                line-height: 2.2;
                &:before {
                    content: "";
                    width: 1px;
                    height: 14px;
                    background-color: $color-black;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
                &:hover {
                    color: $color-blue;
                }
                @include mq($until: tablet){
                    &:first-of-type {
                        display: block;
                        &:before {
                            display: none;
                        }
                    }
                }
                &.is-visible {
                    display: inline-block !important;
                }
                
            }
            @include mq($until: tablet) {
                @for $i from 8 through 20 {
                    li:nth-child(#{$i}) {
                     display: none;
                    }
                }
            }
            
            &__title {
                color: $color-orange;
                font-weight: 700;
                font-size: 20px;
            }
            &__link {
                color: inherit;
            }
        }
    }

    &-see-more {
        margin-top: 30px;
        text-align: center;
        display: none;
        @include mq($until: tablet) {
            display: block;
        }
        &__button {
            background-color: transparent;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            &-text {
                padding-left: 10px;
            }
        }
    }
}
.c-blog-results {
    &__content {
        border-top: 1px solid $color-silver;
        padding: 20px 0 40px;
    }
    
    &__title {
        font-weight: 600;
        font-size: 18px;

        @include mq($from: desktop) {
            font-size: 24px;
        }
        span {
            color: $color-blue;
        }
    }
    &__text {
        margin-top: 15px;
    }
}
.c-blog-features {
    &__title {
        color: #0047bb;
        font-family: "Oswald Regular";
        font-weight: 400;
        font-size: 28px;
        margin: 30px 0 24px;
        @include mq($from: desktop) {
            font-size: 32px;
            margin: 54px 0 30px;
        }
        
    }
    &-content{

        @include mq($from: tablet) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    
        &__item {
            margin-bottom: 30px;
            width: 100%;
            @include mq($from: desktop) {
    
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
    
            &:last-child {
                margin-bottom: 0;
            }
    
            &--50 {
                @include mq($from: tablet) {
                    width: calc(50% - 15px);
                }
                @include mq($from: desktop) {
                    width: calc(50% - 10px);
                }
            }
        }
    
        &-preview {
            background-color: #fff;
            box-shadow: 0px 2px 4px rgba(65, 70, 72, 0.15);
            display: flex;
            @include mq($until: tablet) {
                flex-direction: column;
            }
    
            &--big {
                display: block;
                @include mq($from: desktop) {
                    display: flex;
                }
            }
    
            &__img-link {
                display: block;
                width: 100%;
                @include mq($from: desktop) {
                    width: 50%; 
                }
    
                .c-blog-features-content-preview--big & {
                    width: 100%;
                    @include mq($from: desktop) {
                        width: 50%;
                    }
                }
            }
    
            &__img {
                min-height: 210px;
                height: 100%;
    
                .c-blog-features-content-preview--big & {
                    min-height: 186px;
                    @include mq($from: tablet) {
                        min-height: 300px;
                    }
                }
            }
    
            &__content {
                width: 100%;
                padding: 15px 20px 18px;
                @include mq($from: tablet) {
                    width: 50%;
                    padding: 15px 20px 22px;
                }
                @include mq($from: desktop) {
                    padding: 15px 20px 50px;
                }
    
                .c-blog-features-content-preview--big & {
                    width: 100%;
                    @include mq($from: desktop) {
                        width: 50%;
                        padding: 25px 45px;
                    }
                }
            }
            &__categorie {
                color: $color-black;
                text-decoration: none;
                font-weight: 600;
                &:hover {
                    color: $color-blue;
                    text-decoration: underline;
                }
            }
    
            &__title {
                margin: 10px 0 0 0;
                font-weight: 600;
                font-size: 20px;
                line-height: 1.2;
                @include mq($from: desktop) {
                    font-size: 22px;
                }
    
                .c-blog-features-content-preview--big & {
                    font-size: 24px;
                    @include mq($from: desktop) {
                        font-size: 28px;
                    }
                }
    
                a {
                    color: $color-black;
                    text-decoration: none;
    
                    &:hover {
                        text-decoration: underline;
                        color: $color-blue;
                    }
                }
            }
    
            &__text {
                margin: 15px 0 0 0;
                font-size: 16px;
            }
    
            &__link {
                font-weight: 700;
                font-size: 16px;
                text-decoration: none;
                color: $color-black;
                display: inline-block;
                margin-top: 12px;
                @include mq($from: desktop) {
                    font-size: 18px;
                }
    
                &:hover {
                    text-decoration: underline;
                    color: $color-blue;
                }
            }
        }
    }
}

.c-blog-search {
    width: 100%;

    @include mq($from: 400px) {
        max-width: 342px;
        margin: auto 0;
        .c-blog-intro--page &{
           max-width: 600px;
        }
        .c-glossary-page-hero & {
            max-width: 452px;
            margin: auto;
        }

    }

    @include mq($from: desktop){
        max-width: 342px;
    }

    &-form {
        display: flex;
        position: relative;
        .c-blog-intro--page &{
            margin-top: 30px;
            @include mq($from: desktop){
                margin-top: 40px;
            }
        }
        .c-glossary-page-hero & {
            margin-top: 20px;
        }
        &__label {
            width: 100%;
            margin-bottom: 7px;
        }
        &__input {
            width: 75%;
            margin-right: 7px;
            border-radius: 8px;
            border: 1px solid $color-silver;
            font-weight: 400;
            padding: 5px 15px;
            height: 50px;
            @include placeholder($color-gray-3);
            @include mq($from: mobile)  {
                width: 280px;
            }
            .c-blog-intro--page &{
                width: 100%;
                margin-right: 0;
                height: 60px;
                padding-right: 70px;
            }
        }
        &__controls {
            width: 45px;
            height: 50px;
            @include mq($from: mobile) {
                width: 55px;
            }
            .c-blog-intro--page &{
                position: absolute;
                right: 8px;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 44px;
                height: 44px;
            }
        }

        &__control {
            font-size: 24px;
            background-color: $color-blue-green;
            color: $color-white;
            border-radius: 12px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .c-blog-intro--page &{
                font-size: 20px;
            }
        }
    }
}

@include mq($until: tablet) {
    img.alignnone {
        html[lang=es-US] & {
            max-width: 100%;
            height: auto;
        }
    }
}
    
