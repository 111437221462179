.c-blog-nav {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    background-color: $color-white;
    position: sticky;
    top: 0;
    z-index: 1;

    &__container {
        max-width: 1200px;
        padding: 9px 20px;
        margin: 0 auto;
        
        @include mq($from: tablet){
            display: flex;
            align-items: center;
            height: 70px;
            padding: 0 20px;
        }
    }

    &__controls {
        color: $color-black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mq($from: tablet) {
            
        }
        a {
            text-decoration: none;
            color: inherit;
        }
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        @include mq($until: tablet){
            display: none;
            padding: 8px 0 42px 0;
        }
    }
    &__item {
        position: relative;
        padding: 0 21px;

        @include mq($until: tablet){
             padding: 17px 10px;
             color: $color-blue;
             border-bottom: 1px solid $color-blue-green;
            &.view-more {
                display: none;
            }
        }
        
    }

    &__link {
        display: block;
        color: $color-black;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        position: relative;

        @include mq($until: tablet){
            display: flex;
            align-items: center;
            font-size: 18px;
            color: $color-blue;
        }
        &:hover, &.is-active {
            @include mq($from: tablet) {
                color: $color-blue;
                &:before {
                    content: "";
                    position: absolute;
                    bottom: -100%;
                    width: 100%;
                    height: 4px;
                    background-color: $color-blue;
                }
            }
        }
        img {
            display: none;
            @include mq($until: tablet) {
                display: block;
                margin-right: 14px;
            }
        }
    }

    &__brand {
        font-size: 20px;
        font-family: $font-oswald-medium;
        font-weight: normal;
        @include mq($from: tablet) {
            font-size: 28px;
            margin-right: 21px;
        }
        a {
            color: inherit;
        }
    }

    &__toggle {
        font-size: 16px;
        font-weight: 600;
        background-color: transparent;
        display: flex;
        align-items: flex-start;
        gap: 0 27px;
        align-items: center;
        @include mq($from: tablet){
            padding: 0 21px;
            display: none;
        }

        .o-icon {
            display: none;
            @include mq($until: tablet){
                display: inline-block;
                color: $color-black;
            }
        }

        &.is-active .o-icon {
            transform: rotate(180deg);
        }
        a {
            display: flex;
            align-items: center;
            position: relative;
            @include mq($until: tablet) {
                color: $color-blue;
            }
            &.active {
                color: $color-blue;
            }
            &:hover {
                @include mq($from: tablet) {
                    color: $color-blue;
                    &:before {
                        content: "";
                        position: absolute;
                        bottom: -100%;
                        width: 100%;
                        height: 4px;
                        background-color: $color-blue;
                    }
                }
            }
        }
       
    }

    &__search {
        background-color: $color-blue-green;
        width: 43px;
        height: 42px;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        border-radius: 12px;
        margin-right: 0;
        margin-left: auto;
        @include mq($until: tablet){
            display: none;
            
        }
        .c-blog-nav__list & {
            display: none;
            @include mq($until: tablet){
                display: flex;
                margin-left: 10px;
                margin-top: 24px;
                
            }
        }
        &-link {
            color: inherit;
        }
    }
}
