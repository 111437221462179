.c-pagination {
    margin-bottom: 64px;

    .c-main-content--blog & {
        margin-bottom: 30px;
        @include mq($from: desktop) {
            margin-bottom: 40px;
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        &--left {
            justify-content: flex-start;
        }
    }
    &__item {
        width: 40px;
        height: 40px;
        border: 1px solid $color-silver;
        color: $color-silver;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        margin: 0 3px;
        border-radius: 2px;
        &.is-active {

            background-color: $color-blue;
            border-color: $color-blue;
            .c-pagination__link {
                color: $color-white;
            }
        }
    }
    &__link {
        color: $color-black;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width: 100%;
        height: 100%;
        &--prev {
            transform: rotate(90deg);
            color: $color-black;
            font-size: 18px;
            font-weight: 600;
        }
        &--next {
            transform: rotate(-90deg);
            color: $color-black;
            font-size: 18px;
            font-weight: 600;
        }
    }
}