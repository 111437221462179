.c-state-table {
    .s-cms-content + & {
        margin-top: 30px;        

        @include mq($from: desktop) {
            margin-top: 48px;
        }
    }

    &__content {
        text-align: center;

        @include mq($from: desktop) {
            padding-left: 0;
        }
    }

    &__scroll {
        overflow-x: auto;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            background-color: $color-grey-1;
        }

        &::-webkit-scrollbar:horizontal {
            height: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-orange;
        }
    }

    &__table {
        width: 100%;

        th {
            font-size: 18px;
            font-weight: 600;
        }

        th, td {
            padding-left: 30px;
            padding-right: 30px;
            min-width: 180px;
            @include mq($from: desktop) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }        

        strong {
            font-weight: 600;
        }

        
        span {
            transform: translate(1px, -6px);
            font-size: 12px;
            display: inline-block;
            @include mq($from: desktop) {
                font-size: 14px;
            }
        }

        &--large {
            border-spacing: 1px;
            border-bottom: 1px solid $color-silver;
    
            tr {
                height: 65px;
    
                &:nth-child(odd) {
                    background-color: $color-sky-blue;
                }
    
                a {
                    color: $color-black;
    
                    &:hover,&:active {
                        color: $color-blue;
                    }
                }
            }
            th {
                border-top: 1px solid $color-silver;
                border-bottom: 1px solid $color-silver;
            }
            th ,td {
                text-align: left;
            }
        }

        &--small {
            border: 1px solid $color-silver;
            border-spacing: 0;

            th,td {
                padding-left: 10px;
                padding-right: 10px;
            }

            th {
                height: 43px;
                @include mq($until: desktop) {
                    font-size: 14px;
                }
            }
    
            td {
                border-top: 1px solid $color-silver;
                height: 48px;
                font-size: 14px;
                @include mq($from: desktop) {
                    font-size: 16px;
                }
            }

            a {
                color: $color-black;
                &:hover {
                    color: $color-blue;
                }
            }
        }
    }

    &__text {
        font-size: 14px;
        p {
            margin-bottom: 0;
            margin-top: 16px;
            @include mq($from: desktop) {
                margin-top: 20px;
            }
        }
        &--left{
            text-align: left;
        }
        ul {
            list-style: none;
            padding-left: 0;
            @include mq($from: desktop) {
                padding-left: 23px;
            }

            li {
                line-height: 1.5;
                display: flex;
                margin-bottom: 5px;

                @include mq($from: desktop) {
                    margin-bottom: 0;
                }

                span{
                    font-size: 12px;
                    @include mq($from: desktop) {
                        font-size: 14px;
                    }
                    &:first-child {
                        font-size: 14px;
                        padding-right: 9px;
                        font-weight: 600;
                    }
                }
            }
        }
        a {
            color: $color-blue;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}