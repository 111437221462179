.c-promotions {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 40px 10px;

        @include mq($from: desktop) {
            justify-content: space-between;

        }
    }
    &-banner {
        width: 100%;
        margin: 0 10px 20px;
        background-color: $color-white;

        @include mq($from: desktop) {
            width: 46%;
        }

        @include mq($from: wide) {
            width: 48%;
        }

        &__img {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        &__content {
            padding: 10px 20px 20px;
            //background-color: $color-white;
            h2 {
                color: $color-blue;
                font-family: $font-oswald;
                font-size: 24px;
                font-weight: 500;
                margin: 0;
                @include mq($from: wide) {
                    font-size: 30px;
                }
            }
            p {
                margin-top: 0;
            }
            * {
                text-align: left !important;
            }
            a {
                color: $color-black;
                font-size: 18px;
                &:hover {
                    color: $color-blue;
                }
            }
        }
    }
}

.c-promotions-winners {
    &__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include mq($from: 480px) {
            justify-content: flex-start;
        }
    }
    &__controls {
        text-align: center;
        padding-top: 30px;
    }
    &__item {
        display: none;
        width: 100%;
        margin-bottom: 30px;
        background-color: transparent;
        text-decoration: none;
        @include mq($from: 480px) {
            width: calc(50% - 20px);
            margin-right: 40px;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        @include mq($from: tablet) {
            width: calc(33.33% - 20px);
            margin-right: 30px;
            &:nth-child(2n) {
                margin-right: 30px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        @include mq($from: desktop) {
            width: calc(33.33% - 24px);
            margin-bottom: 40px;
            &:nth-child(2n) {
                margin-right: 36px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        &-image {
            width: 100%;
            height: auto;
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        &-content {
            //height: 95px;
            text-align: left;
            padding: 5px 15px 0;
        }
        &-title {
            color: $color-blue;
            font-weight: 600;
            font-size: 20px;
            line-height: 1;
        }
        &-name {
            font-size: 18px;
            font-weight: 600;
        }
        &-name,
        &-date {
            margin: 0;
            color: $color-black;
        }
        &:nth-child(-n+9) {
            display: block;
        }

        .is-visible-three & {
            display: none;
        }

        .is-visible-three &:nth-child(-n+3) {
            display: block;
        }
    }
}

.modaal {
    &-close {
        &:after,
        &:before {
            background: $color-black;
        }
        &:hover {
            &:after,
            &:before {
                background: $color-blue;
            }
        }
    }
    &-gallery-label {
        color: $color-blue;
        font-weight: 600;
        font-size: 24px;
    }
    &-gallery-control {
        @include mq($from: desktop) {
            background-color: rgba(#000, 0.5);
            &:hover {
                box-shadow: 0 0 0 2px rgba(#000, 0.5);
            }
        }
    }
    &-wrapper {
        overflow: hidden !important;
    }
}