.c-features {
    margin: 35px 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__item {
        margin-bottom: 36px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title-icon {
        @include mq($from: desktop) {
            display: flex;
            align-items: center;
        }
    }

    &__icon {
        width: 52px;
        display: block;
        height: auto;
        margin: 0 auto;
        @include mq($from: tablet) {
            width: 70px;
        }
        @include mq($from: desktop) {
            width: 80px;
            margin: 0;
        }
    }

    &__title {
        margin: 24px 0 0;
        font-family: $font-oswald;
        font-size: 24px;
        color: $color-blue;
        font-weight: 400;
        @include mq($from: tablet) {
            font-size: 26px;
        }
        @include mq($from: desktop) {
            font-size: 28px;
            margin: 0 0 0 26px;
        }
    }

    &__content {
        margin-top: 12px;
    }
}