///* ========================================================================
//   #SOCIAL MEDIA LIST
//   ======================================================================== */

.c-social-media-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &--centered {
        justify-content: center;
    }

    &__item {
        margin-right: 16px;
        margin-bottom: 16px;
        @include mq($from: tablet) {
            margin-bottom: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-blue;
        font-size: 20px;
        border-radius: 50%;
    }
}