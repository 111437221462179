.c-main-content-ds{
    .hide2 {
        display: none;
    } 
}  
.c-ds-main-content{
    &__img {
        height: 180px;
        @include mq($from: tablet) {
            height: 181px;
        }
        @include mq($from: desktop) {
            height: 236px;
        }
    }
}