.c-glossary-page{
    display: grid;
    gap: 30px;

    &-hero {
        text-align: center;
        &__title {
            margin-bottom: 20px;
            font-size: 30px;
            color: $color-blue;
            font-weight: normal;
            font-family: $font-oswald-medium;
            @include mq($from: desktop) {
                font-size: 40px;
            }
        }
        .s-cms-content {
            @include mq($from: desktop) {
                font-size: 18px;
            }
        }
    }
    &__letters{
        border-bottom: 1px solid #bdcce4;
        @include mq($from: desktop) {
            display: flex;
            gap: 40px;
        }
    }
    &__letter{
        align-items: center;
        justify-content: center;
        background-color: $color-blue;
        border-radius: 16px;
        color: $color-white;
        font-weight: 700;
        font-size: 32px;
        display: flex;
        line-height: 1.1;
        height: 60px;
        margin-bottom: 16px;
        text-align: center;
        width: 60px;
        border-radius: 50%;
        @include mq($from: desktop) {
            height: 70px;
            margin-bottom: 0px;
            position: sticky;
            top: 100px;
            background-color: $color-sky-blue;
            width: 70px;
            font-size: 42px;
        }

        &.is-pinned {
            background-color: $color-blue;
        }
    }
    &__list{
        display: grid;
        gap: 20px;
        padding-bottom: 20px;
    }
    &__item{
        padding-bottom: 16px;
        @include mq($from: desktop) {
            padding-bottom: 20px;
        }
    }
    &__title{
        display: inline-block;
        line-height: 1.3;
        margin: 0 0 16px;
        font-size: 18px;
        font-weight: 700;
        @include mq($from: desktop) {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
    &__text {
        text-align: left;
    }
}


// #searchGlossary {

//     .autocomplete {
//         &__input,
//         &__input--default {
//             background-color: $color-white;
//             border: 2px solid $color-blue;
//             border-radius: 16px;
//             color: $color-black;
//             display: block;
//             font-size: 15px;
//             font-weight: 600;
//             height: 60px;
//             padding: 0 16px;
//             width: 100%;
//             box-shadow: none;
//             @include mq($from: desktop) {
//                 font-size: 18px;
//             }

//             &:focus {
//                 border: 2px solid $color-blue;
//             }
//         }

//         &__menu {
//             border: 2px solid $color-blue;
//             border-radius: 16px;
//             position: absolute;
//         }
//     }
// }