.c-contact-channels {
    padding: 0 0 16px;
    .office-profile &{
        padding: 40px 0 20px;
    }

    &--no-padding {
        padding: 0;
    }
    &.c-section {
        padding: 40px 0;
    }

    &:last-of-type {
        margin-bottom: 80px;
    }

    &__content {
        border: 1px solid $color-silver;
        border-radius: 8px;
        padding: 21px 20px 30px;
        text-align: center;

        @include mq($from: desktop) {
            padding: 36px 30px 42px;
        }
    }

    &__title {
        font-family: $font-oswald;
        text-align: center;
        font-size: 30px;
        font-weight: normal;
        margin: 0 auto;
        line-height: 1.2;
        margin-bottom: 24px;
        //max-width: 760px;

        .office-profile &{
            font-family: $font-oswald-medium;
        }

        @include mq($from: desktop) {
            font-size: 36px;
        }
        a {
            color: $color-blue;
        }
        &--blue {
            color: $color-blue;
        }
        strong {
            font-family: $font-oswald-medium;
            font-weight: 500;
        }
        + .c-contact-channels__channel {
            margin-top: 35px;
        }
    }
    &__subtitle {
        font-size: clamp(1.125rem,4vw,1.5rem);
    }

    &__text {
        max-width: 760px;
        margin: auto;
        text-align: left;
        a {
            color: $color-blue;
            
        }

        strong {
            font-weight: 600;
        }
        &--big {
            font-size: clamp(1rem,3vw,1.125rem);
            margin-top: 24px;
            text-align: center;
            strong {
                font-weight: 700;
            }

        }
        &--center {
            text-align: center;
            max-width: initial;
        }
    }


    &__channel {
        display: flex;
        justify-content: center;
        margin-top: 24px;

        &_item {
            margin: 0 10px;

            @include mq($from: desktop) {
                margin: 0 20px;
            }
        }

        &_text {
           font-size: 16px;

            @include mq($from: desktop) {
                font-size: 18px;
            }
        }

        &_icon {
            color: $color-white;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin: 0 auto 5px;
            font-size: 22px;
            &:hover {
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
            }

            @include mq($from: desktop) {
                width: 70px;
                height: 70px;
                font-size: 30px;
            }


            &--online {
                background-color: $color-orange;
                
        &:hover {
            background-color: #DA4A00;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        }
            }
            &--phone {
                background-color: $color-blue;
                &:hover {
                    background-color: #1558c5;
                    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
                }
            }
            &--ubication {
                background-color: $color-blue-green;
                &:hover {
                    background-color: #1f8cbe;
                    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
                }
            }
            &--get-directions {
                background-color: $color-fuchsia;
                &:hover {
                    background-color: #d3006bd9;
                    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
                }
            }
        }
    }
}