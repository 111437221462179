.disclaimer-home {
    text-align: center;
    font-size: 14px;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: calc(100% - 40px);
        max-width: 1200px;
        border-top: 1px solid $color-silver;
    }

    p {
        margin: 0 10px 0;

        &:last-child {
            margin: 0;
        }
    }
    
    a {
        color: $color-black;
        text-decoration: underline;
        transition: color 0.2s ease;

        &:hover {
            color: $color-blue;
            transition: color 0.2s ease;
        }
    }
}