.c-newsroom-articles {
    padding-top: 30px;
    @include mq($from: desktop) {
        padding-top: 56px;
    }
}
.c-newsroom-article {

    &__title {
        color: $color-blue;
        font-size: 24px;
        line-height: 1.2;
        @include mq($from: desktop) {
            font-size: 36px;
        }
    }

    &__date {
        margin-top: 20px;
        display: block;
        @include mq($from: desktop) {
            margin-top: 30px;
        }
    }

    &__controls {
        margin-top: 10px;
        @include mq($from: desktop) {
            margin-top: 15px;
        }
        @media print {
            display: none;
        }
    }

    &-controls {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &__control {
            font-size: 24px;
            color: $color-black;
            transition: color 0.3s ease;
            margin-right: 18px;
            background-color:  transparent;
            line-height: 0.6;

            &:hover {
                color: $color-blue;
                transition: color 0.3s ease;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__content {
        margin-top: 20px;
        &-description {
            h2 {
                font-weight: 700;
                color: $color-blue;
                font-size: 16px;
                margin-top: 30px;
            }
            a {
                color: $color-blue;
            }
        }
    }

    &__logo {
        margin-top: 20px;
        width: 128px;
        display: inline-block;
        @include mq($from: desktop) {
            margin-top: 40px;
            width: 200px;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    &__contact {
        p {
            margin: 0;
        }
        a {
            color: inherit;
            &:hover {
                color: $color-blue;
            }
        }
    }
    &__or {
        margin: 25px 0;
    }
    &__source {
        font-weight: 600;
        margin-top: 30px;
    }
    &__related-link {
        margin-top: 20px;
        @include mq($from: desktop) {
            margin-top: 30px;
        }

        p {
            margin: 0;
        }

        a:not([class]) {
            text-decoration: none;
            color: $color-black;
            &:hover {
                color: $color-blue;
                text-decoration: underline;
            }
        }

    }

    &__button {
        margin-top: 40px;
        margin-bottom: 30px;
        @include mq($from: desktop){
            margin-bottom: 50px;
        }

        @media print {
            display: none;
        }
    }
}
