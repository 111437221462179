.c-plain-transcript {

    &-controls  {
        display: flex;
        text-align: left;
        margin-top: 30px;
        flex-direction: column;
        @include mq($from: desktop) {
            margin-top: 45px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            text-align: initial;
        }
        .c-plain-transcript-control {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 30px;
            @include mq($from: desktop) {
                font-size: 20px;
                margin-bottom: 0;
            }
            &:hover {
                opacity: .8;
            }
            &--return {
                color: $color-orange;
    
            }
            &--download {
                color: $color-blue;
            }
        }
    }
}
