.c-timeline {
    position: relative;
    
    &:before {
        position: absolute;
        content: "";
        width: 1455px;
        height: 1px;
        background-color: #8AB2CB;
        left: 67px;
        right: 0;
        margin: auto;
        top: 12px;
        // @include mq($from: 575px) {
        //     width: 82%;
        // }
        @include mq($from: desktop) {
            width: 88%;
            left: 0;
        }
    }
    ul{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    @include mq($until: desktop) {
        overflow: auto;
        width: 100%;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            background-color: $color-white;
            height: 8px;
        }
        &::-webkit-scrollbar:vertical {
            width: 15px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-blue-green;
            
        }
        
    }

    &__item {
        max-width: 137px;
        width: 100%;
        text-align: center;
        min-width: 118px;
        z-index: 1;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        html[lang=es-US] & {
            max-width: 151px;

        }
        *{
            display: block;
        }

        @include mq($until: desktop) {
            margin-right: 55px;
            &:last-child {
                margin-right: 0;
            }
            html[lang=es-US] & {
                max-width: 151px;
                min-width: 151px;
            }
        }

        strong {
            margin-top: 21px;
        }

    }
    &__icon {
        color: $color-blue;
        font-size: 22px;
        z-index: 1;
        background-color: $color-light-blue;
        display: inline;
    }
}