.c-blog-search-results {
    border-bottom: 1px solid $color-silver;

    &__title {
        font-size: 16px;
        font-weight: 600;
        @include mq($from: desktop) {
            font-size: 18px;
        }
    }

    &__item {
        border-top: 1px solid $color-silver;
        padding: 30px 20px;
    }
    .c-article-preview {
        &__title {
            @include mq($from: desktop) {
                font-size: 28px;
            }
        }
        &__content {
            margin-top: 10px;
        }
        &__pic {

            @include mq($from: desktop) {
                width: 200px;
            }
        }
    }
    + .c-pagination {
        margin-top: 20px;
        
        @include mq($from: desktop) {
            margin-top: 30px;
        }
    }
}
