.c-product-hero {
    display: flex;
    flex-direction: column-reverse;
    background-color: $color-light-blue;
    @include mq($from: desktop) {
        position: relative;
    }
    &.bg-gray {
        background-color: #F6F6F6;
    }

    

    &__img {
        height: 156px;
        @include mq($from: tablet) {
            height: 240px;
        }
        @include mq($from: desktop) {
            height: 400px;
        }
    }
    &--pet {
        .c-product-hero__img {
            @include mq($until: tablet) {
                height: 425px;
            }
        }

    }
    &--large {
        background-color: #F5FCFF;
        .c-product-hero__title,
        .c-product-hero__text,
        .c-product-hero__controls{
            text-align: left;
            padding-bottom: 0;
            
        }
        .c-product-hero__text{
            font-size: 22px;
            line-height: 1.3;
            font-family: $font-oswald-medium;
            @include mq($from: desktop){
                font-size: 32px;
            }
        }
        .c-product-hero__img {
            display: none;
            @include mq($from: desktop) {
                height: 570px;
                display: block;
            }
        }
    }
    .o-wrapper {
        + .c-product-hero__disclaimer {
            padding-top: 30px;
            @include mq($from: desktop) {
                padding-top: 10px;
            }
        }
    }
    &__list {
        display: grid;
        gap: 20px;
        margin: 20px 0 0;
        @include mq($from: desktop){
            margin: 40px 0 18px;
        }
        
        &_item {
            font-weight: 600;
            @include mq($from: desktop) {
                font-size: 20px;
            }
        }
        .o-icon {
            color: $color-blue;
            font-size: 30px;
            vertical-align: middle;
            margin-right: 20px;
        }
    }

    &__content {
        padding: 30px 0 40px;
        @include mq($from: desktop) {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            z-index: 5;
            padding: 20px 0;
            transform: translateY(-50%);
        }
    }

    &__title {
        text-align: center;
        font-size: 34px;
        font-family: $font-oswald-medium;
        font-weight: normal;
        color: $color-blue;
        line-height: 1.08;
        margin: 0;
        @include mq($from: desktop) {
            text-align: left;
            font-size: 42px;
            max-width: 678px;
            line-height: 1.1;
        }
        @include mq($from: wide) {
            font-size: 48px;
        }

        span {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 16px;
            display: inline-block;
            transform: translate(-5px, -2px);
            vertical-align: top;
            font-weight: 700;
            
            @include mq($from: desktop) {
                transform: translate(-10px, 3px);
            }
        }
    }

    &__text {
        margin-top: 8px;
        font-family: $font-oswald;
        font-size: 26px;
        text-align: center;
        line-height: 1;
        max-width: 750px;
        @include mq($from: desktop) {
            text-align: left;
            font-size: 32px;
        }

        p {
            margin: 0 0 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__controls {
        padding: 23px 0;
        @include mq($until: desktop) {
            text-align: center;
        }
        .c-button {
            padding: 5px 30px;
            min-width: 174px;
            @include mq($from: desktop) {
                min-width: auto;
            }

        }
        &-text-mobile {
            @include mq($from: desktop) {
                display: none;
            }
        }
        &-text-desktop {
            display: none;
            @include mq($from: desktop) {
                display: block;
            }
        }

        + .c-review-hero {
            max-width: 420px;
        }
    }

    &__quote {
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 22px 22px 32px;
        max-width: 715px;
        margin: 24px auto 0;
        @include mq($from: tablet) {
            padding: 14px 24px;
        }
        @include mq($from: desktop) {
            max-width: 750px;
            margin: 20px 0 0 0;

            html[lang=es-US] & {
                max-width: 780px;
            }
        }

        .form__error {
            @include mq($from: desktop) {
                line-height: 1.2;
            }
        }
    }

    &__disclaimer {
        max-width: 1200px;
        padding: 30px 25px 0;
        margin-right: auto;
        margin-left: auto;
        font-size: 14px;
        @include mq($from: tablet) {
            text-align: center;
        }

        @include mq($from: desktop) {
            padding: 30px 0 0 20px; 
            text-align: left;
        }

        span {
            display: inline-block;
            font-family: Arial, Helvetica, sans-serif;
            vertical-align: top;
            font-size: 10px;
            transform: translate(-1px , -1px);
        }

        p {
            margin: 0;
        }
    }


}

.c-review-hero {
    max-width: 830px;
    padding: 30px 0 0;
    margin: 0 auto;

    @include mq($from: desktop) {
        max-width: 460px;
        margin: initial;
        padding: 27px 20px 0;
    }
    @include mq($from: 1350px) {
        max-width: 830px;
        
    }
}