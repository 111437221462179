.c-saving-features {
    margin-top: 30px;
    &__list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 25px;

        &[data-columns='4'] {
            padding-left: 0;
            gap: 0 16px;
        }

        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            width: 100%;
            @include mq($from: tablet) {
                width: calc(50% - 10px);
            }
            @include mq($from: desktop) {
                width: calc(33.33% - 10px);
                margin-bottom: 20px;

                [data-columns='4'] & {
                    width: calc(25% - 12px);
                }
            }
        }
        &-text {
            //width: 210px;
            margin-left: 8px;
            //font-weight: 600;
            @include mq($from: desktop) {
                width: 210px;
            }

            a {
                color: $color-blue;
                font-weight: 600;
            }
        }
        &-image {
            width: 52px;
            height: auto;
            
        }
    }
}