.c-minisite-preview {
    margin: 20px 0;
    @include mq($from: desktop) {
        margin: 30px 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        &--separator {
            gap: 0;
            border-bottom: 1px solid $color-silver;
            @include mq($from: desktop) {
                grid-template-columns: repeat(2, 1fr);
                gap: 0 70px;
                border-bottom: none;
            }
        }

        &--boxes {
            @include mq($from: desktop) {
                grid-template-columns: repeat(3, 1fr);
                gap: 24px 32px;
            }
        }
    }

    &__item {
        .c-minisite-preview__list--boxes & {
            border: 1px solid $color-silver;
            border-radius: 8px;
            padding: 20px 16px;
        }

        .c-minisite-preview__list--separator & {
            padding: 20px 0;
            border-top: 1px solid $color-silver;
            @include mq($from: desktop) {
                padding: 24px 8px;
            }

            .c-article-header-media__other & {
                padding: 20px 16px;
            }

            &:nth-last-of-type(-n+2) {
                @include mq($from: desktop) {
                    border-bottom: 1px solid $color-silver;
                }
            }
        }
    }

    &__controls {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        @include mq($from: desktop) {
            margin-top: 24px;
            justify-content: flex-end;
        }
    }
}