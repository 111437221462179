.c-single-review {


    &__wrapper {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        display: block;
        position: relative;
        padding-top: 40px;

        @include mq($from: desktop) {
            padding-top: 54px;
        }

        &:before {
            content: "";
            width: 35px;
            height: 35px;
            position: absolute;
            bottom: -17px;
            left: 0;
            right: 0;
            margin: auto;
            background-color: #fff;
            transform: rotate(45deg);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        }

        + .c-single-review__title {
            margin-top: 30px;
        }
    }
    &__content {
        background-color: #fff;
        padding: 0px 17px 0px;
        position: relative;
        text-align: center;  
        @include mq($from: desktop) {
            padding: 0px 50px 0px;
        }
    }
    &__title {
        font-size: 28px;
        font-family: $font-oswald;
        font-weight: 400;
        text-align: center;
        @include mq($from: desktop) {
            font-size: 30px;
        }

        br {
            @include mq($until: tablet) {
                display: none;
            }
        }
    }
    &__star {
        padding: 18px 0;
        @include mq($from: desktop) {
            padding: 24px 0;
        }
        img {
            width: 128px;
            height: auto;
            @include mq($from: desktop) {
                width: auto;
            }
        }
    }
    &__description {
        margin-bottom: 16px;
    }
    &-user {
        &__name {
            color: $color-blue;
            font-weight: 600;
        }
        &__date {
            font-size: 14px;
        }
    }
    &-google {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 23px 0;
        background-color: $color-white;
        @include mq($from: desktop) {
            flex-direction: row;
        }

        
        &__img {
            display: block;
        }

        &__text {
            font-size: 12px;
            text-align: left;
            padding-left: 11px;
            margin: 0;
        }
    }
}