.c-search-page {

    &__title {
        font-weight: 600;
        font-size: 28px;
        color: $color-blue;
        font-family: $font-oswald-medium;
        font-weight: normal;
        margin: 0;
        @include mq($from: desktop) {
            font-size: 42px;
            br {
                display: none;
            }
        }

        strong {
            font-weight: normal;
            color: $color-black;
            margin-left: 3px;
        }

    }
    &-intro {
        background-color: $color-light-blue;
        padding: 26px 0;
        @include mq($from: desktop) {
            padding: 47px 0 41px;
        }
        &__title {
            font-family: $font-oswald-medium;
            font-weight: 500;
            color: $color-blue;
            font-size: 30px;
            margin: 0;
            width: 100%;
            text-align: center;
            @include mq($from: tablet){
                text-align: left;
                width: auto;
            }
            @include mq($from: desktop){
                font-size: 52px;
            }
        }
        &__content {
            text-align: center;
        }
    }

    &-search {
        width: 100%;
        margin: 25px auto 0;
    
        @include mq($from: 400px) {
            max-width: 342px;
        }
    
        @include mq($from: desktop){
            max-width: 519px;
            margin: 37px auto 0;
        }
        
    }
    &-form {
        display: flex;

        &__label {
            width: 100%;
            margin-bottom: 7px;
        }
        &__input {
            width: 75%;
            margin-right: 7px;
            border-radius: 8px;
            border: 1px solid $color-silver;
            font-weight: 400;
            padding: 5px 15px;
            height: 48px;
            @include placeholder($color-gray-3);
            width: 274px;
            @include mq($from: desktop)  {
                width: 479px;
            }
        }
        &__controls {
            width: 45px;
            height: 50px;
            @include mq($from: mobile) {
                width: 55px;
            }
        }

        &__control {
            font-size: 24px;
            background-color: $color-blue-green;
            color: $color-white;
            border-radius: 12px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.c-search-results {
    &__content {
        padding: 20px 0 29px;
    }
    
    &__title {
        font-weight: 600;
        font-size: 18px;

        @include mq($from: desktop) {
            font-size: 21px;
        }
        span {
            color: $color-blue;
            font-size: 18px;

            @include mq($from: desktop) {
                font-size: 22px;
            }
        }
    }
    &__text {
        margin-top: 15px;
    }

    &-card {
        &__item {
            width: 100%;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12);
            border-radius: 2px;
            padding: 20px 19px;
            margin-bottom: 20px;
            background-color: white;
            @include mq($from: desktop) {
                padding: 20px 40px;
            }
            &:last-child {
                margin-bottom: 35px;
                @include mq($from: desktop) {
                    margin-bottom: 41px;
                }
            }
            &:hover {
                background-color: $color-light-blue;
            }
            a {
                display: block;
                text-decoration: none;
                color: $color-black;
                
            }
            span {
                color: $color-blue-green;
                font-size: 16px;
                font-weight: 600;
                
            }
            h2 {
                font-size: 18px;
                font-family: $font-opensans;
                font-weight: 700;
                margin: 0;
                @include mq($from: desktop) {
                    font-size: 20px;
                }
            }
            p {
                margin: 0;
            }
        }
    }
}


.c-search-results-knowledge {
    &__content {
        padding: 20px 0 29px;
    }
    
    &__title {
        font-weight: 600;
        font-size: 18px;
        text-align: center;

        @include mq($from: desktop) {
            font-size: 21px;
            text-align: left;
        }
        span {
            color: $color-blue;
            font-size: 18px;

            @include mq($from: desktop) {
                font-size: 22px;
            }
        }
    }
    &__text {
        margin-top: 15px;
    }

    &-card {
        &__item {
            width: 100%;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.12);
            border-radius: 2px;
            padding: 20px 19px;
            margin-bottom: 20px;
            background-color: white;
            display: flex;
            align-items: center;
            flex-direction: column;
            @include mq($from: desktop) {
                padding: 20px;
                flex-direction: row;
            }
            &:last-child {
                margin-bottom: 35px;
                @include mq($from: desktop) {
                    margin-bottom: 41px;
                }
            }
            &:hover {
                background-color: $color-light-blue;
                h2 {
                    color: $color-blue;
                }
            }
            a {
                display: block;
                text-decoration: none;
                color: $color-black;
                p:first-child {
                    color: $color-blue-green;
                    font-size: 16px;

                    @include mq($until: tablet) {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;                   
                        max-width: 282px;
                        margin-top: 13px;
                    }
                    @include mq($until: desktop) {
                        margin-top: 13px;
                    }

                    strong {
                        font-weight: 600;
                    }
                }
            }
           
            h2 {
                font-size: 18px;
                font-family: $font-opensans;
                font-weight: 700;
                margin: 0;
                @include mq($from: desktop) {
                    font-size: 20px;
                }
            }
            p {
                margin: 0;
            }

        }
        &__image {


            @include mq($until: desktop) {
                //margin-right: 20px;
                width: 100%;
            }
            @include mq($from: desktop) {
                margin-right: 20px;
                //width: 185px;
            }
            a {
                display: block;
                width: 100%;

                @include mq($from: desktop) {
                    width: 185px;
                }
            }
            picture {
                min-height: 158px;
                height: 100%;
                width: 100%;
                @include mq($from: desktop) {
                    min-height: 100px;
                }
            }

            img {
                //width: 100%;
                //margin: auto;
                @include mq($from: desktop) {
                    //width: 185px;
                }
            }
        }
    }
}
