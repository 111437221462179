.c-journal-seal {
    margin: 30px auto 40px;
    max-width: 320px;
    @include mq($from: desktop) {
        margin: 0;
        max-width: 200px;
        [lang=es-US] & {
            max-width: 250px;
        }
    }

    br {
        display: none;
        @include mq($from: desktop) {
            display: block;
        }
    }

    &__img {
        width: 160px;
        height: auto;
        display: block;
        margin: 0 auto;
        @include mq($from: desktop) {
            width: 140px;
        }
    }

    &__text {
        margin: 16px auto 0;
        font-size: 18px;
        font-weight: 700;
        color: $color-black;
        text-align: center;
        line-height: 1.33;
        [lang=es-US] & {
            width: 85%;
        }
        @include mq($from: desktop) {
            width: 75%;
            font-size: 16px;
            line-height: 1.37;

            [lang=es-US] & {
                width: 95%;
            }
        }
    }
}