.c-explore-articles {
    margin: 40px auto;
    padding: 0 20px;
    max-width: 1200px;
    width: 100%;

    &__content {
        background-color: $color-light-blue;
        padding: 40px 24px;
        width: calc(100% + 40px);
        margin-left: -20px;
        @include mq($from: desktop) {
            width: auto;
            margin-left: 0;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 24px;
        margin: 0;
        color: $color-blue-green;
        @include mq($from: desktop) {
            font-size: 30px;
        }
    }

    &__list {
        margin-top: 24px; 
        @include mq($from: desktop) {
            margin-top: 40px; 
        }  
    }

    &__item {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 24px;
        line-height: 1.2;
        @include mq($from: desktop) {
            text-indent: 10px;
        }  
        &:last-child {
            margin-bottom: 0;
        }
        &::marker{
            color: $color-blue-green;
            
        }
        a {
            color: inherit;
        }
    }
}