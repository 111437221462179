.c-kc-page2-article {
    .o-wrapper {
        padding: 0 20px !important;
    }
    &__description {
        margin-bottom: 30px;
        @include mq($from: tablet) {
            margin-bottom: 56px;
        }

        h2,h3 {
            color: $color-blue;
            font-family: $font-oswald;
            font-weight: 400;
            margin-bottom: 15px;
    
        }
        h2 {
            font-size: 24px;
            @include mq($from: tablet) {
                font-size: 32px;
            }
        }
        h3 {
            font-size: 18px;
            @include mq($from: tablet) {
                font-size: 24px;
            }
        }
        p {
            strong {
                color: $color-blue;
                a {
                    font-weight: 600;
                }
            }
            a {
                color: $color-blue;
            }
        }
        &-link {
            font-weight: 700;
            font-size: 18px;
        }
    }
    &__list{
        &-number {
            list-style: decimal;
            padding-left: 15px;
            li {
                &::marker {
                    color: $color-blue;
                    font-weight: 700;
                }
            }
            strong {
                color: $color-blue;
            }
        }
    }
    .c-kc-page-article__list--circle{
        strong {
            font-weight: 600;
        }       
    }
    .c-kc-page-article__imgs-group {
        text-align: center;
        img {
            margin: 0 auto 0;
        }
    }
}
