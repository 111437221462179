.c-banner-ad {
    margin: 0 0 40px;
    .o-wrapper {
        @include mq($until: tablet) {
            padding: 0;
        }
    }

    &__img {
        width: 100%;
        height: auto;
        display: block;
    }
}