.c-icare {
    padding: 40px 0;
    text-align: center;

    @include mq($from: desktop) {
        padding: 30px 0;
    }

    &__content {
        @include mq($from: wide) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__logo {
        width: 114px;
        margin: 0 auto 36px;
        display: block;

        @include mq($from: desktop) {
            width: 100px;
        }

        @include mq($from: wide) {
            margin: 0 15px 0 0;
        }

        @include mq($from: 1250px) {
            margin: 0 30px 0 0;
        }
    }

    &__word {
        display: flex;
        align-items: center;
        max-width: 230px;
        margin: 0 auto 16px;

        @include mq($from: wide) {
            margin: 0 15px;

            &:last-child {
                margin-right: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__letter {
        width: 50px;
        height: 50px;
        margin-right: 5px;
        border-radius: 50%;
        flex-wrap: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 36px;
        color: #fff;

        &--purple {
            background-color: #884b9f;
        }

        &--blue {
            background-color: #24569F;
        }

        &--green {
            background-color: #008a12;
        }

        &--orange {
            background-color: #f59105;
        }

        &--yellow {
            background-color: #fabe05;
        }

        &--red {
            background-color: #D74203;
        }

        &--aquamarine {
            background-color: #02829B;
        }
    }

    &__text {
        font-size: 26px;
        color: #24569F;
    }
}