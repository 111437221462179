.c-article-header {
    margin: 30px 0;
    @include mq($from: desktop) {
        margin-bottom: 40px;
    }

    .o-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .c-article-preview__tags {
        font-weight: 700;
    }

    &__title {
        color: $color-blue;
        font-size: 32px;
        line-height: 1.19;
        font-family: $font-oswald-medium;
        font-weight: 500;
        margin: 10px 0 0;
        width: 100%;
        @include mq($from: tablet) {
            font-size: 30px;
        }
    
        @include mq($from: desktop) {
            margin-top: 8px;
            font-size: 42px;
        }
    }

    &__text {
        font-weight: 600;
        line-height: 1.56;
        font-size: 18px;
        margin-top: 20px;
        @include mq($from: tablet) {
            font-size: 21px;
        }
        @include mq($from: desktop) {
            margin-top: 24px;
            font-size: 24px;
        }

        p {
            margin: 0 0 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__share {
        margin-top: 40px;
    }

    &-author {
        display: flex;
        align-items: center;
        gap: 0 8px;
        margin-top: 20px;
        @include mq($from: desktop) {
            margin-top: 40px;
        }

        &__pic {
            width: 36px;
            height: 36px;
            display: block;
            overflow: hidden;
            border-radius: 50%;
            @include mq($from: desktop) {
                width: 60px;
                height: 60px;
            }
        }

        &__img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }

        &__name {
            font-size: 12px;
            line-height: 1.67;
            @include mq($from: desktop) {
                font-size: 15px;
            }
        }

        &__link {
            color: $color-black;
            text-decoration: underline;
        }

        &__data {
            font-size: 12px;
            line-height: 1.83;
            @include mq($from: desktop) {
                font-size: 15px;
            }
        }
    }

    &-media {
        width: 100%;
        margin-top: 20px;
        @include mq($from: desktop) {
            margin-top: 40px;
        }

        &--video-list {            
            @include mq($from: desktop) {
                display: flex;
                gap: 40px;
            }
        }

        &__pic {
            margin-left: -20px;
            width: calc(100% + 40px);
            height: 204px;
            display: block;
            @include mq($from: desktop) {
                width: 100%;
                margin-left: 0;
                height: 652px;
                border-radius: 8px;
                overflow: hidden;
            }
        }

        &__video {
            width: 100%;
            margin-left: 0;
            display: block;
            aspect-ratio: 16 / 9;
            @include mq($from: desktop) {
                width: 100%;
                height: 652px;
                border-radius: 8px;
                overflow: hidden;
            }

            .c-article-header-media--video-list & {
                @include mq($from: desktop) {
                    height: 430px;
                }
            }
        }

        &__video-link {
            width: 100%;
            height: 100%;
            display: block;
        }

         &__img {
            width: 100%;
            display: block;
            height: 100%;
            object-fit: cover;
        }

        &__iframe {
            border: 0;
            width: 100%;
            height: 100%;
        }

        &__controls {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            @include mq($from: desktop) {
                margin-top: 16px;
            }
        }

        &__control {
            font-weight: 600;
            color: $color-black;
            text-decoration: underline;
            font-size: 14px;
            @include mq($from: desktop) {
                font-size: 20px;
                
                .c-article-header-media--video-list & {
                    font-size: 16px;
                }
            }
            &:hover {
                color: $color-blue;
            }
        }

        &__wrapper {
            @include mq($from: desktop) {
                width: 764px;
            }
        }

        &__other {
            margin-top: 30px;
            @include mq($from: desktop) {
                width: 355px;
                margin-top: 0;
            }
        }

        &__title {
            color: $color-blue;
            font-family: $font-oswald;
            font-weight: 400;
            font-size: 24px;
            line-height: 1.33;
            margin: 0 0 20px;
        }

        .c-minisite-preview__list--separator {
            @include mq($from: desktop) {
                border-bottom: 1px solid $color-silver;
                grid-template-columns: 1fr;
            }
        }

        .c-minisite-preview__list--separator .c-minisite-preview__item:nth-last-of-type(-n+2) {
            border-bottom: none;
        }

        .c-minisite-preview__list--separator .c-article-preview__pic {
            width: 120px;
        }
        .c-article-preview__title {
            font-size: 18px;
        }
        .c-minisite-preview__list--separator .c-article-preview__title-link {
            width: calc(100% - 138px);
        }
        .c-article-preview__info {
            display: block;
        }
        .c-article-preview__read-time {
            margin-top: 4px;

            &::before {
                display: none;
            }
        }

    }
}