.c-state-letters {
    
    @include mq($until: tablet) {
        max-width: 500px;
        margin: auto;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 18px;
    }

    &__item {
        width: 30px;
        font-weight: 700;
        font-size: 15px;
        margin: 0 4px 16px;
    }

    &__link {
        color: inherit;
        text-decoration: none;
        color: $color-black;
        &:hover {
            text-decoration: underline;
            color: $color-blue;
        }
    }

    .js-state-letters__btn  {
        margin-bottom: 35px;
        background-color: transparent;
        font-weight: 700;
        text-decoration: underline;
        color: $color-black;

        @include mq($from: tablet) {
            display: none;
        }
    }  
}

.js-state-letters__list {
    height: 62px;
    overflow: hidden;
    transition: .3s;
    @include mq($from: tablet) {
        height: auto;
    
    }
}