.c-legal {
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        flex-wrap: wrap;
        @include mq($from: desktop) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}
.c-legal-content {
    width: 100%;
    @include mq($from: desktop) {
        width: 67%;
    }
    @include mq($from: 1057px) {
        width: 70%;
    }
    @include mq($from: wide) {
        max-width: 820px;
        width: 100%;

    }
    &__wrapper {
        p {
            a {
                color: $color-blue;
            }
        }
        @include mq($from: desktop) {
            padding-top: 47px;

        }
    }
    >p.c-legal-content__text:first-child,
    >p.c-legal-content__date:first-child {
        margin-top: 0;
    }
    
    &__title {
        color: $color-blue;
        font-size: 18px;
        font-weight: 600;
        margin-top: 28px;
        
        @include mq($from: desktop) {
            font-size: 22px;
        }
        &--centered {
            font-size: 28px;
            font-family: $font-oswald;
            font-weight: 500;
            margin: 0 auto 5px;
            text-align: center;
            line-height: 1.25;
            text-align: center;
            @include mq($from: desktop) {
                font-size: 42px !important;
            }
        }
        &--big {
            font-size: 28px;
            font-family: $font-oswald;
            font-weight: 500;
            margin-bottom: 5px;
            text-align: center;
            line-height: 1.25;
            @include mq($from: tablet) {
                width: 85%;
                text-align: left;
            }

            @include mq($from: desktop) {
                position: relative;
                font-size: 42px !important;
                width: 74%;
                margin: 20px 0 -53px auto;
                padding-right: 20px;
                padding-left: 40px;
            }
            @include mq($from: wide) {
                padding-left: 0;
                width: 71%;
            }
        }
        &--black {
            color: $color-black;
            @include mq($from: desktop) {
                font-size: 20px;
            }
        }
    }
    &__text {
        a {
            color: $color-blue;
        }
        
    }
    &__link {
        color: $color-blue;
    }
    &__date {
        &--top {
            font-size: 14px;
            + .c-legal-content__text {
                margin-top: 30px;
            }
        }
    }
    &__sub {
        padding-left: 20px;
        @include mq($from: tablet) {
            padding-left: 40px;
        }
    }
    &__list {
        padding-left: 20px;
        list-style: none;
        &-disc {
            list-style: disc;
            margin-bottom: 20px;
            &-item {
                margin-bottom: 12px;
                &::marker {
                    color: $color-gray-3;
                }
            }
        }
        &-number {
            padding-left: 0;
            list-style: decimal;
            list-style-position: inside;
            &-item {
                &::marker {
                    font-weight: 700;
                }
                .c-legal-content__list {
                    padding-left: 35px;
                    @include mq($from: tablet) {
                        padding-left: 50px;
                    }
                }
            }
        }
        &-circle {
            list-style: circle;
            margin: 20px 0 17px;
            &-item {
                margin-bottom: 12px;
            }
        }
        &-underline {
            list-style: none;
            margin: 20px 0 17px;
            &-item {
                margin-bottom: 12px;
            }
        }
        &-two-col {
            padding-left: 20px;
            @include mq($from: tablet) {
                column-count: 2;
                column-gap: 87px;
            }
            &-item {
                margin-bottom: 15px;
                &::marker {
                    color: $color-gray-3;
                    font-size: 19px;
                }
            }

        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        &-item {
            border: 1px solid $color-blue-green;
            width: 100%;
            padding: 15px;
            @include mq($from: tablet) {
                width: 48%;
            }
            &:first-child {
                
                @include mq($from: tablet) {
                    margin-right: 15px;
                }
            }
            strong {
                font-weight: 600;
            }
        }
        &-title {
            font-size: 18px;
        }
        &-direction {
            display: flex;
            .o-icon {
                margin-right: 8px;
            }
        }
    }
}

.c-legal-promotions {
    &__title {
        color: $color-blue;
        font-family: $font-oswald-medium;
        font-weight: 500;
        font-size: 28px;
        text-align: center;
        @include mq($from: desktop)  {
            font-size: 42px;
        }
    }
    &__list {
        list-style: decimal;
        &-item {
            
            &::marker {
                font-weight: 700;
                color: $color-blue;
            }
            strong {
                color: $color-blue;
            }
        }
    }
}
.c-aside {
    width: 100%;
    //padding-top: 30px;
    position: relative;
    text-align: center;

    @include mq($from: tablet) {
        text-align: initial;
    }
    @include mq($from: desktop) {
        width: 305px;
        
    }
    @include mq($from: wide) {
        width: 340px;
    }
    
    &__content {
        max-height: 750px;
        width: 290px !important;
        display: none;
        @include mq($from: tablet) {
            width: 290px !important;
            padding-top: 5px;
        }
        @include mq($from: desktop) {
            width: 300px !important;
            display: block;
        }
        @include mq($from: wide) {
            width: 340px !important;
        }
    }
    &__list {
        position: relative;
        padding-bottom: 10px;
        padding-right: 45px;
       &:after,&:before{
           position: absolute;
           content: '';
           width: 92%;
           height: 1px;
           left: 0;
           bottom: 0;
           background-color: #8AB2CB;
           @include mq($from: wide) {
            width: 81%;
           }
       }
       &:before{
           top: -18px;
       }
    }
    &__item {
        color: black;
        font-size: 18px;
        font-weight: 600;
        @include mq($from: tablet) {
            margin-bottom: 25px;
        }
        @include mq($from: desktop) {
            font-size: 20px;
            margin-bottom: 38px;
        }
        &.is-active {
            color: $color-blue;
            font-weight: 700;
            .c-aside__link {
                text-decoration: underline;
            }
        }

    }
    &__link {
        color: inherit;
        text-decoration: none;
    }
}
.c-aside__select {
    width: 100%;
    height: 62px;
    padding: 5px 26px 5px 18px;
    font-weight: 600;
    margin: 0 auto;
    text-align: left;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border: 0;
    
    @include mq($from: tablet) {
        width: 40%;
    }

    @include mq($from: desktop) {
        display: none;
    }
}