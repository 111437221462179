.c-reasons {
    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq($from: desktop) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__item {
        max-width: 300px;
        margin: 0 0 56px;

        &:last-child {
            margin-bottom: 0;
        }

        @include mq($from: desktop) {
            max-width: 260px;
            margin-bottom: 0;

            &:nth-child(2) {
                margin-top: 32px;
                .c-reasons__list--lineal &{
                    margin-top: 0;
                }
            }
        }
        .c-reasons__list--lineal &{
            @include mq($until: desktop) {
                display: flex;
                align-items: flex-start;
            }
        }
    }

    &__image {
        width: 52px;
        height: auto;
        display: block;
        margin: 0 auto;

        @include mq($from: desktop) {
            width: 60px;
        }
        .c-reasons__list--lineal &{
            @include mq($until: desktop) {
                margin-right: 10px;
                width: 40px;
            }
        }
    }

    &__content {
        margin-top: 12px;
        text-align: center;
        .c-reasons__list--lineal & {
            @include mq($until: desktop) {
                margin-top: 0;
                text-align: left;
            }
        }
    }

    &__title {
        font-weight: 700;
        font-size: 20px;
        margin: 0 0 5px 0;

        @include mq($from: desktop) {
            font-size: 24px;
        }
        .c-reasons__list--lineal &{
            color: $color-blue;
            font-family: $font-oswald;
            font-weight: normal;
            font-size: 24px;

            @include mq($from: desktop) {
                font-size: 28px;
            }
        }
    }

    &__text {
        line-height: 1.5;
        font-size: 16px;

        p {
            margin-top: 0;
        }
        strong {
            font-weight: 600;
        }
        .c-reasons__list--lineal &{
            text-align: left;
        }
    }
}