.c-modal-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: $color-light-blue;
    z-index: 10;
    display: none;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30px);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility ease-in-out 0.3s;
    @include mq($from: desktop) {
        display: flex;
    }

    &.is-open {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility ease-in-out;
    }

    .o-wrapper {
        width: 100%;
        min-width: 100%;
    }

    &__content {
        max-width: 800px;
        margin: 0 auto;
    }

    &-form {
        position: relative;
        
        &__input {
            display: block;
            width: 100%;
            height: 60px;
            padding: 5px 60px 5px 32px;
            font-size: 18px;
            background-color: #fff;
            border-radius: 30px;
            border: 1px solid $color-silver;
            @include placeholder($color-black);
        }

        &__submit {
            position: absolute;
            top: 50%;
            right: 34px;
            font-size: 24px;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            background-color: transparent;
            justify-content: center;
            &:disabled {
                opacity: 0.9;
                cursor: wait;
            }
        }
    }

    &__top-searchs {
        margin-top: 72px;
    }

    &__title {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
    }

    &__keywords {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;
    }

    &__keyword {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        border: 1px solid $color-silver;
        padding: 5px 20px;
        margin-left: 20px;
        text-decoration: none;
        color: $color-black;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 14px;
        transition: all 0.2s ease-in;


        &:hover {
            border: 1px solid $color-orange;
            color: $color-blue;
            transition: all 0.2s ease-in;
        }
    }

    &__close {
        position: absolute;
        top: 45px;
        right: 45px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        color: $color-black;
    }
}