.c-quick-small {
    max-width: 1000px;
    padding: 40px 20px 20px;
    margin: 0 auto;

    &:last-of-type {
        margin-bottom: 80px;
    }

    &--list {
        max-width: 1200px;
        padding: 0 20px;

    }
    &__list {
        margin: 16px 0 0 0;
        @include mq($until: tablet) {
            padding-left: 25px;
            margin-top: 8px;
        }

        span {
            position: relative;
            @include mq($until: tablet) {
                display: block;
            }

            &:before {
                content: '';
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $color-black;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 5px;
                margin: auto;
            }
            &:first-child{
                &:before {
                    
                    @include mq($from: tablet) {
                        display: none;
                    }
                }
            }
            .c-quick-small--list & {
                padding-left: 20px;
            }
        }
        
    }

    &__wrapper {
        background-color: $color-light-blue;
        padding: 20px 10px 20px 20px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        flex-direction: column;
        @include mq($from: tablet) {
            flex-direction: row;
            padding: 20px 25px;
        }
        .c-quick-small--center &{
            justify-content: center;
        }
    }
    &__content{
        display: flex;
        align-items: center;

        .o-icon {
            font-size: 58px;
            color: $color-blue;
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            &:before {
                position: absolute;
                content: '';
                width: 72px;
                height: 72px;
                background-color: $color-white;
                border-radius: 50px;
                z-index: -1;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            }
        }
    }
    &__img {
        width: 72px;
        .c-quick-small--list & {
            width: 110px;
            height: 110px;
        }
        @include mq($from: desktop) {
            width: 105px;
            .c-quick-office & {
                html[lang=es-US] & {
                    width: 90px;
                }
            }
            .c-quick-small--list & {
                width: 120px;
                height: 120px;
            }
        }
        
    }

    &__title {
        font-family: $font-oswald-medium;
        font-weight: normal;
        font-size: 24px;
        max-width: 439px;
        line-height: 1.3;
        margin: 0 0 0 30px;
        .c-quick-office & {
            margin: 0 0 0 20px;
        }
        .c-quick-small--center & {
            max-width: initial;
        }
        .c-quick-small--list & {
            font-size: 20px;
            margin: 0 0 0 20px;
            max-width: initial;
        }
        @include mq($from: desktop) {
            font-size: 28px;
            .c-quick-office & {
                font-size: 28px;
                html[lang=es-US] & {
                    font-size: 26px;
                    max-width: 380px;
                }
            }
            .c-quick-small--list & {
                font-size: 36px;
            }
        }

        &--blue {
            color: $color-blue;
        }
    }

    &-form {
        max-width: 408px;
        width: 200px;
        margin: 20px auto 0;
        .c-quick-small--center & {
            margin-top: 0;
        }
        .c-quick-office & {
            width: 211px;

            [lang=es-US] & {
                width: 260px;
            }
        }
        @include mq($from: tablet) {
            margin-left: 20px;
            margin-right: 0;
        }
        @include mq($from: desktop) {
            width: 100%;
            min-width: 424px;
            display: flex;
            .c-quick-office & {
                width: auto;
                [lang=es-US] & {
                    width: 400px;
                }
            }
            .c-quick-small--center &{
                min-width: initial;
                width: initial;
            }
        }
        .c-quick-small--list & {
            display: flex;
            @include mq($until: desktop) {
                max-width: 300px;
                width: 100%;
                gap: 0 14px;
                justify-content: space-between;
            }
        }
        &__item {
            margin-bottom: 10px;
            
            @include mq($from: desktop) {
                width: 200px;
                margin-right: 10px;

                .c-quick-office & {
                    width: 222px;
                    html[lang=es-US] & {
                        width: 302px;
                    }
                }
            }
        }
    
        &__label {
            font-size: 14px;
            html[lang=es-US] & {
                font-size: 13px;
            }
        }
    
        &__input {
            padding-right: 12px;
            margin-top: 4px;
            display: block;
            width: 100%;
            height: 50px;
            border-radius: 8px;
            font-weight: 600;
            font-size: 20px;
            border: 1px solid $color-silver;
            padding-left: 12px;
            .c-quick-small--list & {
                box-shadow: 0px 0px 6px rgba(0, 71, 187, 0.8);
                @include mq($until: desktop) {
                    border-radius: 12px;
                }
            }

            &.form__valid {
                border: 1px solid $color-green;
            }
    
            &.form__error {
                border: 1px solid $color-orange;
            }
        }
        label.form__error {
            background-color: $color-orange;
            color: #fff;
            font-size: 12px;
            padding: 6px 9px;
            display: inline-block;
            margin-top: 7px;
            border-radius: 6px;
            position: relative;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            
    
            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                top: -5px;
                left: 20px;
                width: 1px;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 5px solid $color-orange;
            }
        }
    
        &__controls {
            text-align: center;    
            min-width: 200px; 
            margin-top: 10px;   
            .c-quick-office & {
                width: 222px;
            }
            @include mq($from: desktop) {
                margin-top: 27px; 
                .c-quick-small--center &{
                    margin-top: 0;
                }
            }
            .c-quick-office & {
                width: auto;
            }
            .c-quick-small--list & {
                @include mq($until: desktop) {
                    margin-top: 27px;
                    min-width: 146px;
                }
            }
        }
    
        &__submit {
            width: 100%;
            &:disabled {
                opacity: 0.9;
                cursor: wait;
            }
           
        }
    
        &__loading {
            display: none;
            width: 28px;
            height: auto;
            margin-left: 6px;
        }
    }
    
}