.c-regional-page-product {
    .c-regional-page-hero__title {
        text-align: center;
    }
    .c-regional-page-quote__wrapper {
        text-align: center;
        margin: auto;
    }
    .c-regional-page-quote__form-input {
        box-shadow: 0px 0px 6px rgba(0, 71, 187, 0.8);
    }
    &__title {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        max-width: 940px;
        margin: auto;
        line-height: 1.25;
        //margin-bottom: 30px;
        @include mq($from: desktop) {
            font-size: 24px;
            strong {
                font-weight: 600;
            }
        }
    }
}
/*/ ========================================================================
//   #Card State Regional Page
//   ======================================================================== */
.c-regional-page-states {
    >.o-wrapper {
        @include mq($from: tablet) {
            padding: 0;
        }
    }
}

.c-regional-page-state {
    margin-bottom: 72px;
    @include mq($from: tablet) {
        margin-bottom: 114px;
    }
   

    &__item {
        width: 100%;
        max-width: 1000px;
        width: 100%;
        background-color: $color-white;
        box-shadow: 0px 4px 12px rgba(65, 70, 72, 0.12);
        position: relative;
        border-radius: 8px;
        margin: 0 auto 20px ;
        padding: 13px 9px;
        @include mq($from: tablet) {
            padding: 24px 21px;
        }
    }

    &-preview {
        display: flex;

        @include mq($until: tablet) {
            flex-wrap: wrap;
        }

        &__content {

            width: 100%;
            @include mq($from: tablet) {
                padding-left: 24px;
            }

            h3 {
                font-size: 24px;
                font-weight: normal;
                margin: 0 0 20px;
                line-height: 1.3;
                color: $color-blue;
                font-family: $font-oswald-medium;
                text-align: center;
                @include mq($from: tablet) {
                    text-align: left;
                    font-size: 28px;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            p {
                margin-top: 0;
                margin-bottom: 26px;
                @include mq($until: tablet) {
                    display: none;
                }
                
                a {
                    color: $color-blue;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
            &-cities {
                strong {
                    padding-right: 3px;
                }
                
                ul {
                    list-style: none;
                    display: inline;
                    padding-left: 0;
                    li {
                        padding-right: 12px;
                        display: inline;
                        line-height: 2;
                        > a {
                            color: $color-black;
                            text-decoration: none;
                            transition: .3s;
                            font-weight: normal;
                            padding-bottom: 1px;
                            border-bottom: 1px solid $color-black;
                            &:hover {
                                // text-decoration: underline;
                                border-bottom: 1px solid $color-blue;
                                color: $color-blue;
                               
                            }
                        }
                    }
                }
                a {
                    color: $color-blue;
                    font-weight: 600;
                }
            }

            &-resources {
                margin-top: 16px;
                ul {
                    list-style: none;
                    padding-left: 0;
                    margin: 0;
                    li {
                        font-weight: 600;
                        color: $color-blue;
                        line-height: 1.8;

                        a {
                            color: inherit;
                        }
                    }
                    &.c-regional-page-state-preview__list-two-column {
                        @include mq($from: tablet) {
                            column-count: 2;
                            column-gap: 50px;
                        }
                    }
                }
            }
        }

        &__img {
            width: 180px;
            height: 100%;
            @include mq($from: tablet) {
                width: 246px;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }

        &__img-link {
            display: block;
            height: 117px;
            border-radius: 4px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
            margin: 0 auto 14px;
            
            @include mq($from: tablet) {
                height: 160px;
                margin: 0;
            }
        }

        &__link {
            font-size: 18px;
            font-weight: 600;
            width: 100%;
            height: 45px;
            color: $color-black;
            @include mq($until: tablet) {
                display: none;
            }
            .c-button {
                position: absolute;
                bottom: 20px;
            }
        }
    }
}