
#line {
    background-color: $color-grey-1;
    width: 100%;
    height: 2px;

}
#line-progress {
    position: absolute;
    width: 33.33%;
    height: 2px;
    left: 0;
    background-color: $color-orange;
    transition: 0.3s ease;
}