/*** ========================================================================
//   #MAIN CONTENT
//   ======================================================================== */
.c-main-content:not(.c-main-content--blog) {
    margin-top: 125px;
    padding-bottom: 20px;

    .no-office & {
        margin-top: 80px;
    }

    @include mq($from: desktop) {
        margin-top: 196px;
        padding-bottom: 77px;

        .no-office & {
            margin-top: 151px;
        }
    }
    
    @include mq($from: wide) {
        margin-top: 136px;
        .no-office & {
            margin-top: 136px;
        }
    }
}