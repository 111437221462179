.c-csr {
    &-counting {
        
        &__content {
            
            background-color: $color-light-blue;
            padding: 33px 50px 42px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
            @include mq($from: tablet) {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
            }
            &-item {
                margin: auto;
                display: flex;
                flex-direction: column;
                text-align: center;
                @include mq($from: tablet) {
                    width: 38%;
                }
            }
            &-title {
                font-size: 36px;
                font-family: $font-oswald;
                font-weight: 600;
            }
            &-icon {
                height: 49px;
            }
            &-description {
                margin-top: 3px;
                margin-bottom: 30px;
            }
            .s-cms-content {
                width: 100%;
                font-family: $font-oswald;
                font-size: 24px;
                margin-bottom: 20px;
                @include mq($from: desktop) {
                    font-size: 26px;
                }
            }
        }
    }
    &-section {
        &__title {
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .c-section__title {
                margin: 0 0 0 15px;
            }
            img {
                width: auto;
                margin-bottom: 15px;
            }
            @include mq($from: tablet) {
                flex-direction: row;
                img {
                    width: auto;
                    margin-bottom: 0;
                }
            }
        }
        &__img {
            width: 100%;
            height: 180px;
            display: block;
            @include mq($from: tablet){
                height: 260px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

        }
        &__description {
            padding-top: 20px;
        }
    }
}
