///* ========================================================================
//   #SITE HEADER
//   ======================================================================== */

.c-button, .link-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 30px;
    color: $color-black;
    text-decoration: none;
    font-weight: 600;
    padding: 5px 24px;
    font-size: 16px;
    line-height: 1;
    background-color: $color-white;
    text-align: center;

    @include mq($from: desktop) {
        height: 50px;
    }

    br {
        @include mq($from: tablet) {
            display: none;
        }
    }

    &:hover {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    }

    &__text {
        &--mobile {
            @include mq($from: desktop) {
                display: none;
            }
        }

        &--desktop {
            display: none;
            @include mq($from: desktop) {
                display: inline-block;
            }
        }
    }

    &--small {
        height: 30px;
        padding: 5px 10px;

        @include mq($from: desktop) {
            height: 35px;
            padding: 5px 20px;
            font-size: 18px;
        }
    }
    &--medium {
        height: 35px;
        padding: 5px 33px;
        font-size: 16px;
    }

    &__icon {
        font-size: 18px;
        &:first-child {
            margin-right: 6px;
        }

        &:last-child {
            margin-left: 6px;
        }
    }

    &__container, .link-button__container {
        margin: 24px 0;
        width: 100%;

        &:last-of-type {
            margin-bottom: 0;
        }

        &--centered {
            text-align: center;
        }
        &--left {
            text-align: left;
        }
    }

    &--orange {
        background-color: $color-orange;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        color: #fff;

        &:hover {
            background-color: #DA4A00;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        }
    }
    &--fuchsia {
        background-color: $color-fuchsia;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        color: #fff;

        &:hover {
            background-color: #d3006bd9;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        }
    }
    &--blue {
        background-color: $color-blue;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        color: #fff;

        &:hover {
            background-color: #1558c5;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        }
    }
    @at-root .link-button__container .link-button_1 {
        background-color: $color-blue;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        color: #fff;
        text-decoration: none;

        &:hover {
            background-color: #1558c5;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        }
    }
    &--blue-green {
        background-color: $color-blue-green;
        color: #fff;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

        &:hover {
            background-color: #1f8cbe;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        }
    }

    &--linear-orange {
        border: 1px solid $color-orange;
        transition: color 0.3s ease-in-out;

        .o-icon {
            font-size: 21px;
            color: $color-orange;
            margin-left: 3px;
        }

        &:hover {
            color: $color-orange;
            transition: color 0.3s ease-in-out;
        }
    }

    &--linear-blue {
        border: 1px solid $color-blue;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: $color-blue;
            transition: color 0.3s ease-in-out;
        }
    }

    &--linear-fuchsia {
        border: 1px solid $color-fuchsia;
        transition: color 0.3s ease-in-out;
        color: $color-fuchsia;

        &:hover {
            transition: color 0.3s ease-in-out;
        }
    }

    &--linear-silver-blue {
        border: 1px solid $color-silver-blue;
        transition: color 0.3s ease-in-out;
        color: $color-silver-blue;

        &:hover {
            transition: color 0.3s ease-in-out;
        }
    }
    &--linear-blog {
        border: 1px solid $color-silver-blue;
        gap: 8px;
        height: 44px;
        font-size: 15px;

        @include mq($from: desktop) {
            font-size: 16px;
        }

        .o-icon {
            color: $color-silver-blue;
        }
    }
    &--linear-blue-green{
        border: 1px solid $color-blue-green;
        transition: color 0.3s ease-in-out;
        color: $color-blue-green;

        &:hover {
            transition: color 0.3s ease-in-out;
        }
    }

    &--text-big {
        font-size: 20px;
        font-weight: 700;

        @include mq($from: desktop) {
            font-size: 24px;
        }
    }

    &--expand {
        border-radius: 8px;
        display: block;
        width: 100%;
        padding: 8px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 600;
        @include mq($from: desktop) {
            font-size: 16px;
            padding: 8px;
        }
    }
}