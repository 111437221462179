.s-cms-content {
    text-align: center;
    font-size: 16px;
    line-height: 1.5;

    + .c-regional-page-table {
        margin-top: 25px;
    }

    &--blog {
        text-align: left;

        picture {
            max-width: 320px;
            width: 100%;
            border-radius: 8px;
            height: auto;
            display: block;
            margin: 30px auto;
            @include mq($from: tablet) {
                max-width: 730px;
                margin: 40px auto;
            }
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }

    &--big {
        @include mq($from: tablet) {}
        font-size: 18px;
    }
    &--center {
        p {
            text-align: center!important;
        }
        
    }

    h2 {
        .c-blog-section & {
            font-size: 28px;
            color: $color-blue;
            font-weight: normal;
            font-family: $font-oswald-medium;
            margin: 0 0 24px ;
            line-height: 1.2;
            @include mq($from: desktop){
                font-size: 36px;
            }
        }
    }

    h3 {
        margin-top: 0;
        .c-blog-section & {
            font-size: 24px;
            font-weight: normal;
            font-family: $font-oswald-medium;
            margin-bottom: 24px;
            line-height: 1.2;
            @include mq($from: desktop){
                font-size: 30px;
            }
        }
    }


    + & {
        margin-top: 30px;
        @include mq($from: tablet) {
            margin-top: 35px;
        }
    }

    p {
        margin: 0 0 20px 0;
        .c-blog-section & {
            margin-bottom: 24px;
        }

        
        &:last-child {
            margin-bottom: 0;
        }
    }

    ul + p, ol + p {
        margin-top: 24px;
        @include mq($from: tablet) {
            margin-top: 50px;
            .c-blog-section & {
                margin-top: 40px;
            }
        }
    }

    a {
        text-decoration: underline;
        color: $color-blue;
        font-weight: 600;
        //white-space: nowrap;
        @include mq($until: tablet) {
            word-break: break-word;
        }
    }

    strong {
        font-weight: 700;
        //color: $color-blue;
    }

    ul, ol {
        padding: 0;
        margin: 24px auto -15px;
        max-width: 590px;
        text-align: left;
        @include mq($from: tablet) {
            margin-top: 50px;
            column-count: 2;
            column-gap: 50px;
        }
        @include mq($from: desktop) {
            column-gap: 65px;
        }

        &.s-cms-content__list-medium {
            max-width: 760px;
        }
        .c-blog-section & {
            max-width: initial;
            
            padding-left: 40px;
            margin: 25px 0;
            @include mq($from: tablet) {
                column-count: 1;
                margin: 40px 0;
            }
        }
    }

    ul {
        list-style: disc;
        padding-left: 35px;
        @include mq($from: tablet) {
            padding-left: 20px;
        }

        li {
            padding-left: 5px;
            padding-bottom: 15px;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            @include mq($from: desktop) {
                padding-left: 10px;
            }
            .c-blog-section & {
                &:last-child {
                    padding-bottom: 0;
                }
            }

            &::marker {
                color: $color-green;
            }
        }
    }
   

    ol {
        padding-left: 18px;

        li {
            padding-bottom: 15px;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            @include mq($from: desktop) {
                padding-left: 12px;
            }
        }
    }

    iframe {
        max-width: 100%;
        height: 100%;
        aspect-ratio: 16 / 9;
    }

}

.wp-block-embed-youtube { text-align: center; }

.white-space {
    white-space: nowrap
}

.no-white-space {
    white-space: normal
}