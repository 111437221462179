/*/ ========================================================================
//   #KC PAGE
//   ======================================================================== */
.c-kc-page {

    &-intro {
        &__title {
            color: $color-blue;
            font-size: 28px;
            font-family: $font-oswald;
            margin: 0;
            @include mq($from: desktop) {
                font-size: 42px;
            }
        }
        &__subtitle {
            font-size: 16px;
            font-weight: 400;
            margin: 5px 0 0;
        }
    }

    &__load-more {
        margin: 0;
    }

    &-categories {
        background-color: $color-light-blue;
        padding: 40px 0 10px;
        margin-top: 40px;

        &__wrapper {
            display: flex;
            flex-wrap: wrap;
            padding: 0 10px;
            height: auto;
            transition: height .3s;
        }

        &__item {
            background-color: $color-white;
            width: 100%;
            margin: 0 10px 20px;
            text-align: center;
            display: flex;
            align-items: center;
            color: $color-black;
            font-weight: 600;
            padding: 15px;
            text-decoration: none;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            &.is-hidden {
                display: none;
            }
            &.is-visible {
                display: flex;
            }
            @include mq($from: tablet) {
                width: 30%;
                flex-direction: column;
                justify-content: center;
            }
            @include mq($from: desktop) {
                width: 215px;
                margin-bottom: 30px;
            }
            
            &:hover {
                color: $color-blue;
            }
        }

        &__icon {
            height: 52px;
            margin-right: 20px;
            @include mq($from: tablet) {
                height: 72px;
                margin-right: 0;
            }
        }
        &__title {
            line-height: 1.25;
            
            @include mq($from: tablet) {
                margin-top: 5px;
            }
        }
    }

    &-see-more {
        padding-bottom: 30px;
        &__button {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            font-weight: 600;
            color: $color-black;
            .o-icon {
                margin-right: 5px;
            }
            &:hover {
                color: $color-blue;
            }
        }
        
    }

    &-features,
    &-article-card {

        &__title {
            color: $color-blue;
            font-family: $font-oswald;
            font-weight: 400;
            font-size: 28px;
            margin: 30px 0 24px;
            @include mq($from: desktop) {
                font-size: 32px;
                margin: 54px 0 30px;
            }
            &--centered {
                text-align: center;
            }
        }
    }

    &-article-card {
        padding-bottom: 45px;
        &__title {
            padding: 0 20px;
        }
        .c-kc-teaser__item {
            @include mq($from: desktop) {
                margin: 0 16px 30px;
            }
        }
       
        .c-kc-teaser__item--33 {
            @include mq($from: desktop) {
                width: calc(33.33% - 33px);
            }
        }
        .c-kc-teaser-preview__content {
            padding: 21px 17px 27px;
        }
    }

    &-subcategories {
        padding: 0 20px;
        margin-top: 40px;
        position: relative;
        @include mq($from: desktop) {
            padding: 40px 0;
            background-color: $color-light-blue;
        }

        &__item {
            margin: 8px 13px;
            text-decoration: none;
            color: $color-black;
            font-size: 18px;
            font-weight: 600;
            border: 2px solid transparent;
            transition: 400ms;
            display: flex;
            align-items: center;
            //max-width: 100%;

            @include mq($from: desktop) {
                height: 56px;
                width: 280px;
                padding: 0 15px;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                background-color: $color-white;
                margin: 8px 10px;
            }

            .o-icon {
                margin-right: 10px;
                @include mq($until: desktop) {
                    font-size: 22px;
                }
            }
           
            &--all-topics,&--claims, &--travel  {
                .o-icon {
                    color: $color-blue-green;
                }
                @include mq($from: desktop) {
                    &:hover, &.is-active {
                        border: 2px solid $color-blue-green;
                    }
                }
            }

            &--understanding, &--safety {
                .o-icon {
                    color: $color-green;
                }
                @include mq($from: desktop) {
                    &:hover, &.is-active {
                        border: 2px solid $color-green;
                    }
                }
            }
            &--buying, &--tips {
                .o-icon {
                    color: $color-orange;
                }
                @include mq($from: desktop) {
                    &:hover, &.is-active {
                        border: 2px solid $color-orange;
                    }
                }
            }
            &--saving,&--basics,&--weather  {
                .o-icon {
                    color: $color-blue;
                }
                @include mq($from: desktop) {
                    &:hover, &.is-active {
                        border: 2px solid $color-blue;
                    }
                }
            }
            &--high-risk, &--enrolling {
                .o-icon {
                    color: $color-fuchsia;
                }
                @include mq($from: desktop) {
                    &:hover, &.is-active {
                        border: 2px solid $color-fuchsia;
                    }
                }
            }
        }


        &__title {
            line-height: 1.3;
            color: $color-black !important;
            text-align: left;
            font-size: 16px;
        }
        &-accordion {
            width: 100%;
            @include mq($from: mobile) {
                width: 320px;
                margin: auto;
            }
            @include mq($from: 375px) {
                max-width: 500px;
                width:  100%;
            }
            @include mq($from: desktop) {
                max-width: inherit;
            }


            &__item {
                text-align: center;
                
                @include mq($until: desktop) {
                    width: 100%;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                    border-radius: 2px;
                    border: 2px solid $color-blue-green;
                }
            }
            #accordion {
                width: 100%;
                position: relative;
            }
            &__content {

                @include mq($from: desktop) {
                    display: inline-flex;
                    flex-wrap: wrap;
                }
            }
            &__toggle {
                display: none;
                
                @include mq($until: desktop) {
                    width: 100%;
                    background-color: transparent;
                    cursor: pointer;
                    display:flex;
                    align-items: center;
                    margin: auto;
                    position:relative;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: left;
                    padding: 12px 13px;

                    .o-icon {
                        font-size: 22px;
                        color: $color-blue-green;
                        margin-right: 11px;
                    }
                    &-icon{
                        color: $color-black !important;
                        font-size: 17px !important;
                        right: 17px;
                        position: absolute;
                        margin-right: 0;
                        transition: 400ms;
                    }
                    
                    &.active {
                        .c-kc-page-subcategories-accordion__toggle-icon {
                            transform: rotate(180deg);
                        }

                        &-icon {
                            display: block;
                            padding-right:1em;
                            content:"\f078";
                            cursor: pointer;

                        }
                    }     
                }
                       
            }
            &__content {
                &.defualt-hidden {
                    @include mq($until: desktop) {
                        display:none;
                        margin: 0;
                        text-align: left;
                    }
                }
            }
        }
        
    }

    &-info {
        margin-top: 40px;
        @include mq($from: desktop){
            margin-top: 100px; 
        }
        .o-wrapper {

            @include mq($from: desktop) {
                display: flex;
                justify-content: space-between;
            }
        }

        &__heading {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $color-silver;
            padding-bottom: 15px;
            margin-bottom: 28px;
            @include mq($from: desktop) {
                padding-left: 25px;
            }
           
        }

        &__icon {
            width: 40px;
            margin-right: 20px;
            @include mq($from: desktop) {
                width: 60px;
                margin-right: 30px;
            }
        }
        &__title {
            font-family: $font-oswald;
            font-size: 24px;
            color: $color-blue;
            margin: 0;
            @include mq($from: desktop) {
                font-size: 32px;
            }
        }
        &__content {
            @include mq($from: desktop){
                padding-left: 30px;
            }
        }
        &__item {
            margin-bottom: 20px;
        }
        &__link {
            color: $color-black;
            text-decoration: none;
            font-weight: 600;
            &:hover {
                color: $color-blue;
                text-decoration: underline;
            }
        }
        .c-button {
            color: $color-orange;
            background-color: $color-white;
            padding: 5px 31px;
            height: 45px;
        }
        .c-kc-page-faq, .c-kc-page-glossary {
            width: 100%;
            margin-bottom: 40px;
            @include mq($from: desktop) {
                width: calc(50% - 50px);
            }
            @include mq($from: wide) {
                width: calc(50% - 100px);
            }
        }
        .c-kc-page-glossary {
            .c-kc-page-info__item {
                margin-bottom: 18px;
                @include mq($from: desktop) {
                    margin-bottom: 29.4px;
                }
            }
        }
    }

    &-features {
        
        &__heading{
            display: flex;
            margin: 16px 0 21px;
            flex-direction: column-reverse;
            @include mq($from: tablet) {
                justify-content: space-between;
                flex-direction: row;
                margin: 46px 0 21px;
                align-items: center;
            }
            .c-kc-page-features__title {
                margin: 0;
                @include mq($from: desktop) {
                    margin: 0;
                }
            }

            .c-search-page-form__input {
                color: $color-blue;
                padding: 5px 14px;
                margin-bottom: 20px;
                @include mq($from: desktop) {
                    width: 245px;
                }
            }
        }
    }

}
.wp-block-image {
    img {
        max-width: 100%;
        @include mq($until: desktop) {
            height: auto;
        }
    }
}