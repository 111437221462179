.c-simple-table {
    margin: 20px 0;

    &:last-child {
        margin-bottom: 0;
    }

    &:only-child {
        margin: 0;
    }

    &__indicator {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        text-align: right;
        gap: 8px;
        font-size: 14px;
        margin-bottom: 12px;
        display: none;
        font-weight: 600;
        color: $color-blue;
        @include mq($from: desktop) {
            margin-bottom: 10px;
        }

        &.is-active {
            display: flex;
        }

        .o-icon {
            font-size: 20px;
            color: #2255A4;
            @include mq($from: desktop) {
                font-size: 26px;
            }
        }
    }

    &__content {
        overflow-x: auto;
        &--small {
            max-width: 920px;
            + .c-simple-table__controls {
                max-width: 920px;
            }
        }

        &::-webkit-scrollbar {
            height: 7px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #E9F3FD;
            border-radius: 2px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $color-blue;
            border-radius: 2px;
        }
    }

    &__table {
        border-collapse: collapse;
        width: 100%;
       
        th {
            border: 2px solid #8AB2CB;
            border-left: none;
            border-right: none;
            padding: 30px 28px;
            //white-space: nowrap;
            font-size: 16px;
            line-height: 1.33;
            font-weight: 600;
            background-color: #E9F3FD;
            .c-blog-section & {
                background-color: $color-light-blue;
            }
        }

        td {
            font-size: 16px;
            padding: 20px 28px;
            background-color: $color-white;

            strong {
                font-weight: 600;
                color: $color-blue;
            }

            a:not([class]) {
                text-decoration: underline;
                font-weight: 600;
                color: $color-black;
                transition: color 0.3s ease;

                &:hover {
                    transition: color 0.3s ease;
                    color: $color-blue;
                }
            }
        }

        tr:nth-of-type(odd) td {
            background-color: #E9F3FD;
            .c-blog-section & {
                background-color: $color-light-blue;
            }
        }

        tr:focus {
            outline-offset: -2px;
        }

        tr:hover td {
            background-color: lighten($color-blue, 55%);
        }

        .is-hidden {
            display: none;
        }
    }

    &__controls {
        margin-top: 16px;
        .c-button--expand, .link-button--expand {
            border-color: $color-silver;
            height: 40px;
            color: $color-blue;
            border-radius: 16px;
            text-decoration: underline;
        }
    }
}