.c-categories {
    padding: 20px 0;
    border-top: 1px solid $color-silver;
    border-bottom: 1px solid $color-silver;
    margin: 30px 0;
    @include mq($from: desktop) {
        padding: 24px 0;
        margin: 40px 0;
    }

    &__title {
        font-weight: 700;
        font-size: 16px;
        line-height: 1.5;
        margin: 0;
    }

    &__list {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px 8px;
        @include mq($from: desktop) {
            margin-top: 24px;
            gap: 16px 20px;
        }
    }

    &__link {
        display: inline-block;
        border-radius: 18px;
        padding: 8px 16px;
        text-decoration: none;
        background-color: $color-light-blue;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.57;
        color: $color-blue;

        &:hover {
            background-color: darken($color-light-blue, 1%);
        }
    }
}