.c-faqs-categories {
    &__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 18px 20px;
        @include mq($from: tablet) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($from: desktop) {
            grid-template-columns: repeat(5, 1fr);
            gap: 40px;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 12px 10px;
        border-radius: 8px;
        border: 1px solid $color-silver;
        background-color: #fff;
        gap: 0 10px;
        text-decoration: none;
        box-shadow: none;
        transition: all 0.2s ease;
        @include mq($from: desktop) {
            flex-direction: column;
            justify-content: flex-start;
            padding: 20px 8px 16px;
            gap: 12px;
            height: 100%;
        }
        
        &:hover {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            transition: all 0.2s ease;

            @include mq($from: desktop) {
                text-decoration: underline;
            }
        }
    }

    &__img {
        width: 40px;
        height: auto;
        display: block;
        @include mq($from: desktop) {
            width: 72px;
        }
    }

    &__title {
        font-size: 16px;
        line-height: 1.25;
        font-weight: 600;
        color: $color-black;
        margin: 0 auto 0 0;
        @include mq($from: desktop) {
            margin: 0;
            text-align: center;
            font-size: 14px;
        }

        .c-faqs-categories__link:hover & {
            color: $color-blue;
        }
    }

    &__icon {
        font-size: 24px;
        color: #8AB2CB;
        @include mq($from: desktop) {
            display: none;
        }
    }
}