///* ========================================================================
//   #SITE HEADER CONTROLS
//   ======================================================================== */

.c-site-header-controls-mobile {
    display: flex;
    align-items: center;
    
    &__control {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: $color-black;
        padding: 0;
        background-color: transparent;

        &:first-child {
            margin-left: 0;
        }
    }

    .js-site-header-mobile-quote-wrapper {
        display: none;
        transform: translateX(10px);
    }

    &__icon {
        font-size: 18px;
    }

    &__text {
        display: inline-block;
        margin-top: 5px;
        font-size: 12px;
        line-height: 1.1;
    }
}