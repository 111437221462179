.c-simple-hero {
    padding: 30px 0;
    @include mq($from: desktop) {
        padding: 60px 0;
    }

    &__title {
        text-align: center;
        font-size: 28px;
        font-family: $font-oswald-medium;
        font-weight: normal;
        color: $color-blue;
        line-height: 1.14;
        margin: 0;
        @include mq($from: desktop) {
            font-size: 34px;
        }
        @include mq($from: wide) {
            font-size: 40px;
        }
    }

    &__text {
        margin-top: 16px;
        font-weight: 600;
        text-align: center;
        font-size: 18px;
        @include mq($from: desktop) {
            margin-top: 12px;
            font-size: 24px;
        }

        p {
            margin: 0 0 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}